import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { SubmissionService } from '../../../../../../http/intake/submission.service';
import { ProgressIndicatorService } from '../../../../../../common/services/progress-indicator.service';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { Subject, takeUntil } from 'rxjs';
import { IntakeConstant } from '../../../../../constants/intake.constant';

@Component({
  selector: 'app-document-link-upload',
  templateUrl: './document-link-upload.component.html',
  styleUrl: './document-link-upload.component.less',
})
export class DocumentLinkUploadComponent implements OnInit {
  constructor(
    private readonly modalService: ModalService,
    private readonly intakeDetailService: SubmissionService,
    private readonly progressIndicator: ProgressIndicatorService
  ) {}
  @Input() public documentInitiatedDetails: any = {};
  documentLink: string = '';
  documentTitle: string = '';
  documentType: number = 0;
  documentStatus: number = 0;
  documentComment: string = '';
  unsubscriber$: Subject<void> = new Subject<void>();
  documentTypeItemsList: SelectItemOptions[] = [];
  documentStatusItemList: SelectItemOptions[] = [];
  allStatusAvailable: SelectItemOptions[] = [];
  otherStatus: number = IntakeConstant.OTHER_STATUS;

  ngOnInit(): void {
    this.getDocumentTypeDropdownData(
      this.documentInitiatedDetails.submissionTypeId
    );
    this.getDocumentStatusDropdownData(
      this.documentInitiatedDetails.submissionStatusId,
      this.documentInitiatedDetails.submissionTypeId
    );
  }

  getDocumentStatusDropdownData(id: number, typeId: number): void {
    this.intakeDetailService.getDocumentStatusDropdown(id, typeId).subscribe({
      next: (result: any) => {
        const convertedArray = result.map((item: any) => ({
          value: item.documentStatusId,
          heading: item.documentStatusName,
        }));
        this.documentStatusItemList = convertedArray;
        this.allStatusAvailable = convertedArray;
      },
      error: (err: any) => {
        this.progressIndicator.hide();
      },
    });
  }

  getDocumentTypeDropdownData(id: number): void {
    this.intakeDetailService.getDocumentTypeDropdown(id).subscribe({
      next: (result: any) => {
        const convertedArray = result.map((item: any) => ({
          value: item.documentTypeId,
          heading: item.documentTypeName,
        }));
        this.documentTypeItemsList = convertedArray;
      },
      error: (err: any) => {
        this.progressIndicator.hide();
      },
    });
  }

  updateStatusDropdown(): void {
    if (
      this.documentInitiatedDetails.type ===
      IntakeConstant.CHANGE_ORDER_LABEL_CODE
    ) {
      if (
        this.documentType === IntakeConstant.ENGAGEMENT_LETTER_CODE ||
        this.documentType === IntakeConstant.ORIGNAL_Letter_SOW_Contract_CODE ||
        this.documentType === IntakeConstant.ORIGNAL_Letter_CODE
      ) {
        this.documentStatusItemList = this.allStatusAvailable;
      } else {
        this.documentStatusItemList = this.documentStatusItemList.filter(
          (item) => item.heading !== IntakeConstant.FINAL_ARCHIVE_READY_VERSION
        );
        if (this.documentStatus === IntakeConstant.FINAL_ARCHIVE_READY_ID) {
          this.documentStatus = 0;
        }
      }
    }
  }

  async saveDocumentLink(): Promise<void> {
    const postRequestData = [
      {
        submissionId: this.documentInitiatedDetails.submissionId,
        documentTypeId: this.documentType,
        documentStatusId: this.documentStatus,
        submissionDocumentTypeId: 2,
        documentTitle: this.documentTitle,
        documentUrl: this.documentLink,
        comments: this.documentComment,
        attachmentId: null,
      },
    ];
    return new Promise((resolve, reject) => {
      this.intakeDetailService
        .postDocumentSave(postRequestData)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: any) => {
            resolve();
          },
          error: (err) => {
            reject(err);
          },
        });
    });
  }

  formValid(): boolean {
    if (
      this.documentLink.length > 0 &&
      this.documentTitle.length > 0 &&
      this.documentType != 0 &&
      this.documentStatus != 0
    ) {
      if (
        this.documentType === this.otherStatus &&
        this.documentComment.length > 0
      ) {
        return true;
      } else if (
        this.documentType === this.otherStatus &&
        this.documentComment.length === 0
      ) {
        return false;
      }
      return true;
    }
    return false;
  }

  async closeAll(): Promise<void> {
    try {
      await this.saveDocumentLink();
      this.close();
    } catch (error) {}
    this.close();
  }

  close() {
    this.modalService.close();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
