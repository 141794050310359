import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  CO_AND_EL_SOW_RADIO_GROUP_VALUE,
  RADIO_GROUP_OPTIONS_FOR_CO_AND_ELSOW_SCOPE,
} from './close-out-change-order.helper';
import {
  ArchiveDetailsUpdatingModel,
  WbsArchiveReceivedModel,
  WbsResponseDataModel,
} from '../../../../common/models/wbs-picker.model';
import { CoElSowOptionValues } from '../../../constants/intake_document_close_out_co_elsow_option_values.enum';
import { IntakeConstant } from '../../../constants/intake.constant';
import {
  CoElSowEventEmitterType,
  SwiftDetails,
} from '../../../../common/models/intake-submission-details-common-section.model';

@Component({
  selector: 'app-close-out-change-order',
  templateUrl: './close-out-change-order.component.html',
  styleUrl: './close-out-change-order.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloseOutChangeOrderComponent {
  constructor() {}
  @Input() contractSignedExecuted: null | string = IntakeConstant.EMPTY_SPACE;
  @Input() wbsChargeCode: null | string = IntakeConstant.EMPTY_SPACE;
  @Input() numberOfOpenArchieve: null | number = null;
  @Input() wbsDescription: null | string = IntakeConstant.EMPTY_SPACE;
  @Input() dataSubmissionId: number | null = null;
  @Input() dataOpportunityId: string = IntakeConstant.EMPTY_SPACE;
  @Input() archiveDetails: WbsArchiveReceivedModel | null = null;
  @Input() swiftDetails: SwiftDetails | null = null;

  @Output() coAndElSowEventEmitter: EventEmitter<CoElSowEventEmitterType> =
    new EventEmitter<CoElSowEventEmitterType>();
  @Output() wbsChargeCodeChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() wbsArchiveValueChange: EventEmitter<ArchiveDetailsUpdatingModel> =
    new EventEmitter<ArchiveDetailsUpdatingModel>();
  textareaOptions: any = IntakeConstant.TEXT_AREA_SETTINGS_GLOBAL;
  co_elsow_radioGroupOptions: any = RADIO_GROUP_OPTIONS_FOR_CO_AND_ELSOW_SCOPE;
  co_elsow_radio_values: any = CO_AND_EL_SOW_RADIO_GROUP_VALUE;
  CoElSowOptionValueYes = CoElSowOptionValues.YES;
  wbsPendingCheckbox: boolean = false;
  numberOfOpenArchivesLabel = 'Archive : ';

  coAndElSowStateChanged(event: string): void {
    if (event === CoElSowOptionValues.YES) {
      this.coAndElSowEventEmitter.emit({
        contractSignedExecuted: event,
        wbsChargeCode: this.wbsChargeCode,
        numberOfOpenArchieve: null,
        wbsDescription: this.wbsDescription,
      });
      this.updateWbsValues({
        wbsChargeCode: this.wbsChargeCode
          ? this.wbsChargeCode
          : IntakeConstant.EMPTY_SPACE,
        wbsDescription: this.wbsDescription
          ? this.wbsDescription
          : IntakeConstant.EMPTY_SPACE,
        wbsPending: this.wbsPendingCheckbox,
      });
    } else {
      this.wbsChargeCode = null;
      this.wbsPendingCheckbox = false;
      this.wbsDescription = IntakeConstant.EMPTY_SPACE;
      this.wbsChargeCode = IntakeConstant.EMPTY_SPACE;
      this.coAndElSowEventEmitter.emit({
        contractSignedExecuted: event,
        wbsChargeCode: null,
        numberOfOpenArchieve: null,
        wbsDescription: null,
      });
    }
  }

  updateWbsValues(wbsChargeCode: WbsResponseDataModel): void {
    this.wbsPendingCheckbox = wbsChargeCode.wbsPending;
    this.wbsChargeCodeChange.emit({
      wbsChargeCode: wbsChargeCode.wbsChargeCode,
      wbsDescription: wbsChargeCode.wbsDescription,
      wbsPending: wbsChargeCode.wbsPending,
    });
  }

  updateArchivesValues(event: ArchiveDetailsUpdatingModel): void {
    this.wbsArchiveValueChange.emit(event);
  }
}
