import { Component } from '@angular/core';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';

@Component({
  selector: 'app-third-party-repository-grid',
  templateUrl: './third-party-repository-grid.component.html',
  styleUrl: './third-party-repository-grid.component.less'
})
export class ThirdPartyRepositoryGridComponent {
  public isLoading: boolean = true;

  ngOnInit() {
    this.isLoading = false;
  }
}
