export enum RoleEnum {
  RISK_MANAGER = 'Risk Manager',
  SUPPORTING_RISK_MANAGER = 'Supporting Risk Manager',
  SPECIALIZED_RISK_MANAGER = 'Specialized Risk Manager',
  REVIEWER = 'Reviewer',
  TECHNICAL_OR_INDEPENDENT_REVIEWER = 'Technical/Independent Reviewer',
  TECHNICAL_OR_INDEPENDENT_RM = 'Technical/Independent Risk Manager',
  PERSUIT_TEAM = 'Pursuit Team',
  SYSTEM_ADMIN = 'System admin',
  ENGAGEMENT_TEAM_MEMBER = 'Engagement Team Member',
  LEAD_ENGAGEMENT_PARTNER = 'Lead Engagement Partner',
}
