import { Component, Input } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { WinningProposalOptionValues } from '../../../constants/intake_document_close_out_winning_proposal_option_values.enum';
import {
  ButtonKind,
  ExtThemes,
  Size,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { OtherOptionValues } from '../../../constants/intake_document_close_out_other_option_values.enum';
import { SubmissionService } from '../../../../http/intake/submission.service';
import { CloseOutModalModel } from '../../../../common/models/show-all-popup-model';
import { IntakeConstant } from '../../../constants/intake.constant';
import { IntakeDocumentSubmissionTypeCode } from '../../../constants/intake_submission_type_code.enum';
import { CoElSowOptionValues } from '../../../constants/intake_document_close_out_co_elsow_option_values.enum';

@Component({
  selector: 'app-confirm-pop-modal',
  templateUrl: './confirm-pop-modal.component.html',
  styleUrl: './confirm-pop-modal.component.less',
})
export class ConfirmPopModalComponent {
  modalTitle: string = '';
  valueSelected: string = '';
  moveToCompleteBtnLabel = 'Move to complete';
  cancelBtnLabel = 'Cancel';
  movetoComplete: CloseOutModalModel = {
    moveToCompleteOther: false,
    moveToCompleteProposal: false,
    moveToCompleteCoElSow: false,
  };
  static readonly MODAL_COMMON_BTN_OPTIONS: ButtonOptions = {
    theme: ExtThemes.green,
    size: Size.lg,
    width: WidthState.fixed,
    isLoading: false,
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: true,
    customClass: '',
    role: 'button',
  };
  NO_OPPORTUNITY_LOST_DISCONTINUE_ABANDONED =
    WinningProposalOptionValues.NO_OPPORTUNITY_LOST_DISCONTINUE_ABANDONED;

  NO_DOCUMENT_WILL_NOT_BE_EXECUTED =
    OtherOptionValues.NO_DOCUMENT_WILL_NOT_BE_EXECUTED;

  moveToCompleteBtnOption: ButtonOptions = {
    ...ConfirmPopModalComponent.MODAL_COMMON_BTN_OPTIONS,
    icon: '',
    customClass: '',
    kind: ButtonKind.primaryLoud,
    ariaLabel: this.moveToCompleteBtnLabel,
    size: IntakeConstant.UI_ELEMENT_SIZE,
  };
  cancelBtnOption: ButtonOptions = {
    ...ConfirmPopModalComponent.MODAL_COMMON_BTN_OPTIONS,
    icon: '',
    kind: ButtonKind.secondaryLoud,
    customClass: '',
    ariaLabel: this.cancelBtnLabel,
  };
  selectedSubmissionType: string = '';

  oppertunitySubmissionTypeElSow = IntakeDocumentSubmissionTypeCode.EL_SOW;
  oppertunitySubmissionTypeCo = IntakeDocumentSubmissionTypeCode.CO;

  constructor(
    private readonly modalService: ModalService,
    private readonly submissionService: SubmissionService
  ) {}

  @Input() isFooter!: boolean;
  @Input() size!: string;
  @Input() isInverse!: boolean;
  @Input() set modalSetting(setting: any) {
    this.setModalValue(setting);
  }

  open(): void {
    this.modalService.open(ConfirmPopModalComponent);
  }

  close(data: CloseOutModalModel | null = null): void {
    this.modalService.close(data);
  }

  setModalValue(setting: any): void {
    this.selectedSubmissionType = setting?.selectedSubmissionType;
    this.valueSelected = setting?.selectedOption;
    this.movetoComplete.moveToCompleteProposal = false;
    this.movetoComplete.moveToCompleteOther = false;
    this.movetoComplete.moveToCompleteCoElSow = false;
  }

  onClickMoveToCompleteBtn(): void {
    if (
      this.valueSelected &&
      this.valueSelected !== IntakeConstant.EMPTY_SPACE
    ) {
      if (
        this.valueSelected === this.NO_OPPORTUNITY_LOST_DISCONTINUE_ABANDONED &&
        this.selectedSubmissionType === IntakeDocumentSubmissionTypeCode.PRO
      ) {
        this.movetoComplete.moveToCompleteProposal = true;
        this.close(this.movetoComplete);
      } else if (
        this.valueSelected ===
          OtherOptionValues.NO_DOCUMENT_WILL_NOT_BE_EXECUTED &&
        this.selectedSubmissionType === IntakeDocumentSubmissionTypeCode.OTH
      ) {
        this.movetoComplete.moveToCompleteOther = true;
        this.close(this.movetoComplete);
      } else if (
        this.valueSelected ===
          CoElSowOptionValues.NO_DOCUMENT_WILL_NOT_BE_EXECUTED &&
        (this.selectedSubmissionType === this.oppertunitySubmissionTypeElSow ||
          this.selectedSubmissionType === this.oppertunitySubmissionTypeCo)
      ) {
        this.movetoComplete.moveToCompleteCoElSow = true;
        this.close(this.movetoComplete);
      }
    }
  }

  onClickCancel(): void {
    if (
      this.valueSelected &&
      this.valueSelected !== IntakeConstant.EMPTY_SPACE
    ) {
      this.close();
      switch (this.valueSelected) {
        case this.NO_OPPORTUNITY_LOST_DISCONTINUE_ABANDONED:
          break;
        case this.NO_DOCUMENT_WILL_NOT_BE_EXECUTED:
          this.submissionService.updateOtherSubmissionValue('');
          break;
        default:
          break;
      }
    }
  }

  isModalBtnVisible(): boolean {
    return (
      (this.selectedSubmissionType === IntakeDocumentSubmissionTypeCode.PRO &&
        this.valueSelected ===
          this.NO_OPPORTUNITY_LOST_DISCONTINUE_ABANDONED) ||
      (this.selectedSubmissionType === IntakeDocumentSubmissionTypeCode.OTH &&
        this.valueSelected === this.NO_DOCUMENT_WILL_NOT_BE_EXECUTED) ||
      ((this.selectedSubmissionType === this.oppertunitySubmissionTypeElSow ||
        this.selectedSubmissionType === this.oppertunitySubmissionTypeCo) &&
        this.valueSelected === this.NO_DOCUMENT_WILL_NOT_BE_EXECUTED)
    );
  }
}
