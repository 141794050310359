<div id="nca-tiles-container" class="tile-row" *ngIf="tilesCount">
  <div class="row flex-container">
    <div class="col-3" *ngFor="let tile of tileCodeDetails; let i = index" [hidden]="i >= 4" [ngStyle]="{'flex': tileCodeDetails.length === 3 ? '0 0 33%' : '0 0 0.313rem'}">
      <div id="{{tile.tileCode}}"
           class="dds-card ds-card_has-image dds-card_clickable {{tile.tileCode}}"
           (click)="tileSelected(tile.tileCode)"
           [ngClass]="{'active': selectedTileCode === tile.tileCode}">
        <div class="dds-card__content">
          <div class="row">
            <div class="col-6">
              <h1 class="dds-card__title">
                {{tilesCount[tile.countKey] ?? 0}}
                <span *ngIf="dashboardType === 'ReviewerDashboard' && tile.tileCode === 'CCDueNow'"> due</span>
              </h1>
            </div>
            <div *ngIf="dashboardType === 'NCADashboard' && tile.tileCode === 'CompleteMarsNcaForm'"
              class="col-6 mars-draft-span" [ngStyle]="{'margin-left': getDynamicTileCountStyle(tilesCount[tile.countKey])}">
              <span> ({{tilesCount[MARSNcasDraftCount] ?? 0}} draft)</span>
            </div>
          </div>
          <p class="dds-card__text">{{tile.title}}</p> 
        </div>
      </div>
    </div>
  </div>
  <div class="row flex-container" *ngIf="tileCodeDetails.length > 3">
    <div class="col-3" *ngFor="let tile of tileCodeDetails; let i = index" [hidden]="i < 4" [ngStyle]="{'flex': '0 0 0.313rem'}">
      <div id="{{tile.tileCode}}"
           class="dds-card ds-card_has-image dds-card_clickable {{tile.tileCode}}"
           (click)="tileSelected(tile.tileCode)"
           [ngClass]="{'active': selectedTileCode === tile.tileCode}">
        <div class="dds-card__content">
          <div class="row">
            <div class="col-6">
              <h1 class="dds-card__title">
                {{tilesCount[tile.countKey] ?? 0}}
                <span *ngIf="dashboardType === 'ReviewerDashboard' && tile.tileCode === 'CCDueNow'"> due</span>
              </h1>
            </div>
            <div *ngIf="dashboardType === 'NCADashboard' && tile.tileCode === 'CompleteMarsNcaForm'"
              class="col-6 mars-draft-span" [ngStyle]="{'margin-left': getDynamicTileCountStyle(tilesCount[tile.countKey])}">
              <span> ({{tilesCount[MARSNcasDraftCount] ?? 0}} draft)</span>
            </div>
          </div>
          <p class="dds-card__text">{{tile.title}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
