export class URLConstant{
    public static readonly COMMON_MARS_PATH: string = 'MARS/';
    public static readonly COMMON_NCA_PATH: string = 'NCA/';
    public static readonly CONCAT_ADD_REMOVE_TEAMMEMBER_PARAM: string = '&teamMemberId=';
    public static readonly GET_MARS_NCA_FORM_DATA: string = URLConstant.COMMON_MARS_PATH+'GetMarsNCAFormData?clientMilestoneId=';
    public static readonly UPDATE_MARS_NCA_FORM_DATA: string = URLConstant.COMMON_MARS_PATH+'UpdateMarsNCAFormData';
    public static readonly ADD_OWNERSHIP_INTEREST: string = URLConstant.COMMON_MARS_PATH+'AddOwnershipInterest?clientMilestoneMarsNcaFormId=';
    public static readonly UPDATE_OWNERSHIP_INTEREST: string = URLConstant.COMMON_MARS_PATH+'UpdateOwnershipInterest';
    public static readonly DELETE_OWNERSHIP_INTEREST: string = URLConstant.COMMON_MARS_PATH+'DeleteOwnershipInterest?clientMilestoneMarsOwnershipInterestId=';
    public static readonly SUBMIT_TO_BUSINESS_LEADERSHIP: string = URLConstant.COMMON_NCA_PATH+'ClientStatus/SetNCAReset';
    public static readonly ADD_MARS_NCA_TEAM_MEMBER: string = URLConstant.COMMON_MARS_PATH+'AddMarsNcaTeamMember?clientMilestoneMarsNcaFormId=';
    public static readonly REMOVE_MARS_NCA_TEAM_MEMBER: string = URLConstant.COMMON_MARS_PATH+'RemoveMarsNcaTeamMember?clientMilestoneMarsNcaFormId=';
    public static readonly ADD_NCA_NOTIFICATION_TEAM_MEMBER: string = URLConstant.COMMON_NCA_PATH+'ClientStatus/AddNCANotificationTeamMember?clientMilestoneId=';
    public static readonly REMOVE_NCA_NOTIFICATION_TEAM_MEMBER: string = URLConstant.COMMON_NCA_PATH+'ClientStatus/RemoveNCANotificationTeamMember?clientMilestoneId=';

}