import { Component, Input, OnInit } from '@angular/core';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import {
  buttonOptions,
  RecentlyReviewColumn,
  RecentReviewsColumns,
} from '../dashboard.helper';
import { StatusEnum } from '../../intake/constants/status-enum';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ReviewerDashboardWebapiService } from '../../http/dashboard/reviewer-webapi.service';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { Subject, takeUntil } from 'rxjs';
import {
  RecentlyViewedSubmissionList,
  RecentlyViewedSubmissions,
} from '../../common/models/start-page/reviewer-dashboard.model';
import { Router } from '@angular/router';
import { IntakeConstant } from '../../intake/constants/intake.constant';

@Component({
  selector: 'app-recently-viewed',
  templateUrl: './recently-viewed.component.html',
  styleUrl: './recently-viewed.component.less',
})
export class RecentlyViewedComponent implements OnInit {
  notFound: string = '';

  constructor(
    private readonly modalService: ModalService,
    private readonly reviewerWebapiService: ReviewerDashboardWebapiService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly router: Router
  ) {}
  ngOnInit(): void {
    this.fetchRecentlyViewedSubmission(this.pageNumber);
  }
  @Input() isFooter: boolean = false;
  @Input() size: string = '';
  @Input() isInverse: boolean = false;
  options: ButtonOptions = buttonOptions;
  pageNumber: number = 1;
  pageSize: number = IntakeConstant.PAGE_NUMBER_10;
  totalCount: number = 0;
  submssionDetailList!: RecentlyViewedSubmissions[];
  unsubscriber$:Subject<void> = new Subject<void>();
  public sampleColumns = RecentlyReviewColumn;

  fetchMoreSubmissions(event: any): void {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      if (this.pageNumber < this.totalCount / this.pageSize) {
        this.pageNumber++;
        this.fetchRecentlyViewedSubmission(this.pageNumber);
      }
    }
  }

  fetchRecentlyViewedSubmission(pageNumber: number): void {
    this.progressIndicator.show();
    this.reviewerWebapiService
      .getRecentlyViewedSubmissions(pageNumber, this.pageSize)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (submissionDetail: RecentlyViewedSubmissionList) => {
          if (submissionDetail?.totalCount > 0) {
            if (pageNumber === 1) {
              this.submssionDetailList = submissionDetail.submissionDetails;
            } else {
              this.submssionDetailList = [
                ...this.submssionDetailList,
                ...submissionDetail?.submissionDetails,
              ];
            }
            this.totalCount = submissionDetail?.totalCount;
          }
          this.progressIndicator.hide();
        },
        error: (err) => {
          this.progressIndicator.hide();
          console.error('Error fetching submission types', err);
        },
      });
  }

  public goToSubmission(opportunityNumber: string, submissionId: number):void {
    this.router.navigate(['/submission/opportunity-details', opportunityNumber], {
      state: {
        navigatingFromDashboard: true,
        submissionID: submissionId,
      },
    });
    this.close();
  }

  transformStatus(status: string): StatusEnum {
    return (
      Object.values(StatusEnum).find((enumValue) => enumValue === status) ||
      StatusEnum.Draft
    );
  }

  close(): void {
    this.modalService.close();
  }
}
