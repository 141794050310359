import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ifEmpty' })
export class ifEmptyPipe implements PipeTransform {
  transform(
    value: boolean | string | number,
    emptyValue: string
  ): boolean | string | number {
    if (typeof value === 'boolean') {
      return value !== null && value !== undefined ? value : emptyValue;
    }
    const valStr =
      value !== null &&
      value !== undefined &&
      value !== 'null' &&
      value !== 'undefined'
        ? String(value)
        : '';
    return valStr.length ? value : emptyValue;
  }
}
