    <div class="clientpicker-section mars-nca-flex-box margin-top-xs">
    <div ngbDropdown #clientdropdown="ngbDropdown" class="client-picker mars-nca-flex-sub-container">
      <div class="search-container" ngbDropdownAnchor>
        <dds-search [formControl]="clientSearchFormCtrl"
                    [size]="searchOptions.size ?? ''"
                    [icon]="!clientSelectedItem ? (searchOptions.icon || 'dds-icon_search') : ''"
                    (valueChanged)="clientSearch()"
                    (fieldCleared)="clearClientSearch()">
          <input ddsSearch
                 [placeholder]="clientSelectedItem ? '' : searchOptions.placeholder ?? ''"
                 [(ngModel)]="clientSearchKey"
                 (input)="clientSearch()"
                 (click)="clientInputClick()"
                 [ngClass]="{'hidden': clientSelectedItem}" />
        </dds-search>
        <div *ngIf="clientSelectedItem" class="selected-item-display">
          <span class="client-number padding-right-xxs">{{ clientSelectedItem.clientName }}</span>
          <span class="sap-id padding-right-xxs" *ngIf="showClientSelectedId">(ID: {{ clientSelectedItem.clientNumber }})</span>
          <span class="dds-icon dds-icon_close" (click)="clearClientSearch()"></span>
        </div>
      </div>
      <div #menu ngbDropdownMenu role="menu" aria-labelledby="single-button" class="items-dropdown">
        <div class="search-list-label padding-bottom-none" [hidden]="!isLoading">Searching...</div>
        <div [hidden]="isLoading">
          <div class="no-matches" [hidden]="clientFilteredItems.length != 0">No matches found</div>
        </div>
        <button *ngFor="let item of clientFilteredItems" ngbDropdownItem (click)="selectItem(item)">
          <div class="eclipse-content">
            <span class="client-number padding-right-xxs">{{ item.clientName }}</span>
            <span class="sap-id padding-right-xxs">(ID: {{ item.clientNumber }})</span>
          </div>
        </button>
      </div>
    </div>
  </div>