import { SearchOptions } from "@usitsdasdesign/dds-ng/search";
import { Size, Themes } from "@usitsdasdesign/dds-ng/shared";
import { TagOptions } from "@usitsdasdesign/dds-ng/tags";

export class ConsultingNCAConstants {
    public static readonly Search_MultiOptions_Placeholder: string = 'Add team member';
    public static readonly searchMultiOptions: SearchOptions = {
    placeholder: ConsultingNCAConstants.Search_MultiOptions_Placeholder,
    size: Size.lg,
    customClass: '',
  };
  public static readonly multiOptions: TagOptions = {
    theme: Themes.dark,
    isRemovable: true,
    disabled: true,
    isError: false,
    isInverse: false,
    size: Size.lg,
    customClass: ''
  };
  public static readonly COMPLETED_NCA_FORM_PDF_HEADING: string = 'Completed #clientMilestoneProcessType form PDF';
  public static readonly COMPLETED_NCA_FORM_PDF_VALUE: string = 'Completed#clientMilestoneProcessTypeformPDF';
  public static readonly EXPORT_NCA_TOOLTIP_INFO: string = 'The exported PDF will contain client information at the time NCA was approved';
  public static readonly EXPORT_MARS_NCA_FORM_PDF: string = 'MARSNCAFormPDF';
  public static readonly EXPORT_MARS_NCA_FORM_PDF_HEADING: string = 'MARS NCA Form PDF';
  public static readonly EXPORT_CC_TOOLTIP_INFO: string = 'The exported PDF will contain client information at the time Client continuance was confirmed';
}
