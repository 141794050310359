import { ErrorState, Themes } from '@usitsdasdesign/dds-ng/shared';
import { ToastOptions } from '@usitsdasdesign/dds-ng/toast';

export const ToggleGroupOptions = {
  title: '',
  isInverse: false,
  isRequired: false,
};

export const ToggleOptions = {
  theme: Themes.green,
  label: 'Hide all draft documents (e.g. drafts and supporting documents)',
  ariaLabel: '',
  disabled: false,
  isError: false,
  errorState: ErrorState.default,
  isProcessingStatusActive: false,
  isRequired: false,
  customClass: '',
};

export const Column = [
  { name: 'Title', header: 'Title', dataType: 'string' },
  { name: '', header: '', dataType: 'string' },
  { name: 'Type', header: 'Type', dataType: 'string' },
  {
    name: 'Status',
    header: 'Status',
    dataType: 'string',
  },
  { name: 'Uploaded Date', header: 'Uploaded Date', dataType: 'string' },
  { name: 'Uploaded By', header: 'Uploaded By', dataType: 'string' },
  { name: 'Actions', header: 'Actions', dataType: 'string' },
];

  export const toast: ToastOptions = {
    title: 'Upload another RM Reviewed/Archive ready - final version document before deleting this one.',
    message: '',
    lifeTime: 3000,
    withProgressBar: false,
    position: 'bottom-center',
    closeBtnIcon: 'dds-icon_close',
    isCloseIcon: false,
    customClass: '',
    isError: false,
    isInverse: false,
    isLoading: false,
    limit: 5,
    theme: Themes.dark
  };
