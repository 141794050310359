import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environment/environment';
import { UrlConstant } from '../../intake/constants/url.constant';
import { ArchiveDocument } from '../../common/models/archive-document.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(private readonly httpClient: HttpClient) {}
  baseURL: string = environment.apiUrl;
  //baseURL: string = 'https://dapi.eclipse.deloitte.com/api/'; //dev
  //baseURL: string = 'https://qapi.eclipse.deloitte.com/api/'; //qa

  getPresignedUrlDetails(data: any): Observable<any> {
    const url = this.baseURL + UrlConstant.GET_PRESIGNED_DETAILS_PATH;
    return this.httpClient.post(url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('idToken'),
      }),
    });
  }

  saveDocumentMetaData(data: any): Observable<any> {
    const url = this.baseURL + UrlConstant.GET_SAVE_DOCUMENT_META__DATA_PATH;
    let finalData = JSON.stringify(data);
    return this.httpClient.post(url, finalData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('idToken'),
      }),
    });
  }

  updateDocumentMetaData(data: any): Observable<any> {
    const url = this.baseURL + UrlConstant.GET_UPDATE_DOCUMENT_META__DATA_PATH;
    let finalData = JSON.stringify(data);
    return this.httpClient.post(url, finalData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('idToken'),
      }),
    });
  }

  documentUploadByPresignedURL(data: any, fileSource: any): Observable<any> {
    const url = data.url;
    return this.httpClient.put(url, fileSource, {
      headers: new HttpHeaders({
        'Content-Type': 'binary/octet-stream',
        'x-amz-server-side-encryption': 'aws:kms',
        'x-amz-server-side-encryption-aws-kms-key-id': data.kmsArn,
      }),
    });
  }

  getDownloadUrl(downloadRequest: any): Observable<any> {
    var data = JSON.stringify(downloadRequest);
    const url = this.baseURL + UrlConstant.GET_DOWNLOAD_PATH;
    return this.httpClient.post(url, data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('idToken'),
      }),
    });
  }

  getArchivedDocDownloadUrl(docId: number): Observable<any> {
    const url = this.baseURL + UrlConstant.GET_ARCHIVE_DOWNLOAD_PATH + docId;
    return this.httpClient.get(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('idToken'),
      }),
    });
  }

  deleteDocument(documentKey: any, fileFolderPath: any): Observable<any> {
    const url =
      this.baseURL +
      UrlConstant.GET_DELETE_DOCUMENT_PATH +
      documentKey +
      '&fileFolderPath=' +
      fileFolderPath;
    return this.httpClient.delete(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('idToken'),
      }),
    });
  }

  uploadDocument(file: any, filePath: any) {
    const url = this.baseURL + UrlConstant.GET_UPLOAD_DOCUMENT_PATH;
    const formData: FormData = new FormData();
    formData.append('file', file.fileSource, file.fileSource.name);
    formData.append('FileFolderPath', filePath);
    return this.httpClient.post(url, formData, {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('idToken'),
      }),
    });
  }

  exportClientDataPDF(
    clientNumber: string,
    isNCA: boolean,
    isCC: boolean,
    isMars: boolean,
    clientMilestoneId: number
  ): void {
    const url = this.baseURL + UrlConstant.GET_EXPORT_CLIENT_DATA_PDF_PATH;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      accept: 'application/pdf',
    });

    this.httpClient
      .post<Blob>(
        url,
        { clientNumber, isNCA, isCC, isMars, clientMilestoneId },
        { headers, observe: 'response', responseType: 'blob' as 'json' }
      )
      .subscribe(
        (response: any) => {
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const contentDisposition = response.headers.get(
            'Content-Disposition'
          );
          let fileName = 'ClientData.pdf'; // Default file name

          if (contentDisposition) {
            const matches = /filename="([^"]*)"/.exec(contentDisposition);
            if (matches != null && matches[1]) {
              fileName = matches[1];
            }
          }

          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        },
        (error) => {
          console.error('Download error:', error);
        }
      );
  }

  downloadPdf(req: any): Observable<any> {
    const url = this.baseURL + UrlConstant.GET_PDF_DOWNLOAD_PATH;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      accept: 'application/pdf',
    });
    return this.httpClient.post<Blob>( url, req, { headers, observe: 'response', responseType: 'blob' as 'json' })
  }

  getDocumentArchiveId(
    submissionId: number,
    oppertunityNumber: string
  ): Observable<ArchiveDocument> {
    const url = this.baseURL + UrlConstant.GET_ARCHIVE_SUBMISSION_ID_PATH;
    return this.httpClient.get<ArchiveDocument>(
      `${url}${submissionId}&opportunityNumber=${oppertunityNumber}`
    );
  }

  //getDocumentUploadData(attachmentID:string): Observable<DocumentTypeData[]> {
  //  const url = this.baseURL + 'Document/GetDocumentMetaData?attachmentID=' + attachmentID;
  //  return this.myhttp.get<DocumentTypeData[]>(url, {
  //    headers: new HttpHeaders({
  //      'Content-Type': 'application/json',
  //      'Authorization': "Bearer " + localStorage.getItem("idToken")
  //    })
  //  })
  //}
}
