import { Component } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { IntakeConstant } from '../../constants/intake.constant';
import { IntakeOptOutPopUpDataModel } from '../../../common/models/initiateSubmission.model';

@Component({
  selector: 'app-opt-out-popup',
  templateUrl: './opt-out-popup.component.html',
  styleUrl: './opt-out-popup.component.less',
})
export class OptOutPopupComponent {
  optOutReason: string = '';
  minHeight: number = IntakeConstant.TEXTAREA_MIN_HEIGHT;
  updateSubmission: IntakeOptOutPopUpDataModel = {
    submissionLevelUpdateSubmission: false,
    optOutReason: '',
  };
  textareaOptions: any = IntakeConstant.TEXT_AREA_SETTINGS_GLOBAL;
  constructor(
    public readonly modalService: ModalService
  ) {}

  save(): void {
    this.updateSubmission.submissionLevelUpdateSubmission = true;
    this.updateSubmission.optOutReason = this.optOutReason;
    this.close();
  }

  close(): void {
    this.modalService.close(this.updateSubmission);
  }
}
