<div class="dds-modal-overlay"
     (click)="close()"></div>

<div ddsResize
     class="dds-modal dds-modal_lg">
  <div class="dds-modal__header">
    <!--to add dynamic client name-->
    <span class="dds-modal__title">{{labelValues.labelHeader}}</span>
    <button class="dds-modal__close dds-icon dds-icon_close" (click)="cancel()" aria-label="Close modal"></button>
  </div>

  <div class="dds-modal__body">

    <div class="moreText margin-bottom-s">
      <span class="client-text">Client: </span>
      <span class="padding-right-xxs">{{labelValues.clientname}}</span>
      <span class="sap-id padding-right-xxs">(ID: {{labelValues.clientnumber}})</span>
      <span [ngClass]="{
      'serve-with-conditions-container': labelValues.watchliststatusId === WatchlistStatus.ServeWithConditions,
      'do-not-serve-container': labelValues.watchliststatusId === WatchlistStatus.DoNotServe}">
        {{labelValues.watchliststatusname}}
      </span>
    </div>

    <div class="moreText margin-top-s">
      <span [innerHtml]="labelValues.comment"></span>
    </div>
  </div>

</div>
