<div class="mars-nca-details-section">
  <div class="mars-nca-header-block">
    <p class="eclipse-main-tab-header">Initiation - MARS NCA</p>
    <div class="buttons-flex-sub-container padding-right-xs">
      <dds-button id="initiation-mars-nca-initiate-button"
                  [theme]="initiateButtonOptions.theme ?? ''"
                  [kind]="initiateButtonOptions.kind ?? ''"
                  [size]="initiateButtonOptions.size ?? ''"
                  [width]="initiateButtonOptions.width ?? ''"
                  [ariaLabel]="initiateButtonOptions.ariaLabel ?? ''"
                  [disabled]="!this.isValid() || this.isActiveMarsFormExists"
                  (clicked)="initiateMars()">Initiate</dds-button>
      <dds-button id="initiation-mars-nca-cancel-button"
                  [theme]="cancelButtonOptions.theme ?? ''"
                  [kind]="cancelButtonOptions.kind ?? ''"
                  [size]="cancelButtonOptions.size ?? ''"
                  [width]="cancelButtonOptions.width ?? ''"
                  [ariaLabel]="cancelButtonOptions.ariaLabel ?? ''"
                  (clicked)="cancelPage()">Cancel</dds-button>
    </div>
  </div>
  <p class="eclipse-content-grey">Provide all required information and select "Initiate" to create a new MARS NCA request</p>

  <div id="mars-nca-initiate-questionaire">
    <div>
      <div class="margin-top-s">
        <span [hidden]="isClientNotListed ? otherClientName : model.clientName" class="icon-required-yellow"></span>
        <span class="text-label">Client name:</span>
      </div>
      <div class="mars-nca-flex-box margin-top-xs">

        <div *ngIf="!isClientNotListed; else ClientNotListedElseBlock" class="col-4">
          <div ngbDropdown #clientdropdown="ngbDropdown" class="client-picker mars-nca-flex-sub-container">
            <div class="search-container" ngbDropdownAnchor>
              <dds-search [formControl]="clientSearchFormCtrl"
                          [size]="searchOptions.size ?? ''"
                          [icon]="!clientSelectedItem ? (searchOptions.icon || 'dds-icon_search') : ''"
                          (valueChanged)="clientSearch()"
                          (fieldCleared)="clearClientSearch()">
                <input ddsSearch
                       [placeholder]="clientSelectedItem ? '' : searchOptions.placeholder ?? ''"
                       [(ngModel)]="clientSearchKey"
                       (input)="clientSearch()"
                       (click)="clientInputClick()"
                       [ngClass]="{'hidden': clientSelectedItem}" />
              </dds-search>
              <div *ngIf="clientSelectedItem" class="selected-item-display">
                <span class="client-number padding-right-xxs">{{ clientSelectedItem.clientName }}</span>
                <span class="sap-id padding-right-xxs">(ID: {{ clientSelectedItem.clientNumber }})</span>
                <span class="dds-icon dds-icon_close" (click)="clearClientSearch()"></span>
              </div>
            </div>
            <div #menu ngbDropdownMenu role="menu" aria-labelledby="single-button" class="items-dropdown">
              <div class="search-list-label padding-bottom-none" [hidden]="!isLoading">Searching...</div>
              <div [hidden]="isLoading">
                <div class="no-matches" [hidden]="clientFilteredItems.length != 0">No matches found</div>
              </div>
              <button *ngFor="let item of clientFilteredItems" ngbDropdownItem (click)="selectItem(item)">
                <div class="dropdown-item-content">
                  <span class="client-number padding-right-xxs">{{ item.clientName }}</span>
                  <span class="sap-id padding-right-xxs">(ID: {{ item.clientNumber }})</span>
                </div>
              </button>
            </div>
          </div>
        </div>

        <ng-template #ClientNotListedElseBlock>
          <div class="col-4">
            <dds-input class="mars-nca-flex-sub-container"
                       [placeholder]="inputOptions.placeholder ?? ''"
                       [size]="inputOptions.size ?? ''"
                       [maxLength]="inputOptions.maxLength ?? 0"
                       [maxLengthPosition]="inputOptions.maxLengthPosition ?? ''"
                       [(ngModel)]="otherClientName"
                       (ngModelChange)="changeClientName()"
                       [disabled]="isCheckingNameInProcess"></dds-input>
          </div>
        </ng-template>


        <dds-checkbox class="mars-nca-flex-sub-container padding-xs"
                      [ngClass]="{'client-not-listed-margin': isClientNotListed}"
                      [formControl]="checkboxFormCtrl"
                      [label]="options.label ?? ''"
                      [theme]="options.theme ?? ''"
                      (stateChanged)="stateChanged()">
        </dds-checkbox>
        <span class="mars-nca-flex-sub-container padding-right-xs margin-top-xs icon-info"
              [ngClass]="{'client-not-listed-margin': isClientNotListed}"
              [ddsTooltip]="'enter full legal entity name'"
              [tooltipInvokeType]="clientTooltipOptions.tooltipInvokeType ?? ''"
              [tooltipType]="clientTooltipOptions.tooltipType ?? ''"
              [tooltipSize]="clientTooltipOptions.tooltipSize  ?? ''"
              [tooltipPosition]="clientTooltipOptions.tooltipPosition ?? ''"
              [tooltipIndent]="clientTooltipOptions.tooltipIndent ?? 0"
              [tooltipHasBeak]="clientTooltipOptions.tooltipHasBeak ?? false"
              [tooltipTheme]="clientTooltipOptions.tooltipTheme ?? ''" ddsFocus></span>
      </div>

      <div class="margin-top-xs" *ngIf="isClientNotListed ? (isClientNameExists && !isCheckingNameInProcess) : isActiveMarsFormExists">
        <span class="text-red">
          MARS NCA request is already in progress for this client
        </span>
      </div>
    </div>
    <div>
      <div class="margin-top-m">
        <span [hidden]="model.marsClientTypeID" class="icon-required-yellow"></span>
        <span class="text-label">Client type:</span>
      </div>
      <div class="col-4 margin-top-xs">
        <dds-select [list]="optionsMARSClientType" [placeholder]="selectOptionsMARSClientType.placeholder ?? ''"
                    [size]="selectOptionsMARSClientType.size ?? ''"
                    [(ngModel)]="model.marsClientTypeID">
        </dds-select>
      </div>
    </div>
    <div>
      <div class="margin-top-m">
        <span [hidden]="model.recommendingPpmd" class="icon-required-yellow"></span>
        <span class="text-label">Recommending PPMD:</span>
      </div>
      <div class="mars-nca-flex-box margin-top-xs">
        <div ngbDropdown #ppmddropdown="ngbDropdown" class="client-picker col-4 mars-nca-flex-sub-container">
          <div class="search-container" ngbDropdownAnchor>
            <dds-search [formControl]="ppmdSearchFormCtrl"
                        [size]="searchOptions.size ?? ''"
                        [icon]="!ppmdSelectedItem ? (searchOptions.icon || 'dds-icon_search') : ''"
                        (valueChanged)="PPMDSearch()"
                        (fieldCleared)="clearPPMDSearch()">
              <input ddsSearch
                     [placeholder]="ppmdSelectedItem ? '' : searchOptions.placeholder ?? ''"
                     [(ngModel)]="ppmdSearchKey"
                     (input)="PPMDSearch()"
                     (click)="PPMDInputClick()"
                     [ngClass]="{'hidden': ppmdSelectedItem}" />
            </dds-search>
            <div *ngIf="ppmdSelectedItem" class="selected-item-display">
              <span class="client-number padding-right-xxs">{{ ppmdSelectedItem.outlookDisplayName }}</span>
              <span class="dds-icon dds-icon_close" (click)="clearPPMDSearch()"></span>
            </div>
          </div>
          <div #menu ngbDropdownMenu role="menu" aria-labelledby="single-button" class="items-dropdown">
            <div class="search-list-label padding-bottom-none" [hidden]="!isLoading">Searching...</div>
            <div [hidden]="isLoading">
              <div class="no-matches" [hidden]="ppmdFilteredItems.length != 0">No matches found</div>
            </div>
            <button *ngFor="let item of ppmdFilteredItems" ngbDropdownItem (click)="selectItemPPMD(item)">
              <div class="dropdown-item-content">
                <span class="client-number padding-right-xxs">{{ item.outlookDisplayName }}</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="margin-top-m">
        <span [hidden]="model.isHedgeFund" class="icon-required-yellow"></span>
        <span class="text-label">
          Is the prospective client a hedge fund, underwriter, lender,
          <a class="hover-text"
             [ddsTooltip]="'or company otherwise registered under the Investment Company Act of 1940'"
             [tooltipInvokeType]="clientTooltipOptions.tooltipInvokeType ?? ''"
             [tooltipType]="clientTooltipOptions.tooltipType ?? ''"
             [tooltipSize]="clientTooltipOptions.tooltipSize  ?? ''"
             [tooltipPosition]="clientTooltipOptions.tooltipPosition ?? ''"
             [tooltipIndent]="clientTooltipOptions.tooltipIndent ?? 0"
             [tooltipHasBeak]="clientTooltipOptions.tooltipHasBeak ?? false"
             [tooltipTheme]="clientTooltipOptions.tooltipTheme ?? ''" ddsFocus>business development company</a>, special <br /> purpose acquisition company (SPAC), development stage entity, sovereign wealth fund or other state-<br />owned or controlled enterprise, or high net worth individual?
        </span>
      </div>
      <div class="col-3 margin-top-xs">
        <dds-radio-group name="isHedgeFundOptions" [(ngModel)]="model.isHedgeFund">

          <ng-container *ngFor="let radio of radioBtns">
            <dds-radio class="radio-btn"
                       [value]="radio.value !== undefined ? radio.value : ''"
                       [label]="radio.options.label ?? ''"
                       [theme]="radio.options.theme ?? ''"></dds-radio>
            <br />
          </ng-container>
        </dds-radio-group>
      </div>
      <div class="margin-top-s margin-bottom-s" *ngIf="model.isHedgeFund === 'true'">
        <div class="disclaimer-message-container">
          <span class="dds-icon dds-icon_pin pin-style"></span>
          <div class="eclipse-content-grey pin-margin">
            <span class="margin-top-xxs">MARS QRM will be connecting with you for additional discussion</span>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="margin-top-m">
        <span [hidden]="model.isConsultedWithQrm" class="icon-required-yellow"></span>
        <span class="text-label">
          Have you consulted with MARS QRM?
        </span>
      </div>
      <div class="col-3 margin-top-xs">
        <dds-radio-group name="isConsultedWithQrmOptions"
                         [(ngModel)]="model.isConsultedWithQrm"
                         (ngModelChange)="changeIsConsultedWithQrm()"
                         [disabled]="isMarsConsultationWithMarsQrmSummaryDisabledBySecurity">

          <ng-container *ngFor="let radio of radioBtns">
            <dds-radio class="radio-btn"
                       [value]="radio.value !== undefined ? radio.value : ''"
                       [label]="radio.options.label ?? ''"
                       [theme]="radio.options.theme ?? ''"
                       [disabled]="isMarsConsultationWithMarsQrmSummaryDisabledBySecurity"></dds-radio>
            <br />
          </ng-container>
        </dds-radio-group>
      </div>
      <div class="margin-top-s margin-bottom-s" *ngIf="model.isConsultedWithQrm === 'true'">
        <div class="margin-top-m">
          <span [hidden]="model.applicableMarsQrm" class="icon-required-yellow"></span>
          <span class="text-label">Applicable MARS QRM:</span>
        </div>
        <div class="col-4 margin-top-xs">
          <dds-select [formControl]="selectFormCtrl"
                      [list]="optionsMarsQrmEmployees"
                      [placeholder]="selectOptionsMarsQrm.placeholder ?? ''"
                      [size]="selectOptionsMarsQrm.size ?? ''"
                      (valueChanged)="selectMarsQrm()">
          </dds-select>
        </div>

        <div class="margin-top-m">
          <span [hidden]="model.consultationSummary" class="icon-required-yellow"></span>
          <span class="text-label padding-right-xs">Consultation summary with MARS QRM:</span>
          <span class="icon-required-yellow margin-top-xs icon-info"
                [ddsTooltip]="consultationSummaryHoverText"
                [tooltipInvokeType]="clientTooltipOptions.tooltipInvokeType ?? ''"
                [tooltipType]="clientTooltipOptions.tooltipType ?? ''"
                [tooltipSize]="clientTooltipOptions.tooltipSize  ?? ''"
                [tooltipPosition]="clientTooltipOptions.tooltipPosition ?? ''"
                [tooltipIndent]="clientTooltipOptions.tooltipIndent ?? 0"
                [tooltipHasBeak]="clientTooltipOptions.tooltipHasBeak ?? false"
                [tooltipTheme]="clientTooltipOptions.tooltipTheme ?? ''" ddsFocus></span>
        </div>
        <div class="col-4">
          <dds-textarea [placeholder]="textareaOptions.placeholder ?? ''"
                        [minHeight]="textareaOptions.minHeight ?? 0" [maxLength]="textareaOptions.maxLength ?? 0"
                        [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''" [size]="textareaOptions.size ?? ''"
                        [(ngModel)]="model.consultationSummary"></dds-textarea>
        </div>

      </div>
    </div>
  </div>
</div>
