import { ThirdPartyRepositoryGridComponent } from '../third-party-repository/third-party-repository-grid/third-party-repository-grid.component';
import { ThirdPartyRepositoryAddComponent } from '../third-party-repository/third-party-repository-add/third-party-repository-add.component';

export const THIRD_PARTY_REPOSITORY_ROUTES = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ThirdPartyRepositoryGridComponent,
      },
      {
        path: 'thirdparty/add',
        component: ThirdPartyRepositoryAddComponent,
      }
    ],
  },
];
