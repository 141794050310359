import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, filter, Subject, switchMap } from 'rxjs';
import { ClientSearchResult, InitiationApiService } from '../../../http/initiation/initiation-api.service';
import { FormControl } from '@angular/forms';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import {  Size } from '@usitsdasdesign/dds-ng/shared';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-client-picker',
  templateUrl: './client-picker.component.html',
  styleUrl: './client-picker.component.less'
})
export class ClientPickerComponent {
  @Output() clientSelected: EventEmitter<any> = new EventEmitter<any>();
  @Input()  showClientSelectedId: boolean = false;
  @Input() showClientSelectionConfirmPopup: boolean = false;
  @ViewChild('clientdropdown', { static: false }) clientdropdown?: NgbDropdown;
  public clientSearchKey: string = '';
  private clientSearchKey$ = new Subject<string>();
  clientFilteredItems: ClientSearchResult[] = [];
  clientSelectedItem?: ClientSearchResult;
  clientSearchFormCtrl = new FormControl({ value: '', disabled: false });
  public clientSearchmodel: ClientSearchResult = {} as ClientSearchResult;

  public isLoading: boolean = false;

  searchOptions: SearchOptions = {
      placeholder: 'Search by name or ID',
      size: Size.md,
      customClass: '',
    };

  clientNumber: string='';
  clientName:string='';

  constructor(
      private initiationApiService: InitiationApiService
      ,private modalService: ModalService
    ) { }

 /*Client search section - start*/
   public clearClientSearch() {
     this.clientSearchKey = '';
     this.clientSearchKey$.next(this.clientSearchKey);
     this.clientFilteredItems = [];
     this.clientSelectedItem = undefined;
 
     this.clientNumber = '';
 
     if (this.clientdropdown && this.clientdropdown.isOpen()) {
       this.clientdropdown.close();
     }
   }
 
   public clientSearch() {
     if (this.clientSearchKey && this.clientSearchKey.length > 2) {
       this.clientSearchKey$.next(this.clientSearchKey.trim());
     }
     else {
       this.clientFilteredItems = [];
       if (this.clientdropdown && this.clientdropdown.isOpen()) {
         this.clientdropdown.close();
       }
       this.isLoading = true;
     }
   }
 
   selectItem(item: ClientSearchResult) {

    let modalRef = this.modalService.open(ConfirmPopupComponent, {
          size: 'xl',
          backdrop: 'static',
          centered: true,
          windowClass: ' center-modal modal-fade-in modal-adaptive convert-nca-to-cc-dialog-modal'
        })
        let labelHeaderText = 'Warning';
        modalRef.componentInstance.resolve = {
          labelHeader: labelHeaderText,
          labelBody: 'Are you sure the selected client is correct? You will not be able to modify it after confirmation'
        };

        modalRef.componentInstance.isConfirmed.subscribe((res:any) => {
         if(res){
            this.clientSearchKey = '';
            this.clientSearchFormCtrl.setValue(this.clientSearchKey, { emitEvent: false });
            this.clientFilteredItems = [];
            this.clientSelectedItem = item;
            this.clientSearchmodel = item;

            this.clientNumber = item.clientNumber;
            this.clientName = item.clientName;
            this.clientSelected.emit(this.clientNumber);
         }
      });
    
   }
 
   public clientInputClick() {
     this.isLoading = true;
     this.clientSearchKey$.pipe(
       debounceTime(500),
       filter(key => !!key && key.length > 2),
       switchMap(key => this.searchItems(key))
     ).subscribe({
       next: (response: any) => {
         this.clientFilteredItems = response;
         this.isLoading = false;
       },
       error: (err) => {
         this.clientFilteredItems = [];
       }
     });
 
     this.clientSearchKey$.next(this.clientSearchKey);
     if (this.clientdropdown && !this.clientdropdown.isOpen() && this.clientSearchKey.length > 2) {
       this.clientdropdown.open();
     }
   }
 
   private searchItems(key: string) {
     if (this.clientdropdown && !this.clientdropdown.isOpen()) {
       this.clientdropdown.open();
     }
 
     return this.initiationApiService.searchClients(key);
   }
   /*Client search section - end*/
}
