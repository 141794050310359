import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { IntakeConstant } from '../../../constants/intake.constant';
import { SubmissionService } from '../../../../http/intake/submission.service';
import { ProgressIndicatorService } from '../../../../common/services/progress-indicator.service';
import { Subject, takeUntil } from 'rxjs';
import {
  RADIO_GROUP_OPTIONS_FOR_STATUS,
  selectOptions,
} from './reset-status-popup-helper';
import { CommonService } from '../../../../http/intake/common.service';
import { OppertunitySubmissionStatusCode } from '../../../../common/models/oppertunity-submission-statusCode.model';
import {
  IntakeSubmissionActionReasonDropdown,
  SubmissionStatusByActionModel,
} from '../../../../common/models/submission-action.model';
import { RadioButton } from '@usitsdasdesign/dds-ng/radio';
import { capitalizeFirstLetter } from '../../../common/intake.util';

@Component({
  selector: 'app-reset-status-popup',
  templateUrl: './reset-status-popup.component.html',
  styleUrl: './reset-status-popup.component.less',
})
export class ResetStatusPopupComponent implements OnInit {
  constructor(
    private readonly modalService: ModalService,
    private readonly submissionService: SubmissionService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly commonService: CommonService
  ) {}

  @Input() isFooter: boolean = false;
  @Input() size: string = IntakeConstant.EMPTY_SPACE;
  @Input() isInverse: boolean = false;
  @Input() submissionId: number = 0;
  @Input() submissionStatus: string = IntakeConstant.EMPTY_SPACE;
  status: string = IntakeConstant.EMPTY_SPACE;
  status_radioGroupOptions: any = RADIO_GROUP_OPTIONS_FOR_STATUS;
  submissionStatusAction: SubmissionStatusByActionModel[] = [];
  statusRadioValues: RadioButton[] = [];
  actionDropdownList: SelectItemOptions[] = [];
  selectedReason: number = 0;
  otherReasonDescription: string = IntakeConstant.EMPTY_SPACE;
  unsubscriber$: Subject<void> = new Subject<void>();
  textareaOptions = IntakeConstant.TEXT_AREA_SETTINGS_GLOBAL;
  selectOptions = selectOptions;
  statusCodeList!: OppertunitySubmissionStatusCode[];
  showReasonTextBox: boolean = false;
  dropdownOptions: IntakeSubmissionActionReasonDropdown[] = [];
  resetButtonSelected: boolean = false;

  ngOnInit(): void {
    this.getActionDropdownData(IntakeConstant.ResetActionID);
    this.commonService
      .getOppertunityStatusCode()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: OppertunitySubmissionStatusCode[]) => {
          this.statusCodeList = response;
        },
        error: (err) => {
          console.error('An error occurred during fetching data: ', err);
        },
      });
    this.loadStatusResetModalRadioButtons();
  }

  loadStatusResetModalRadioButtons(): void {
    this.progressIndicator.show();
    this.submissionService
      .getSubmissionStatusByAction(IntakeConstant.ResetActionID.toString())
      .subscribe({
        next: (result: SubmissionStatusByActionModel[]) => {
          const convertedArray = result.map(
            (item: SubmissionStatusByActionModel) => ({
              options: {
                label: capitalizeFirstLetter(item.submissionStatusName),
                theme: IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR,
              },
              value: item.submissionStatusId.toString(),
            })
          );
          this.statusRadioValues = convertedArray;
          this.progressIndicator.hide();
        },
        error: (err: any) => {
          console.error(err);
          this.progressIndicator.hide();
        },
      });
  }

  getActionDropdownData(id: number): void {
    this.submissionService.getActionReasons(id).subscribe({
      next: (result: IntakeSubmissionActionReasonDropdown[]) => {
        const convertedArray: SelectItemOptions[] = result.map((item: any) => ({
          value: item.reasonId,
          heading: item.reason,
        }));
        this.actionDropdownList = convertedArray;
        this.dropdownOptions = result;
      },
      error: (err: any) => {
        this.progressIndicator.hide();
      },
    });
  }

  handleDropDownSelected(data: number): void {
    const selectedObj = this.dropdownOptions.filter(
      (item: IntakeSubmissionActionReasonDropdown) => item.reasonId === data
    );
    selectedObj[0].enableText
      ? (this.showReasonTextBox = true)
      : (this.showReasonTextBox = false);
  }

  async saveSubmissionHistory(): Promise<void> {
    const selectedRadioStatus = this.statusRadioValues.filter(
      (radio) => radio.value === this.status.toString()
    );
    const postRequestData = {
      submissionId: this.submissionId,
      currentStatusId: Number(selectedRadioStatus[0].value),
      previousStatusId: Number(
        this.statusCodeList.find(
          (x: OppertunitySubmissionStatusCode) =>
            x.statusName === this.submissionStatus
        )?.id
      ),
      submissionActionId: IntakeConstant.ResetActionID,
      reasonId: this.selectedReason,
      otherReasonDescription: this.otherReasonDescription,
    };
    this.submissionService
      .saveSubmissionHistory(postRequestData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: any) => {
          console.log(response);
        },
        error: (err) => {
          console.error('An error occurred while saving the data: ', err);
        },
      });
      this.resetButtonSelected = true
    this.close();
  }

  formValid(): boolean {
    if (this.selectedReason != 0 && this.status != IntakeConstant.EMPTY_SPACE)
      if (
        this.showReasonTextBox &&
        this.otherReasonDescription != IntakeConstant.EMPTY_SPACE
      )
        return true;
      else if (!this.showReasonTextBox) return true;
      else return false;
    return false;
  }

  close(): void {
    this.modalService.close(this.resetButtonSelected);
  }
}
