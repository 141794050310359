<div class="dds-modal-overlay" (click)="close()"></div>

<div
  ddsResize
  [ngClass]="{
    'dds-modal_lg': size === 'lg',
    'dds-modal_inverse': isInverse
  }"
  class="dds-modal"
>
  <div class="dds-modal__header">
    <span class="dds-modal__title font-size-16 font-bold-600 modal-lone-height"
      >Discontinue submission</span
    >
    <dds-button
      size="sm"
      icon="dds-icon_close"
      kind="silent"
      theme="dark"
      ariaLabel="Close modal"
      class="dds-modal__close"
      [isInverse]="isInverse"
      (clicked)="close()"
    ></dds-button>
  </div>

  <div class="dds-modal__body">
    <div>
      <div
        class="padding-right-m padding-left-s info font-size-14 line-height-sm"
      >
        <div class="font-bold-600">
          Discontinuing this submission will update its status to Discontinued
          and make it inactive
        </div>
        <div class="margin-top-s">
          Warning/guidance that is editable in the Admin module: Submissions
          should be discontinued only if (1) submission was incorrectly setup
          (e.g., wrong submission type, wrong Jupiter opportunity number) OR (2)
          client declined the work while the contract was being created. If an
          opportunity is lost after sharing the contract with the client, the
          submission should be completed to reflect the work that has been done.
        </div>
      </div>
      <div class="margin-top-m">
        <div class="font-size-12 padding-bottom-xxs">
          {{ discontinueSelectOptions.label }}
        </div>
        <dds-select
          [(ngModel)]="discontinueReason"
          [description]="discontinueSelectOptions.description ?? ''"
          [errorMessage]="discontinueSelectOptions.errorMessage ?? ''"
          [disabled]="discontinueSelectOptions.disabled ?? false"
          [isError]="discontinueSelectOptions.isError ?? false"
          [errorState]="discontinueSelectOptions.errorState ?? ''"
          [isResponsive]="true"
          [isRequired]="discontinueSelectOptions.isRequired ?? false"
          [isInverse]="discontinueSelectOptions.isInverse ?? false"
          [list]="discontinueSelectedItems"
          [label]="''"
          [labelPosition]="discontinueSelectOptions.labelPosition ?? ''"
          [customClass]="discontinueSelectOptions.customClass ?? ''"
          [placeholder]="discontinueSelectOptions.placeholder ?? ''"
          [size]="discontinueSelectOptions.size ?? elementSize"
          [stickerWidth]="discontinueSelectOptions.stickerWidth ?? 0"
          [stickerShift]="discontinueSelectOptions.stickerShift ?? 0"
          [stickerMaxHeight]="discontinueSelectOptions.stickerMaxHeight ?? 0"
          [stickerIsDisabled]="
            discontinueSelectOptions.stickerIsDisabled ?? false
          "
          [stickerPosition]="discontinueSelectOptions.stickerPosition ?? ''"
          [stickerIndent]="discontinueSelectOptions.stickerIndent ?? 0"
          [stickerCustomClass]="
            discontinueSelectOptions.stickerCustomClass ?? ''
          "
          [stickerIsDynamic]="
            discontinueSelectOptions.stickerIsDynamic ?? false
          "
        >
        </dds-select>
      </div>
    </div>

    <div class="dds-modal__footer">
      <div class="dds-modal__footer-content dds-modal__footer-content_left">
        <dds-button
          class="dds-modal__footer-item"
          [theme]="discontinueBtnOption.theme ?? ''"
          [kind]="discontinueBtnOption.kind ?? ''"
          [size]="discontinueBtnOption.size ?? ''"
          [width]="discontinueBtnOption.width ?? ''"
          [icon]="discontinueBtnOption.icon ?? ''"
          [isIconLeft]="discontinueBtnOption.isIconLeft ?? false"
          [role]="discontinueBtnOption.role ?? ''"
          [isInverse]="discontinueBtnOption.isInverse ?? false"
          [isColorBg]="discontinueBtnOption.isColorBg ?? false"
          [isLoading]="discontinueBtnOption.isLoading ?? false"
          [ariaLabel]="discontinueBtnOption.ariaLabel ?? ''"
          [customClass]="discontinueBtnOption.customClass ?? ''"
          [disabled]="discontinueReason < 1"
          (clicked)="onClickDiscontinueBtnLabelBtn()"
          ><span>{{ discontinueBtnLabel }}</span></dds-button
        >
        <dds-button
          class="dds-modal__footer-item"
          [theme]="cancelBtnOption.theme ?? ''"
          [kind]="cancelBtnOption.kind ?? ''"
          [size]="cancelBtnOption.size ?? ''"
          [width]="cancelBtnOption.width ?? ''"
          [icon]="cancelBtnOption.icon ?? ''"
          [isIconLeft]="cancelBtnOption.isIconLeft ?? false"
          [role]="cancelBtnOption.role ?? ''"
          [isInverse]="cancelBtnOption.isInverse ?? false"
          [isColorBg]="cancelBtnOption.isColorBg ?? false"
          [isLoading]="cancelBtnOption.isLoading ?? false"
          [ariaLabel]="cancelBtnOption.ariaLabel ?? ''"
          [customClass]="cancelBtnOption.customClass ?? ''"
          [disabled]="false"
          (clicked)="close()"
          ><span>{{ cancelBtnLabel }}</span></dds-button
        >
      </div>
      <div
        class="dds-modal__footer-content dds-modal__footer-content_right"
      ></div>
    </div>
  </div>
</div>
