import { TagOptions } from '@usitsdasdesign/dds-ng/tags';
import { ExtThemes, Size, Themes, ButtonKind, WidthState, ErrorState, LabelPosition } from '@usitsdasdesign/dds-ng/shared';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { MultiSelectOptions, SelectControlTypes, SelectType } from "@usitsdasdesign/dds-ng/multi-select";
import { RadioButton } from '@usitsdasdesign/dds-ng/radio';
import { ButtonOptions } from "@usitsdasdesign/dds-ng/button";
import { AccordionOptions } from '@usitsdasdesign/dds-ng/accordion';
import { TextareaOptions } from '@usitsdasdesign/dds-ng/textarea';
import { SelectOptions } from '@usitsdasdesign/dds-ng/select';
import { NumberInputOptions } from '@usitsdasdesign/dds-ng/number-input';

export class MARSConstant {
  public static readonly Search_MultiOptions_Placeholder: string = 'Search by name';
  public static readonly attachmentLabel = 'Attach document(s)';
  public static readonly submitLabel = 'Submit to business leadership';
  public static readonly Text_Area_Options_Placeholder = 'Enter text';
  public static readonly Search_Placeholder = 'Search by name or ID';
  public static readonly SelectOptions_Placeholder = 'Select';
  public static readonly Textarea_Sample_Length = 40;
  public static readonly Textarea_Normal_Length = 100;
  public static readonly Textarea_Medium_Length = 1000;
  public static readonly Textarea_High_Length = 4000;
  public static readonly MARS_Dashboard_NcasDraftCount = 'marsNcasDraftCount';
  public static readonly MARS_Finanicial_SectionID = '8';
  public static readonly MARS_Conflict_SectionID = '9';
  public static readonly multiOptions: TagOptions = {
    theme: Themes.dark,
    isRemovable: true,
    disabled: true,
    isError: false,
    isInverse: false,
    size: Size.lg,
    customClass: ''
  };
  public static readonly descTooltipOptions: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'right-top',
    tooltipIndent: 1,
    tooltipHasBeak: true,
    tooltipType: 'regular',
    tooltipSize: 'md',
    tooltipTheme: Themes.dark
  };
  public static readonly searchMultiOptions: SearchOptions = {
    placeholder: MARSConstant.Search_MultiOptions_Placeholder,
    size: Size.lg,
    customClass: '',
    disabled: false,
  };

  public static readonly submitBtnOptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primaryLoud,
    size: Size.lg,
    width: WidthState.fixed,
    disabled: false,
    ariaLabel: MARSConstant.submitLabel,
    customClass: '',
    role: 'button'
  }
  public static readonly textareaPrincipalOption: TextareaOptions = {
    placeholder: MARSConstant.Text_Area_Options_Placeholder,
    minHeight: 100,
    maxLength: MARSConstant.Textarea_Medium_Length,
    maxLengthPosition: 'bottom-right',
    size: Size.lg
  };
  public static readonly jupiterOption: TextareaOptions = {
    placeholder: MARSConstant.Text_Area_Options_Placeholder,
    minHeight: 100,
    maxLength: MARSConstant.Textarea_Sample_Length,
    maxLengthPosition: 'bottom-right',
    size: Size.lg
  };
  public static readonly clientTooltipOptions: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'top',
    tooltipIndent: 1,
    tooltipHasBeak: true,
    tooltipType: 'regular',
    tooltipSize: 'md',
    tooltipTheme: Themes.dark
  };
  public static readonly textBusinessOption: TextareaOptions = {
    placeholder: '',
    minHeight: 100,
    maxLength: MARSConstant.Textarea_Normal_Length,
    maxLengthPosition: 'bottom-right',
    size: Size.lg
  };
  public static readonly options: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.silent,
    size: Size.md,
    width: WidthState.fixed,
    isLoading: false,
    icon: 'dds-icon_arrow-up',
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: false,
    ariaLabel: 'Show Less',
    customClass: '',
    role: 'button'
  }
  public static readonly SelectOptions: SelectOptions = {
    placeholder: MARSConstant.SelectOptions_Placeholder,
    size: Size.lg,
    disabled: false
  };

  public static readonly textareaOptions: TextareaOptions = {
    placeholder: MARSConstant.Text_Area_Options_Placeholder,
    minHeight: 100,
    maxLength: MARSConstant.Textarea_High_Length,
    size: Size.md,
    maxLengthPosition: 'bottom-right',
  };
  public static readonly multiSelectOptions: MultiSelectOptions = {
    label: '',
    size: Size.lg,
    description: '',
    placeholder: MARSConstant.SelectOptions_Placeholder,
    type: SelectType.counter,
    controlType: SelectControlTypes.checkbox,
    theme: 'green',
    isInverse: false,
    disabled: false,
    isResponsive: false,
    isError: false,
    isRequired: false,
    displayTickAllBtn: true,
    errorMessage: '',
    errorState: ErrorState.default,
    customClass: '',
    stickerWidth: 0,
    stickerShift: 0,
    stickerMaxHeight: "",
    stickerIsDisabled: false,
    stickerPosition: "bottom-left",
    stickerIndent: 0,
    stickerCustomClass: "",
    counterTypeText: '',
  };
  public static readonly searchOptions: SearchOptions = {
    placeholder: MARSConstant.Search_Placeholder,
    size: Size.lg,
    customClass: '',
  };
  public static readonly radioBtns: RadioButton[] = [
    {
      options: {
        label: 'Yes',
        theme: Themes.green,
      },
      value: 'True',
    },
    {
      options: {
        label: 'No',
        theme: Themes.green
      },
      value: 'False',
    }
  ];
  public static readonly accordionOptions: AccordionOptions = {
    size: Size.md,
    disabled: false,
    isOpen: true,
    isMulti: false,
    isInverse: false,
    customClass: '',
  };
  public static readonly optionsAttachments: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.md,
    width: WidthState.fixed,
    isLoading: false,
    icon: '',
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: false,
    ariaLabel: MARSConstant.attachmentLabel,
    customClass: '',
    role: 'button'
  }
  public static readonly numberInputOptions: NumberInputOptions = {
      label: 'Label',
      labelPosition: LabelPosition.external,
      placeholder: MARSConstant.Text_Area_Options_Placeholder,
      size: Size.lg,
      step: 1,
      thousandSeparator: ',',
      hasOnlyPositiveValues: true,
      decimalMarker: '.',
      isStrictMode: true,
    };
};
