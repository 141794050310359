import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitiateSubmissionComponent } from './initiate-submission/initiate-submission.component';
import { RouterModule } from '@angular/router';
import { INTAKE_ROUTES } from './intake.states';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LibModule } from '@usitsdasdesign/dds-ng';
import { OpportunityDetailsComponent } from './opportunity-details/opportunity-details.component';
import { ComplexityQuestionsComponent } from './opportunity-details/complexity-questions/complexity-questions.component';
import { EclipseCommonModule } from '../common/common.module';
import { OportunityDetailRightPanelComponent } from './opportunity-details/oportunity-detail-right-panel/oportunity-detail-right-panel.component';
import { ServiceDeliveryComponent } from './opportunity-details/oportunity-detail-right-panel/service-delivery/service-delivery.component';
import { LinkedOpportunitiesComponent } from './opportunity-details/linked-opportunities/linked-opportunities.component';
import { SubmissionCommonSectionComponent } from './opportunity-details/submission-common-section/submission-common-section.component';
import { DocumentComponent } from './opportunity-details/submission-common-section/document/document.component';
import { DocumentLinkUploadComponent } from './opportunity-details/submission-common-section/document/document-upload/document-link-upload/document-link-upload.component';
import { DocumentEditPopupComponent } from './opportunity-details/submission-common-section/document/document-upload/document-edit-popup/document-edit-popup.component';
import { DocumentTableComponent } from './opportunity-details/submission-common-section/document/document-upload/document-table/document-table.component';
import { OpportunityDetailsSectionComponent } from './opportunity-details/oppportunity-details-section/opportunity-details-section.component';
import { ComplexityCyberQuestionsComponent } from './opportunity-details/complexity-cyber-questions/complexity-cyber-questions.component';
import { SearchELSOWComponent } from './common/search-ELSOW/search-elsow/search-elsow.component';
import { IntakeClientComponent } from './opportunity-summary/opportunity-summary/intake-client/intake-client.component';
import { StatusStylePipe } from './status-style-pipe/status-style.pipe';
import { IntakeDocumentUploadComponents } from './opportunity-details/submission-common-section/document/document-upload/intake-document-upload/intake-document-upload.component';
import { ShowAllPopupComponent } from './opportunity-summary/show-all-popup/show-all-popup/show-all-popup.component';
import { OpportunitySummaryComponent } from './opportunity-summary/opportunity-summary/opportunity-summary.component';
import { TeamOpportunitySummaryComponent } from './opportunity-summary/team-opportunity-summary/team-opportunity-summary.component';
import { DocumentSelectionPopupComponent } from './opportunity-details/submission-common-section/document-selection-popup/document-selection-popup.component';
import { CloseOutProposalComponent } from './opportunity-details/submission-common-section/close-out-proposal/close-out-proposal.component';
import { CloseOutChangeOrderComponent } from './opportunity-details/submission-common-section/close-out-change-order/close-out-change-order.component';
import { CloseOutDeliverableComponent } from './opportunity-details/submission-common-section/close-out-deliverable/close-out-deliverable.component';
import { CloseOutOtherComponent } from './opportunity-details/submission-common-section/close-out-other/close-out-other.component';
import { ConfirmPopModalComponent } from './opportunity-details/submission-common-section/confirm-pop-modal/confirm-pop-modal.component';
import { DocumentSubmissionComponent } from './opportunity-summary/opportunity-summary/document-submission/document-submission.component';
import { OptOutPopupComponent } from './intake-common-popups/opt-out-popup/opt-out-popup.component';
import { AdministrativelyClosePopupComponent } from './opportunity-details/submission-common-section/administratively-close-popup/administratively-close-popup/administratively-close-popup.component';
import { DiscontinueModalComponent } from './opportunity-details/submission-common-section/discontinue-modal/discontinue-modal.component';
import { ResetStatusPopupComponent } from './opportunity-details/submission-common-section/reset-status-popup/reset-status-popup.component';
import { SummaryArchivePopupComponent } from './opportunity-summary/opportunity-summary/summary-archive-popup/summary-archive-popup.component';
import { ManageTeamMemberPopupComponent } from './opportunity-summary/team-opportunity-summary/manage-team-member-popup/manage-team-member-popup.component';
import { WbsChargeCodeComponent } from './opportunity-details/submission-common-section/wbs-charge-code/wbs-charge-code.component';

@NgModule({
  declarations: [
    InitiateSubmissionComponent,
    OpportunityDetailsComponent,
    OportunityDetailRightPanelComponent,
    ServiceDeliveryComponent,
    LinkedOpportunitiesComponent,
    SubmissionCommonSectionComponent,
    DocumentComponent,
    DocumentLinkUploadComponent,
    DocumentEditPopupComponent,
    DocumentTableComponent,
    ComplexityQuestionsComponent,
    ComplexityCyberQuestionsComponent,
    OpportunityDetailsSectionComponent,
    SearchELSOWComponent,
    IntakeClientComponent,
    StatusStylePipe,
    IntakeDocumentUploadComponents,
    ShowAllPopupComponent,
    OpportunitySummaryComponent,
    TeamOpportunitySummaryComponent,
    DocumentSelectionPopupComponent,
    CloseOutProposalComponent,
    CloseOutChangeOrderComponent,
    CloseOutDeliverableComponent,
    CloseOutOtherComponent,
    ConfirmPopModalComponent,
    DocumentSelectionPopupComponent,
    DocumentSubmissionComponent,
    OptOutPopupComponent,
    AdministrativelyClosePopupComponent,
    DiscontinueModalComponent,
    ResetStatusPopupComponent,
    SummaryArchivePopupComponent,
    ManageTeamMemberPopupComponent,
    WbsChargeCodeComponent
  ],

  imports: [
    CommonModule,
    LibModule,
    FormsModule,
    EclipseCommonModule,
    RouterModule.forChild(INTAKE_ROUTES),
    ReactiveFormsModule,
  ]
})
export class IntakeModule {}
