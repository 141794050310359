import { SelectOptions } from '@usitsdasdesign/dds-ng/select';
import {
  LabelPosition,
  ErrorState,
  ButtonKind,
  ExtThemes,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { IntakeConstant } from '../../../constants/intake.constant';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';


export const selectedReasonOptions: SelectOptions = {
  label: 'Reason',
  labelPosition: LabelPosition.internal,
  description: '',
  placeholder: 'Select Reason',
  size: IntakeConstant.UI_ELEMENT_SIZE,
  disabled: false,
  isResponsive: false,
  isRequired: false,
  isInverse: false,
  isError: false,
  errorMessage: '',
  errorState: ErrorState.default,
  customClass: '',
  stickerWidth: 0,
  stickerShift: 0,
  stickerMaxHeight: '',
  stickerIsDisabled: false,
  stickerPosition: 'bottom-left',
  stickerIndent: 0,
  stickerCustomClass: '',
  stickerIsDynamic: true,
};

 const MODAL_COMMON_BTN_OPTIONS: ButtonOptions = {
  theme: ExtThemes.green,
  size: IntakeConstant.UI_ELEMENT_SIZE,
  width: WidthState.fixed,
  isLoading: false,
  isIconLeft: false,
  isColorBg: false,
  isInverse: false,
  disabled: true,
  customClass: '',
  role: 'button',
};
export const DISCONTINUE_BTN_LABEL: string = 'Confirm';
export const CANCEL_BTN_LABEL: string = 'Cancel';

export const DISCONTINUE_BTN_OPTIONS = {
  ...MODAL_COMMON_BTN_OPTIONS,
  icon: '',
  customClass: '',
  kind: ButtonKind.primaryLoud,
  ariaLabel: DISCONTINUE_BTN_LABEL,
  size: IntakeConstant.UI_ELEMENT_SIZE,
};

export const CANCEL_BTN_OPTIONS = {
  ...MODAL_COMMON_BTN_OPTIONS,
  icon: '',
  kind: ButtonKind.secondaryLoud,
  customClass: '',
  ariaLabel: CANCEL_BTN_LABEL,
};
