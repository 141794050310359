<div [autosave-module]="saveComplexityModule">
  <form [formGroup]="complexityForm" class="complexity-question-container">
    <!-- Question 1: Opportunity Characteristics -->
    <div class="margin-top-s">
      <span
        *ngIf="isOpportunityComplexityRequired()"
        class="icon-required-yellow"
      ></span>
      <span class="text-label">
        1. Are any of the following characteristics present in the opportunity?
        Select all that apply:
      </span>
      <div class="padding-top-xs value-box">
        <dds-multi-select
          class="box-size"
          [disabled]="multiSelectOptions.disabled ?? false"
          [isResponsive]="multiSelectOptions.isResponsive ?? false"
          [isRequired]="multiSelectOptions.isRequired ?? false"
          [label]="multiSelectOptions.label ?? ''"
          [size]="htmlElementSize"
          [placeholder]="multiSelectOptions.placeholder ?? ''"
          [type]="multiSelectOptions.type ?? ''"
          [controlType]="multiSelectOptions.controlType ?? ''"
          [theme]="multiSelectOptions.theme ?? ''"
          [isInverse]="multiSelectOptions.isInverse ?? false"
          [list]="opportunityComplexityOptions"
          formControlName="opportunityComplexity"
          (valueChanged)="valueChanged($event, 'opportunityComplexity')"
        >
        </dds-multi-select>
        <div>
          <span
            class="comments-text-label eclipse-small-header-grey"
            *ngIf="
              complexityForm.get('opportunityComplexity')?.value.length > 0
            "
            >Selected values:</span
          >
          <dds-tags>
            <dds-tag
              class="tag-width"
              *ngFor="
                let item of complexityForm.get('opportunityComplexity')?.value
              "
              [theme]="options.theme ?? ''"
              [isRemovable]="options.isRemovable ?? false"
              [disabled]="options.disabled ?? false"
              [customClass]="options.customClass ?? ''"
              [size]="htmlElementSize"
              [isError]="options.isError ?? false"
              [isInverse]="options.isInverse ?? false"
              (removed)="removeOpportunityComplexity(item)"
              #tagItems
            >
              {{ item.label }}
            </dds-tag>
          </dds-tags>
        </div>
      </div>
    </div>

    <!-- Conditional Section: Regulatory Considerations -->
    <div *ngIf="hasRegulatoryConditions">
      <div class="row padding-top-xs">
        <div class="col-10 margin-top-s">
          <span class="comments-text-label eclipse-small-header-grey"
            >1.1 Regulatory considerations:</span
          >
          <span
            class="icon-s icon-info"
            [ddsTooltip]="
              'Identify regulatory considerations such as HIPAA, GDPR, ADA, Export Controls'
            "
            [tooltipInvokeType]="groupToolTipOptions.tooltipInvokeType ?? ''"
            [tooltipType]="groupToolTipOptions.tooltipType ?? ''"
            [tooltipSize]="groupToolTipOptions.tooltipSize ?? ''"
            [tooltipPosition]="groupToolTipOptions.tooltipPosition ?? ''"
            [tooltipIndent]="groupToolTipOptions.tooltipIndent ?? 0"
            [tooltipHasBeak]="groupToolTipOptions.tooltipHasBeak ?? false"
            [tooltipTheme]="groupToolTipOptions.tooltipTheme ?? ''"
            ddsFocus
          >
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="textarea-container">
            <dds-input
              formControlName="regulatoryConsiderations"
              [placeholder]="textareaOptions.placeholder ?? ''"
              [maxLength]="textareaOptions.maxLength ?? 0"
              [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''"
              [size]="htmlElementSize"
            >
            </dds-input>
          </div>
        </div>
      </div>
    </div>

    <!-- Question 2: Opportunity Involvement -->
    <div class="margin-top-m">
      <span
        *ngIf="isFieldRequired(complexityForm.get('productAndTools'))"
        class="icon-required-yellow"
      ></span>
      <span class="text-label"
        >2. Does the opportunity involve any of the following?</span
      >
      <div class="sub-text-section">
        <span class="icon-s icon-pinned"></span>
        <span class="text-label eclipse-content-grey"
          >Does not apply to Deloitte accelerators used only to develop
          deliverables</span
        >
      </div>

      <div class="padding-top-xs value-box">
        <dds-multi-select
          class="box-size"
          [disabled]="multiSelectOptions.disabled ?? false"
          [isResponsive]="multiSelectOptions.isResponsive ?? false"
          [label]="multiSelectOptions.label ?? ''"
          [size]="htmlElementSize"
          [placeholder]="multiSelectOptions.placeholder ?? ''"
          [type]="multiSelectOptions.type ?? ''"
          [controlType]="multiSelectOptions.controlType ?? ''"
          [isInverse]="multiSelectOptions.isInverse ?? false"
          [list]="productAndToolsOptions"
          formControlName="productAndTools"
          (valueChanged)="valueChanged($event, 'productAndTools')"
        >
        </dds-multi-select>
        <div>
          <span
            class="comments-text-label eclipse-small-header-grey"
            *ngIf="complexityForm.get('productAndTools')?.value.length > 0"
            >Selected values:</span
          >
          <dds-tags>
            <dds-tag
              class="tag-width"
              *ngFor="let item of complexityForm.get('productAndTools')?.value"
              [theme]="options.theme ?? ''"
              [isRemovable]="options.isRemovable ?? false"
              [disabled]="options.disabled ?? false"
              [customClass]="options.customClass ?? ''"
              [size]="options.size ?? ''"
              [isError]="options.isError ?? false"
              [isInverse]="options.isInverse ?? false"
              (removed)="removeProductAndTools(item)"
              #tagItems
            >
              {{ item.label }}
            </dds-tag>
          </dds-tags>
        </div>
      </div>
    </div>

    <!-- Question 3: Member Firm Participation -->
    <div class="margin-top-m">
      <span *ngIf="isMemberFirmInvolvementRequired()" class="icon-required-yellow"></span>
      <span class="text-label">
        3. Does the opportunity involve participation from another member firm?
      </span>
      <div *ngIf="complexityForm.get('memberFirmInvolvement')?.value?.includes('Yes')" class="sub-text-section" class="">
        <span class="icon-s icon-pinned margin-right-xs margin-top-xxs"></span>
        <span class="text-label eclipse-content-grey" [innerHTML]="updatedIfaNote"></span>
      </div>
    
      <div class="padding-top-xs value-box">
        <dds-radio-group [name]="radioGroupOptions.name ?? ''" [disabled]="radioGroupOptions.disabled ?? false"
          [isError]="radioGroupOptions.isError ?? false" [isUncheckable]="radioGroupOptions.isUncheckable ?? false"
          [isInverse]="radioGroupOptions.isInverse ?? false" [isRequired]="radioGroupOptions.isRequired ?? false"
          [errorState]="radioGroupOptions.errorState ?? ''" formControlName="memberFirmInvolvement">
          <ng-container *ngFor="let radio of radioBtns">
            <dds-radio [value]="radio.value" [label]="radio.options.label ?? ''"
              [disabled]="radio.options.disabled ?? false" [customClass]="radio.options.customClass ?? ''"
              [theme]="radio.options.theme ?? ''">
            </dds-radio>
            <br />
          </ng-container>
        </dds-radio-group>
      </div>
    </div>
    
    <!-- 3.1 Conditional Section: Member Firm Names -->
    <div *ngIf="complexityForm.get('memberFirmInvolvement')?.value === 'Yes'">
      <div class="row">
        <div class="col-10 margin-top-s">
          <span class="comments-text-label eclipse-small-header-grey">3.1 Enter the names of the member firm(s)
            involved:</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="textarea-container">
            <dds-input autosave formControlName="memberFirmNames" [placeholder]="'Enter member firm name(s)'"
              [maxLength]="maxSizeMemberFirms" [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''"
              [size]="htmlElementSize">
            </dds-input>
          </div>
        </div>
      </div>
    </div>

    <!-- Question 4: Third-Party Involvement -->
    <div class="margin-top-m">
      <span
        *ngIf="isFieldRequired(complexityForm.get('thirdPartyInvolvement'))"
        class="icon-required-yellow"
      ></span>
      <span *ngIf="!shouldIncludeNotYetDetermined()" class="text-label">
        4. Does the opportunity involve external third-party service providers
        via subcontracting or prime contracting?
      </span>
      <span *ngIf="shouldIncludeNotYetDetermined()" class="text-label">
        4. Does the opportunity involve external third-party service providers
        via subcontracting, prime contracting, or teaming relationships?
      </span>
      
      <div *ngIf="complexityForm.get('thirdPartyInvolvement')?.value?.includes('Yes')" class="sub-text-section">
        <span class="icon-s icon-pinned"></span>
        <span class="text-label eclipse-content-grey">
          <span *ngFor="let item of tprmNoteWords">
            <span *ngIf="item.includes(tprmText)">
              <a class="text-green-2" href="https://ssodeloitteprod.aravo.com/" target="_blank"
                title="https://ssodeloitteprod.aravo.com/"> {{item}}</a>
            </span>
            <span *ngIf="item !== tprmText"> {{item}}</span>
          </span>
        </span>
      </div>
      <div class="padding-top-xs value-box">
        <div role="radiogroup">
          <label class="dds-custom-control dds-custom-control_green">
            <input
              type="radio"
              name="thirdPartyInvolvement"
              formControlName="thirdPartyInvolvement"
              class="dds-custom-control__field dds-custom-control__field_radio"
              value="Yes, external third-party subcontractors (Deloitte is prime)"
            />
            <span
              class="dds-custom-control__icon dds-custom-control__icon_radio"
            ></span>
            <span class="dds-custom-control__text">
              Yes, external third-party
              <span
                [ddsTooltip]="
                  'Subcontractors are external companies or individuals who are contracted by Deloitte to deliver services on one or more Deloitte client engagement/opportunities.'
                "
                [tooltipInvokeType]="
                  groupToolTipOptions.tooltipInvokeType ?? ''
                "
                [tooltipType]="groupToolTipOptions.tooltipType ?? ''"
                [tooltipSize]="groupToolTipOptions.tooltipSize ?? ''"
                [tooltipPosition]="groupToolTipOptions.tooltipPosition ?? ''"
                [tooltipIndent]="groupToolTipOptions.tooltipIndent ?? 0"
                [tooltipHasBeak]="groupToolTipOptions.tooltipHasBeak ?? false"
                [tooltipTheme]="groupToolTipOptions.tooltipTheme ?? ''"
                ddsFocus
                class="text-underline fw-bold"
                >subcontractors</span
              >
              (Deloitte is prime)
            </span>
          </label>
          <br />
          <label class="dds-custom-control dds-custom-control_green">
            <input
              type="radio"
              name="thirdPartyInvolvement"
              class="dds-custom-control__field dds-custom-control__field_radio"
              formControlName="thirdPartyInvolvement"
              value="Yes, prime contractor (Deloitte is a subcontractor)"
            />
            <span
              class="dds-custom-control__icon dds-custom-control__icon_radio"
            ></span>
            <span class="dds-custom-control__text">
              Yes,
              <span
                [ddsTooltip]="
                  'Deloitte will be a subcontractor to a third-party prime contractor on a client engagement/opportunity.'
                "
                [tooltipInvokeType]="
                  groupToolTipOptions.tooltipInvokeType ?? ''
                "
                [tooltipType]="groupToolTipOptions.tooltipType ?? ''"
                [tooltipSize]="groupToolTipOptions.tooltipSize ?? ''"
                [tooltipPosition]="groupToolTipOptions.tooltipPosition ?? ''"
                [tooltipIndent]="groupToolTipOptions.tooltipIndent ?? 0"
                [tooltipHasBeak]="groupToolTipOptions.tooltipHasBeak ?? false"
                [tooltipTheme]="groupToolTipOptions.tooltipTheme ?? ''"
                ddsFocus
                class="text-underline fw-bold"
                >prime contractor</span
              >
              (Deloitte is a subcontractor)
            </span>
          </label>
          <br />
          <div *ngIf="shouldIncludeNotYetDetermined()">
            <label class="dds-custom-control dds-custom-control_green">
              <input
                type="radio"
                name="thirdPartyInvolvement"
                class="dds-custom-control__field dds-custom-control__field_radio"
                formControlName="thirdPartyInvolvement"
                value="Yes, teaming"
              />
              <span
                class="dds-custom-control__icon dds-custom-control__icon_radio"
              ></span>
              <span class="dds-custom-control__text">
                Yes,
                <span
                  [ddsTooltip]="
                    'Teaming relationship exists if Deloitte and a third party have agreed to prepare a joint response to a request for Proposal from a prospective client.'
                  "
                  [tooltipInvokeType]="
                    groupToolTipOptions.tooltipInvokeType ?? ''
                  "
                  [tooltipType]="groupToolTipOptions.tooltipType ?? ''"
                  [tooltipSize]="groupToolTipOptions.tooltipSize ?? ''"
                  [tooltipPosition]="groupToolTipOptions.tooltipPosition ?? ''"
                  [tooltipIndent]="groupToolTipOptions.tooltipIndent ?? 0"
                  [tooltipHasBeak]="groupToolTipOptions.tooltipHasBeak ?? false"
                  [tooltipTheme]="groupToolTipOptions.tooltipTheme ?? ''"
                  ddsFocus
                >
                  teaming
                </span>
              </span>
            </label>
            <br />
          </div>
          <label class="dds-custom-control dds-custom-control_green">
            <input
              type="radio"
              name="thirdPartyInvolvement"
              class="dds-custom-control__field dds-custom-control__field_radio"
              formControlName="thirdPartyInvolvement"
              value="None"
            />
            <span
              class="dds-custom-control__icon dds-custom-control__icon_radio"
            ></span>
            <span class="dds-custom-control__text"> None </span>
          </label>
          <br />
          <label
            class="dds-custom-control dds-custom-control_green"
            *ngIf="shouldIncludeNotYetDetermined()"
          >
            <input
              type="radio"
              name="thirdPartyInvolvement"
              class="dds-custom-control__field dds-custom-control__field_radio"
              formControlName="thirdPartyInvolvement"
              value="Not yet determined"
            />
            <span
              class="dds-custom-control__icon dds-custom-control__icon_radio"
            ></span>
            <span class="dds-custom-control__text"> Not yet determined </span>
          </label>
        </div>
      </div>
    </div>

    

    <!-- Question 5: Sensitive Data Categories -->
    <div class="margin-top-m">
      <span
        *ngIf="isFieldRequired(complexityForm.get('sensitiveData'))"
        class="icon-required-yellow"
      ></span>
      <span class="text-label">
        5. Will the team have access to any of the following categories of data?
      </span>

      <div class="padding-top-xs value-box">
        <dds-multi-select
          class="box-size"
          [disabled]="multiSelectOptions.disabled ?? false"
          [isResponsive]="multiSelectOptions.isResponsive ?? false"
          [isRequired]="multiSelectOptions.isRequired ?? false"
          [label]="multiSelectOptions.label ?? ''"
          [size]="htmlElementSize"
          [placeholder]="multiSelectOptions.placeholder ?? ''"
          [type]="multiSelectOptions.type ?? ''"
          [controlType]="multiSelectOptions.controlType ?? ''"
          [theme]="multiSelectOptions.theme ?? ''"
          [isInverse]="multiSelectOptions.isInverse ?? false"
          [list]="sensitiveDataOptions"
          formControlName="sensitiveData"
          (valueChanged)="valueChanged($event, 'sensitiveData')"
        >
        </dds-multi-select>
        <div>
          <span
            class="comments-text-label eclipse-small-header-grey"
            *ngIf="complexityForm.get('sensitiveData')?.value.length > 0"
            >Selected values:</span
          >
          <dds-tags>
            <dds-tag
              class="tag-width"
              *ngFor="let item of complexityForm.get('sensitiveData')?.value"
              [theme]="options.theme ?? ''"
              [isRemovable]="options.isRemovable ?? false"
              [disabled]="options.disabled ?? false"
              [customClass]="options.customClass ?? ''"
              [size]="htmlElementSize"
              [isError]="options.isError ?? false"
              [isInverse]="options.isInverse ?? false"
              (removed)="removeSensitiveData(item)"
              #tagItems
            >
              {{ item.label }}
            </dds-tag>
          </dds-tags>
        </div>
      </div>
    </div>

    <!-- Question 6: Data Storage Location -->
    <div class="margin-top-m">
      <span
        *ngIf="
          isFieldRequired(
            complexityForm.get('dataInDeloittesOrDeloittesHostedEnvironment')
          )
        "
        class="icon-required-yellow"
      ></span>
      <span class="text-label">
        6. Will client data be stored in Deloitte's environment or Deloitte
        hosted environment?
      </span>

      <div class="padding-top-xs value-box">
        <dds-radio-group
          [disabled]="radioGroupOptions.disabled ?? false"
          [isError]="radioGroupOptions.isError ?? false"
          [isUncheckable]="radioGroupOptions.isUncheckable ?? false"
          [isInverse]="radioGroupOptions.isInverse ?? false"
          [isRequired]="radioGroupOptions.isRequired ?? false"
          [errorState]="radioGroupOptions.errorState ?? ''"
          formControlName="dataInDeloittesOrDeloittesHostedEnvironment"
        >
          <ng-container *ngFor="let radio of radioBtns">
            <dds-radio
              [value]="radio.value"
              [label]="radio.options.label ?? ''"
              [disabled]="radio.options.disabled ?? false"
              [customClass]="radio.options.customClass ?? ''"
              [theme]="radio.options.theme ?? ''"
            >
            </dds-radio>
            <br />
          </ng-container>
        </dds-radio-group>
      </div>
    </div>

    <!-- Question 7: SOW/EL Template Usage -->
    <div class="margin-top-m">
      <span
        *ngIf="
          isFieldRequired(
            complexityForm.get('deloitteStandardELSOWTemplateReferred')
          )
        "
        class="icon-required-yellow"
      ></span>
      <span class="text-label">
        7. Was the content of Deloitte's standard SOW/EL template leveraged?
      </span>
      <span
        class="icon-s icon-info position-alignment"
        [ddsTooltip]="
          'Has the team leveraged the content of the latest version of the standard SOW/EL template from Contract Maker?'
        "
        [tooltipInvokeType]="groupToolTipOptions.tooltipInvokeType ?? ''"
        [tooltipType]="groupToolTipOptions.tooltipType ?? ''"
        [tooltipSize]="groupToolTipOptions.tooltipSize ?? ''"
        [tooltipPosition]="groupToolTipOptions.tooltipPosition ?? ''"
        [tooltipIndent]="groupToolTipOptions.tooltipIndent ?? 0"
        [tooltipHasBeak]="groupToolTipOptions.tooltipHasBeak ?? false"
        [tooltipTheme]="groupToolTipOptions.tooltipTheme ?? ''"
        ddsFocus
      >
      </span>

      <div class="padding-top-xs value-box">
        <dds-radio-group
          [name]="deloitteStandardELSOWTemplateReferredGroupOptions.name ?? ''"
          [disabled]="radioGroupOptions.disabled ?? false"
          [isError]="radioGroupOptions.isError ?? false"
          [isUncheckable]="radioGroupOptions.isUncheckable ?? false"
          [isInverse]="radioGroupOptions.isInverse ?? false"
          [isRequired]="radioGroupOptions.isRequired ?? false"
          [errorState]="radioGroupOptions.errorState ?? ''"
          formControlName="deloitteStandardELSOWTemplateReferred"
        >
          <ng-container *ngFor="let radio of radioBtns">
            <dds-radio
              [value]="radio.value"
              [label]="radio.options.label ?? ''"
              [disabled]="radio.options.disabled ?? false"
              [customClass]="radio.options.customClass ?? ''"
              [theme]="radio.options.theme ?? ''"
            >
            </dds-radio>
            <br />
          </ng-container>
        </dds-radio-group>
      </div>
    </div>

    <!-- Question 8: MSA or GBTs -->
    <div class="margin-top-m">
      <span
        *ngIf="isUseOfExistingMSAsOrContractMakerGBTsRequired()"
        class="icon-required-yellow"
      ></span>
      <span class="text-label">
        8. Will the Services be provided under an executed MSA or the latest
        GBTs from Contract Maker?
      </span>

      <div class="padding-top-xs value-box">
        <dds-radio-group
          [name]="useOfExistingMSAsOrContractMakerGBTsGroupOptions.name ?? ''"
          [disabled]="radioGroupOptions.disabled ?? false"
          [isError]="radioGroupOptions.isError ?? false"
          [isUncheckable]="radioGroupOptions.isUncheckable ?? false"
          [isInverse]="radioGroupOptions.isInverse ?? false"
          [isRequired]="radioGroupOptions.isRequired ?? false"
          [errorState]="radioGroupOptions.errorState ?? ''"
          formControlName="useOfExistingMSAsOrContractMakerGBTs"
        >
          <ng-container *ngFor="let radio of radioBtns">
            <dds-radio
              [value]="radio.value"
              [label]="radio.options.label ?? ''"
              [disabled]="radio.options.disabled ?? false"
              [customClass]="radio.options.customClass ?? ''"
              [theme]="radio.options.theme ?? ''"
            >
            </dds-radio>
            <br />
          </ng-container>
        </dds-radio-group>
      </div>
    </div>
    <!-- Conditional Section: General Business Terms (GBTs) -->
    <div
      *ngIf="
        complexityForm.get('useOfExistingMSAsOrContractMakerGBTs')?.value ===
        'No'
      "
    >
      <div class="row">
        <div class="col-10 margin-top-s">
          <span class="comments-text-label eclipse-small-header-grey">
            8.1 General business terms (GBTs) (e.g., starting point):
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="value-box">
            <dds-select
              class="box-size"
              [disabled]="selectOptions.disabled ?? false"
              [isResponsive]="selectOptions.isResponsive ?? false"
              [isRequired]="selectOptions.isRequired ?? false"
              [isInverse]="selectOptions.isInverse ?? false"
              [list]="gbtOptions"
              [labelPosition]="selectOptions.labelPosition ?? ''"
              [customClass]="selectOptions.customClass ?? ''"
              [placeholder]="selectOptions.placeholder ?? ''"
              [size]="htmlElementSize"
              [stickerWidth]="selectOptions.stickerWidth ?? ''"
              [stickerShift]="selectOptions.stickerShift ?? 0"
              [stickerMaxHeight]="selectOptions.stickerMaxHeight ?? ''"
              [stickerIsDisabled]="selectOptions.stickerIsDisabled ?? false"
              [stickerPosition]="selectOptions.stickerPosition ?? ''"
              [stickerIndent]="selectOptions.stickerIndent ?? 0"
              [stickerIsDynamic]="selectOptions.stickerIsDynamic ?? false"
              formControlName="generalBusinessTerms"
            >
            </dds-select>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
