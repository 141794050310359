import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrl: './confirm-popup.component.less'
})
export class ConfirmPopupComponent {
@Input() resolve: any;
@Output() isConfirmed: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private modalService: ModalService
  ) {}

  public ngOnInit() { }

  public confirm() {
    this.isConfirmed.emit(true);
  }
  public cancel() {
    //This will disable the scroll when the pop-up is open
    document.body.classList.remove('no-scroll');
    this.isConfirmed.emit(false);
    this.modalService.close();
  }
}
