import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DashboardDataCollection, DashboardGridLevelFilter } from '../../common/models/start-page/start-page.model';
import { RecentlyViewedSubmissionList, RiskManagerDashboardData, RiskManagerDashboardTilesCount } from '../../common/models/start-page/riskmanager-dashboard.model';
import { Observable, of } from 'rxjs';
import { UrlConstant } from '../../intake/constants/url.constant';
import { environment } from '../../../environment/environment';

@Injectable({
  providedIn: 'root'
})
export class RiskmanagerDashboardWebapiService {

  constructor(private readonly httpService: HttpClient) { }

  apiUrl: string = environment.apiUrl;
  // apiUrl: string = "https://dapi.eclipse.deloitte.com/api/";
  // apiUrl: string = 'https://qapi.eclipse.deloitte.com/api/';//qa

  getRiskManagerDashBoardGridData(
      tileCode: string,
      pageNumber: number,
      pageSize: number,
      sortParameterCode: number,
      isAscending: boolean,
      filterData: DashboardGridLevelFilter[]
    ): Observable<DashboardDataCollection<RiskManagerDashboardData>> {
      const params = {
        tileCode: tileCode,
        pageNumber: pageNumber,
        pageSize: pageSize,
        sortParameterCode: sortParameterCode,
        isAscending: isAscending,
      };
      return this.httpService.post<
        DashboardDataCollection<RiskManagerDashboardData>
      >(this.apiUrl + UrlConstant.POST_RISKMANAGER_GRID_DATA, filterData, {
        params,
      });
    }
  
    getRiskManagerDashboardTilesCount(): Observable<RiskManagerDashboardTilesCount> {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
      return this.httpService.get<RiskManagerDashboardTilesCount>(
        this.apiUrl + UrlConstant.GET_RISKMANAGER_TILE_COUNT,
        { headers }
      );
    }
  
    getRecentlyViewedSubmissions(
      pageNumber: number,
      pageSize: number
    ): Observable<RecentlyViewedSubmissionList> {
      return this.httpService.get<RecentlyViewedSubmissionList>(
        this.apiUrl +
          UrlConstant.GET_RECENTLY_VIEWED_SUBMISSIONS +
          '?pageNumber=' +
          pageNumber +
          '&pageSize=' +
          pageSize
      );
    }
}
