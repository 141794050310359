import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SecurityWebapiService } from '../http/security/security-webapi.service';
import {
  DropdownItemOptions,
  DropdownOptions,
} from '@usitsdasdesign/dds-ng/dropdown';
import { DashboardConstant, dropdownOptions } from './dashboard.helper';
import { PositionState } from '@usitsdasdesign/dds-ng/shared';
import { DashboardRoles } from '../common/models/start-page/start-page.model';
@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrl: './start-page.component.less',
})
export class StartPageComponent implements OnInit {
  selectedRole: string = '';
  roles: string[] = [];
  dropdownOptions: DropdownOptions = dropdownOptions;
  dropdownItems: DropdownItemOptions[] = [
    {
      heading: 'Contract/Proposal/Eng. Related Review',
      value: 'Contract/Proposal/Eng. Related Review',
    },
  ];
  dropdownStickerPosition: PositionState = PositionState.bottomLeft;

  public clientNumber: string = '';
  private securityNcaDashboard: string = 'ClientStatus.NcaDashboard';
  private isDashboardSecurity: boolean = false;
  public Nca_Specialist: string = DashboardConstant.NCA_SPECIALIST;
  public Reviewer: string = DashboardConstant.REVIEWER;
  public Engagement_Team: string = DashboardConstant.ENGAGEMENT_TEAM;
  public Risk_Manager: string = DashboardConstant.RISK_MANAGER;

  constructor(
    private route: ActivatedRoute,
    private securityWebapiService: SecurityWebapiService,
    private readonly router: Router
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.securityWebapiService.getPermissions('');
    });

    this.securityWebapiService.data.subscribe((rules) => {
      if (!rules.empty) {
        this.isDashboardSecurity =
          rules[this.securityNcaDashboard] &&
          rules[this.securityNcaDashboard].Visible;
        if (this.isDashboardSecurity == true)
          this.roles = [
            DashboardRoles.NCASpecialist,
            DashboardRoles.Reviewer,
            DashboardRoles.EngagementTeam,
          ];
        // TODO: Need to get the roles from the backend, for now hardcoded.
        else {
          this.roles = [
            DashboardRoles.Reviewer,
            DashboardRoles.EngagementTeam,
            DashboardRoles.RiskManager,
          ]; // If you don't have permission to view the NCA dashboard, you can only see the reviewer dashboard.
        }
        this.selectedRole = this.roles[0];
      }
    });
  }
  onRoleChange(event: any): void {}

  getRoleWidth(): string {
    return this.selectedRole === this.Reviewer ||
      this.selectedRole === this.Engagement_Team
      ? this.selectedRole.length + 3 + 'ch'
      : this.selectedRole.length + 'ch';
  }

  initiateNewItemSelected(event: string): void {
    if (event === 'Contract/Proposal/Eng. Related Review') {
      this.router.navigate(['/intake/submission']);
    }
  }
}
