export interface Column {
    name: keyof DocumentTableData;
    header: string;
    dataType: string;
    minWidth: string;
  }
  
  export interface DocumentTableData {
    submissionDocumentId: number;
    submissionId: number;
    documentTitle: string;
    documentType: string;
    documentTypeId: string;
    documentStatus: string;
    submissionDocumentTypeId: number;
    documentStatusId: string;
    attachmentId: number | null;
    attachmentGuid: string | null;
    createdDate: string;
    createdBy: string;
    documentUrl: string | null;
    comments: string;
    folderFilePath: string | null;
    isArchiveReady: boolean | null;
    archivalStatus: string | null;
    selected: boolean;
  }
  
  export const sampleColumns: Column[] = [
    {
      name: 'documentTitle',
      header: 'Title',
      dataType: 'string',
      minWidth: '5rem',
    },
    {
      name: 'documentType',
      header: 'Type',
      dataType: 'string',
      minWidth: '5rem',
    },
    {
      name: 'documentStatus',
      header: 'Status',
      dataType: 'string',
      minWidth: '5rem',
    },
    {
      name: 'createdDate',
      header: 'Uploaded Date',
      dataType: 'string',
      minWidth: '5rem',
    },
  ];