import { Component } from '@angular/core';

@Component({
  selector: 'app-third-party-repository-add',
  templateUrl: './third-party-repository-add.component.html',
  styleUrl: './third-party-repository-add.component.less'
})
export class ThirdPartyRepositoryAddComponent {

}
