import { InitiateReviewComponent } from "./initiate-review/initiate-review.component";
import { InitiationMarsDetailsComponent } from "./mars-details/initiation-mars-details.component";

export const INITIATION_ROUTES = [
    {
        path: '',
        children: [
            {
                path: '',
                component: InitiateReviewComponent
            },
            {
              path: 'initiate/mars-nca',
              component: InitiationMarsDetailsComponent
            }
        ]
  }
]
