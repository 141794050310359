import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environment/environment';
import { ClientMilestoneMarsNcaForm, ClientMilestoneMarsOwnershipInterest } from '../../common/models/mars-nca-request-model';
import { InitiationUrlConstants } from '../../initiation/constants/initiation-url-constants';
import { URLConstant } from '../../client/constants/url-constant';

@Injectable({
  providedIn: 'root'
})
export class MarsNcaService {

  constructor(private httpService: HttpClient) { }
  apiUrl: string = environment.apiUrl;
  getMarsNCAForm(clientMilestoneID: number): Observable<ClientMilestoneMarsNcaForm> {
    return this.httpService.get<ClientMilestoneMarsNcaForm>(this.apiUrl + URLConstant.GET_MARS_NCA_FORM_DATA + clientMilestoneID);
  }

  updateMarsNCAForm(model: ClientMilestoneMarsNcaForm): Observable<any> {
    return this.httpService.put<any>(this.apiUrl + URLConstant.UPDATE_MARS_NCA_FORM_DATA, model, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) });
  }
  AddOwnershipInterest(clientMilestoneMarsNcaFormID: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.httpService.post<any>(this.apiUrl + URLConstant.ADD_OWNERSHIP_INTEREST + clientMilestoneMarsNcaFormID, { headers });
  }
  UpdateOwnershipInterest(model: Array<ClientMilestoneMarsOwnershipInterest>): Observable<any> {
    return this.httpService.put<any>(this.apiUrl + URLConstant.UPDATE_OWNERSHIP_INTEREST, model);
  }
  DeleteOwnershipInterest(clientMilestoneMarsOwnershipInterestId: number): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.httpService.post<any>(this.apiUrl + URLConstant.DELETE_OWNERSHIP_INTEREST + clientMilestoneMarsOwnershipInterestId, { headers });
  }
  getIndustryLeader(): Observable<any> {
    return this.httpService.get<any>(
      InitiationUrlConstants.GET_INDUSTRY_LEADER
    );
  }
  SubmitToBusinessLeadership(clientMilestoneId: number, clientNumber: string): Observable<any> {
    let params = new HttpParams()
      .set('clientMilestoneId', clientMilestoneId)
      .set('clientNumber', clientNumber);
    return this.httpService.post<any>(this.apiUrl + URLConstant.SUBMIT_TO_BUSINESS_LEADERSHIP, params);
  }
  AddMarsNcaTeamMember(clientMilestoneMarsNcaFormId: number, teammemberID: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.httpService.post<any>(this.apiUrl +URLConstant.ADD_MARS_NCA_TEAM_MEMBER + clientMilestoneMarsNcaFormId + URLConstant.CONCAT_ADD_REMOVE_TEAMMEMBER_PARAM + teammemberID, { headers });
  }
  RemoveMarsNcaTeamMember(clientMilestoneMarsNcaFormId: number, teammemberID: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.httpService.post<any>(this.apiUrl + URLConstant.REMOVE_MARS_NCA_TEAM_MEMBER + clientMilestoneMarsNcaFormId + URLConstant.CONCAT_ADD_REMOVE_TEAMMEMBER_PARAM + teammemberID, { headers });
  }
}
