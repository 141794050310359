<progress-indicator></progress-indicator>
<app-tiles-group
  (tileSelectedEvent)="onTileSelected($event)"
  [tileCodeDetails]="riskManagerTileCodeDetails"
  [tilesCount]="riskManagerDashboardTilesCountModel"
  [selectedTileCode]="selectedTileCode"
  [dashboardType]="dashboard"
></app-tiles-group>
<div class="start-page-tabs" *ngIf="riskManagerDashboardTilesCountModel">
  <startpage-grid-area
    [currentDashboardTileCode]="currentDashboardTileCode"
    [currentTileNameForGrid]="selectedTileCode"
    [gridColumns]="gridColumns"
    [model]="model"
    [gridTitle]="gridTitle"
    [dashboardfilters]="riskManagerDashboardfilters"
    [buttonOptionsList]="buttonOptionsList"
    (gridUpdatedEvent)="gridUpdatedEvent($event)"
  >
  </startpage-grid-area>
  <app-new-pagination-and-grid
    *ngIf="model && model.length > 0"
    [tileCountforPage]="this.countforPagination"
    [itemsPerPage]="itemsPerPage"
    [list]="itemsPerPageOptions"
    [pageUI]="this.gotoPage + 1"
    (pageChanged)="onPageChanged($event)"
    (valueChanged)="onValueChanged($event)"
  ></app-new-pagination-and-grid>
  <div class="my-2 modal-margin">
    <dds-button
      [theme]="options.theme ?? ''"
      [kind]="options.kind ?? ''"
      [size]="options.size ?? ''"
      [width]="options.width ?? ''"
      [icon]="options.icon ?? ''"
      [isIconLeft]="options.isIconLeft ?? false"
      [role]="options.role ?? ''"
      [isInverse]="options.isInverse ?? false"
      [isColorBg]="options.isColorBg ?? false"
      [isLoading]="options.isLoading ?? false"
      [ariaLabel]="options.ariaLabel ?? ''"
      [customClass]="options.customClass ?? ''"
      [disabled]="options.disabled ?? false"
      (clicked)="openModalForRecentlyViewed()"
      >Show recently viewed</dds-button
    >
  </div>
</div>
