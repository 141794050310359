import { Component, EventEmitter, Input } from '@angular/core';
import { DocumentService } from '../../http/document/document.service';
import { ClientWebapiService } from '../../http/client/client-webapi.service';
import { environment } from '../../../environment/environment';
import { FormControl, Validators } from '@angular/forms';
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { NotifyPopupComponent } from '../../common/notify-popup/notify-popup.component';
import { CommonHelperService } from '../../common/services/common-helper.service';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { UploadFile } from '@usitsdasdesign/dds-ng/uploader';
import {
  uploadAreaOptions, textFileOptions, textareaOptions, selectDocumentTypeOptions, options, DocumentColumns, fileTooltipOptions
} from './document-upload-popup-settings';

@Component({
  selector: 'app-document-upload-popup',
  templateUrl: './document-upload-popup.component.html',
  styleUrl: './document-upload-popup.component.less'
})
export class DocumentUploadPopupComponent {
  constructor(private docService: DocumentService,
    private ncaDocService: ClientWebapiService,
    private modalService: ModalService,
    private progressIndicator: ProgressIndicatorService,
    private commonHelperService: CommonHelperService) { }

  maxFileLength: any = 5;//Max Files allowing
  maxFilesAllowedToUploadInNCA: any = 32;//Max Files allowed to upload in NCA  
  maxFileSizeAllowed: any = 50 * 1024 * 1024; // 50 MB in bytes
  maxFileSizeAllowedUpto: any = 100 * 1024 * 1024; // 100 MB in bytes 
  validFileExtensions: any = "doc,docx,msg,pptx,pdf,ppt,xls,xlsb,xlsx,xlsm,jpg,jpeg,png,zip"; //Valid File Extensions

  displayStyle: any = "none";
  isFileSize100MBExceed: boolean = false;
  notifyReason: any = "";
  fileName: any = "";
  filePath: any = "";
  uploadCount: any = 0;
  uploadFileSourceList: any = [];
  fileSourceList: any = [];
  removeUploadedDocumentFromList: any = [];
  documentTypeSelectItems: any = [];
  documentAllSectionsMetaData: any = [];
  documentUploadData: any = [];
  documentUploadedData: any = [];
  documentUnUploadData: any = [];
  fileUploadPercentage: any = "";
  isEncrypted: boolean = true;
  isFileUploaded: boolean = false;
  isDocumentExists: boolean = false;
  fileList: any = [];
  loadUserTriggeredFilesLength: number = 0;//To track the user triggered files length

  @Input() isFooter: boolean = false;
  @Input() size: string = "";
  @Input() isInverse: boolean = false;
  @Input() public documentInitiatedDetails: any = {};
  documentTypeItemsList: SelectItemOptions[] = [];

  uploadAreaOptions = uploadAreaOptions;
  textFileOptions = textFileOptions;
  textareaOptions = textareaOptions;
  selectDocumentTypeOptions = selectDocumentTypeOptions;
  options = options;
  DocumentColumns = DocumentColumns;
  fileTooltipOptions = fileTooltipOptions;

  ngOnInit(): void {
    this.progressIndicator.show();
    this.getDocumentTypeDropdownData();
    this.filePath = this.commonHelperService.getDocumentFolderPath(this.documentInitiatedDetails.moduleName);
    this.getDocumentUploadData(this.documentInitiatedDetails.docSectionId, this.documentInitiatedDetails.clientmilestoneId);
  }
  getDocumentUploadData(docSectionId: any, clientmilestoneId: any) {
    this.ncaDocService.getNCADocumentUploadData(docSectionId, clientmilestoneId).subscribe(
      {
        next: (result: any) => {
          if (docSectionId == "All") {
            this.documentAllSectionsMetaData = result;
            result = result.filter((element: any) => element.clientMilestoneDocumentSectionID == this.documentInitiatedDetails.docSectionId);
            this.progressIndicator.hide();
            this.closePopup();
          }
          else {
            this.progressIndicator.hide();
            this.documentUploadedData = result;
          }
        },
        error: (err: any) => {
          this.progressIndicator.hide();
        }
      })
  }
  getDocumentTypeDropdownData() {
    this.ncaDocService.getDocumentTypeData().subscribe(
      {
        next: (result: any) => {
          const convertedArray = this.commonHelperService.loadFormWiseDocumentTypes(result, this.documentInitiatedDetails.moduleName, this.documentInitiatedDetails.docSectionId);
          this.documentTypeItemsList = convertedArray;
          //this.enableDisableUIDocumentDetails();
          this.progressIndicator.hide();
        },
        error: (err: any) => {
          this.progressIndicator.hide();
        }
      })
  }

  removeDocument(row: any) {
    const index = this.documentUploadData.indexOf(row);
    if (index === -1) {
      return;
    }
    this.documentUploadData.splice(index, 1);
    this.fileList.forEach((element: any, index: any) => {
      if (element.fileName === row.fileName) {
        element.remove();
      }
    });
    this.documentUploadData = this.documentUploadData.filter((item: any) => item.fileName !== row.fileName);
    this.uploadFileSourceList = this.uploadFileSourceList.filter((element: any) => element.fileSource.name !== row.fileName);
    this.removeUploadedDocumentFromList = this.removeUploadedDocumentFromList.filter((element: any) => element !== row.fileName);
    this.fileSourceList = this.fileSourceList.filter((element: any, index: any) => element.file.name !== row.fileName);
    //this.loadFileSourceData(this.fileSourceList);
    this.isDocumentExists = this.documentUploadData.length > 0 ? true : false;
  }
  onSave() {
    this.uploadFiletoS3();
    this.progressIndicator.show();
    this.applyBodyOverflow();
  }
  uploadDraggedAndSelectedDocument() {
    this.fileSourceList.forEach((element: any) => {
      if (!this.uploadFileSourceList.some((item: any) => item.fileSource.name === element.file.name)) {
        this.uploadFileSourceList.push({ fileSource: element.file });
      }
      if (!this.documentUploadData.some((item: any) => item.fileName === element.file.name)) {
        this.documentUploadData.push({
          fileName: element.file.name,
          clientMilestoneDocumentTypeID: 0,
          fileComments: ""
        });
      }
    });
    let tempData = this.documentUploadData.some((obj: any) => obj.fileName.length == 0 || obj.clientMilestoneDocumentTypeID.toString() == 0);
    this.isDocumentExists = tempData === true ? false : true;
  }
  uploadFiletoS3() {
    for (const fileSource of this.uploadFileSourceList) {
      this.docService.uploadDocument(fileSource, this.filePath).subscribe(
        {
          next: (result: any) => {
            if (result.isDocumentUploaded == false) {
              if (result.isDocumentProtected == true) {
                this.notifyReason = "DocumentProtected";
                this.documentUnUploadData.push(result.documentName);
              }
              if (this.uploadFileSourceList.length - 1 == this.uploadCount) {
                this.fileSourceList = [];
                this.progressIndicator.hide();
                this.showMsg();
              }
              else {
                this.uploadCount++;
              }
            }
            else {
              this.saveNCADocumentMetaData(result.documentName, result.attachmentID);
            }
          },
          error: (err: any) => {
          }
        })
    }
  }
  prepareNCADocumentMetaDataRequest(fileName: any, attachmentID: any) {

    let documentMetaDataRequest: any;
    this.fileSourceList.forEach((element: any, index: any) => {
      if (element.file.name.toString().includes(fileName)) {
        let fileData = this.documentUploadData.filter((item: any) => item.fileName.toLowerCase() == fileName.toLowerCase());
        documentMetaDataRequest = {
          AttachmentID: attachmentID,
          CreatedBy: '00000000',//TODO:Need to replace with Employee ID          
          ModifiedBy: '',
          isActive: true,
          FileComments: element.fileComments,
          ClientMilestoneID: Number(this.documentInitiatedDetails.clientmilestoneId),
          ClientMilestoneDocumentSectionID: Number(this.documentInitiatedDetails.docSectionId),
          fileComments: fileData.length > 0 && fileData[0].fileComments != undefined ? fileData[0].fileComments : '',
          ClientMilestoneDocumentTypeID: fileData.length > 0 && fileData[0].clientMilestoneDocumentTypeID != undefined ? fileData[0].clientMilestoneDocumentTypeID : 0
        };
      }
    });
    return documentMetaDataRequest;
  }
  saveNCADocumentMetaData(fileName: any, attachmentID: any) {
    let documentMetaDataRequest = this.prepareNCADocumentMetaDataRequest(fileName, attachmentID);
    this.ncaDocService.saveNCADocumentMetaData(documentMetaDataRequest).subscribe(
      {
        next: (result: any) => {
          if (this.uploadFileSourceList.length - 1 == this.uploadCount) {
            if (this.notifyReason == "DocumentProtected") {
              this.progressIndicator.hide();
              this.showMsg();
              this.fileSourceList = this.fileSourceList.filter((item: any) => this.documentUnUploadData.includes(item.fileName));
              this.documentUploadData = this.documentUploadData.filter((item: any) => this.documentUnUploadData.includes(item.fileName));
            }
            else {
              this.fileSourceList = [];
              this.getDocumentUploadData("All", this.documentInitiatedDetails.clientmilestoneId);
            }
          }
          else {
            this.uploadCount++;
          }

        },
        error: (err: any) => {
          this.progressIndicator.hide();
          console.error("Error saving MetaData", err);
        },
        complete: () => {

        }
      }
    );
  }
  applyBodyOverflow() {
    document.body.style.overflow = 'auto';
  }
  openModal() {
    this.modalService.open(DocumentUploadPopupComponent);
  }
  closeModal() {
    this.getDocumentUploadData("All", this.documentInitiatedDetails.clientmilestoneId);
  }
  closePopup() {
    this.applyBodyOverflow();
    this.modalService.close(this.documentAllSectionsMetaData);
  }
  isFileNameValidLength() {
    let isFileValidLength = true;
    this.fileSourceList.forEach((element: any) => {
      if (element.file.name.length > 150) {
        isFileValidLength = false;
      }
    });
    let rslt = this.fileSourceList.filter((item: any) => item.file.name.length <= 150);
    if (rslt.length != this.fileSourceList.length) {
      isFileValidLength = false;
      this.fileSourceList = rslt;
    }
    return isFileValidLength;
  }
  isFileExtensionValid() {
    let isFileExtensionValid = true;
    let rslt = this.fileSourceList.filter((item: any) => this.validFileExtensions.toString().toLowerCase().includes(item.fileExtenstion.toString().toLowerCase()));
    if (rslt.length != this.fileSourceList.length) {
      isFileExtensionValid = false;
      this.fileSourceList = rslt;
    }
    return isFileExtensionValid;
  }
  isFileNameExist() {
    let isFileExist = false;
    let fileNameList: any = [];
    this.documentUploadedData.forEach((element: any) => { fileNameList.push(element.fileName) });
    let rslt = this.fileSourceList.filter((item: any) => !fileNameList.includes(item.fileName));
    if (rslt.length != this.fileSourceList.length) {
      isFileExist = true;
      this.fileSourceList = rslt;
    }
    return isFileExist;
  }
  isFileSizeExceed() {
    let isFileSizeExceed = false;
    this.isFileSize100MBExceed = false;
    let sum: number = 0;
    this.fileSourceList.forEach((element: any) => sum += element.file.size);
    if (this.maxFileSizeAllowedUpto < sum)
      isFileSizeExceed = true;
    return isFileSizeExceed;
  }
  isSigleFileMaxSizeExceed() {
    let isFileSizeExceed = false;
    this.fileSourceList.forEach((element: any) => {
      if (element.file.size > this.maxFileSizeAllowed) {
        isFileSizeExceed = true;
      }
    });
    return isFileSizeExceed;
  }
  isMaxFileSelectionExceed() {
    let isMaxFileSelectionExceed = false;
    let selectedFileCount = this.fileSourceList.length;
    if (selectedFileCount > this.maxFileLength) {
      isMaxFileSelectionExceed = true;
    }
    return isMaxFileSelectionExceed;
  }
  isMaxFileUploadExceed() {
    let isMaxFileUploadExceed = false;
    let selectedFileCount = this.fileSourceList.length + this.documentUploadedData.length;
    if (selectedFileCount > this.maxFilesAllowedToUploadInNCA) {
      isMaxFileUploadExceed = true;
    }
    return isMaxFileUploadExceed;
  }
  removeBodyOverflow() {
    document.body.style.overflow = 'hidden';
  }
  showMsg(modalBtn?: ButtonComponent) {
    this.removeBodyOverflow();
    let modalRef = this.modalService.open(NotifyPopupComponent, { isFooter: true, size: 'md', isInverse: false, notifyReason: this.notifyReason });
    modalRef.onClosed().subscribe(() => {
      if (modalBtn) {
        modalBtn.focus();
      }
    });
  }
  uploadedItems(selectedFilesList: UploadFile[]) {
    if (selectedFilesList.length > 0) {
      this.fileList = selectedFilesList;
      this.loadUserTriggeredFilesLength = this.loadUserTriggeredFilesLength == 0 ? selectedFilesList.length : this.loadUserTriggeredFilesLength;
      this.removePreviousUploadDocument(selectedFilesList); //Due to DDS Upload component issue, we are removing the already uploaded files from the list
      if (this.fileSourceList.length > 0 && this.loadUserTriggeredFilesLength == selectedFilesList.length) {
        if (this.isMaxFileUploadExceed()) { //Validate Max File Selection - Exceeds 32 File
          this.fileSourceList = [];
          this.removeUploadedDocumentFromList = this.removeUploadedDocumentFromList.filter((item: any) => !selectedFilesList.some((file: any) => file.fileName === item));
          this.resetFiles();
          this.notifyReason = "FileCount_ClientMilestoneDocument";
          this.showMsg();
        }
        else if (this.isMaxFileSelectionExceed()) { // Validate Max File Selection - Exceeds 5 File
          this.fileSourceList = [];
          this.removeUploadedDocumentFromList = this.removeUploadedDocumentFromList.filter((item: any) => !selectedFilesList.some((file: any) => file.fileName === item));
          this.resetFiles();
          this.notifyReason = "inValidFileSelection";
          this.showMsg();
        }
        else if (this.isFileSizeExceed()) { // Validate Total File Size Exceeds 100 MB 
          this.notifyReason = "FileSizeExceeds100MB";
          this.showMsg();
        }
        else {
          if (this.isSigleFileMaxSizeExceed()) { // Validate single file exceeds 50 MB (Allow)
            this.notifyReason = "inValidFileSize";
            this.showMsg();
          }
          if (!this.isFileNameValidLength()) { // Validate filename length Exceeds 150
            this.notifyReason = "FileNameExceeds150Char";
            this.showMsg();
          }
          if (!this.isFileExtensionValid()) {  // Validate File Extension
            this.notifyReason = "inValidFileType";
            this.showMsg();
          }
          if (this.isFileNameExist()) { // Validate Duplicate File
            this.notifyReason = "FileExist";
            this.showMsg();
          }
          if (this.fileSourceList.length > 0) {
            this.uploadDraggedAndSelectedDocument();
          }
        }
      }
      this.resetFiles();
    }
    else {
      this.loadUserTriggeredFilesLength = 0;
    }
  }
  loadFileSourceData(fileSource: any) {
    fileSource.forEach((element: any) => {
      this.removeUploadedDocumentFromList.push(element.fileName);
    });
  }
  removePreviousUploadDocument(selectedFilesList: any) {
    if (this.removeUploadedDocumentFromList.length == 0) {
      this.loadFileSourceData(selectedFilesList);
      this.fileSourceList = selectedFilesList;
    }
    else {
      var tempList = selectedFilesList.filter((element: any, index: any) => this.removeUploadedDocumentFromList.indexOf(element.fileName) === -1);
      tempList.forEach((element: any) => {
        this.fileSourceList.push(element);
      });
      if (this.loadUserTriggeredFilesLength == selectedFilesList.length && tempList.length != selectedFilesList.length) { this.notifyReason = "FileExist"; this.showMsg(); }
      this.loadFileSourceData(tempList);
    }
  }
  valueChange(row: any) {
    let tempData = this.documentUploadData.some((obj: any) => obj.fileName.length == 0 || obj.clientMilestoneDocumentTypeID.toString() == 0);
    this.isDocumentExists = tempData === true ? false : true;
  }
  onFileNameEdit(row: any) {
    row.isFileNameInEditMode = true;
  }

  resetFiles() {
    this.fileList.forEach((element: any, index: any) => {
      element.remove();
    });
  }
}
