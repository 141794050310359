<progress-indicator></progress-indicator>

<div class="tiles-container" *ngIf="!isLoading">
  <div class="page-header margin-bottom-l">
    <div class="d-flex justify-content-between align-items-center">
      <div class="title inline-block font-size-32">Third party agreements repository</div>
      <div class="button-section d-flex align-items-center text-right"></div>
    </div>
  </div>
</div>

