import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environment/environment';
import { Observable } from 'rxjs';
import {
  DashboardDataCollection,
  DashboardGridLevelFilter,
} from '../../common/models/start-page/start-page.model';
import {
  RecentlyViewedSubmissionList,
  ReviewerDashboardData,
  ReviewerDashboardTilesCount,
} from '../../common/models/start-page/reviewer-dashboard.model';

@Injectable({
  providedIn: 'root',
})
export class ReviewerDashboardWebapiService {
  constructor(private httpService: HttpClient) {}

  apiUrl: string = environment.apiUrl;
  // apiUrl: string = 'https://dapi.eclipse.deloitte.com/api/';
  // apiUrl: string = 'https://qapi.eclipse.deloitte.com/api/'; //qa

  getReviewerDashBoardGridData(
    tileCode: string,
    pageNumber: number,
    pageSize: number,
    sortParameterCode: number,
    isAscending: boolean,
    filterData: DashboardGridLevelFilter[]
  ): Observable<DashboardDataCollection<ReviewerDashboardData>> {
    const params = {
      tileCode: tileCode,
      pageNumber: pageNumber,
      pageSize: pageSize,
      sortParameterCode: sortParameterCode,
      isAscending: isAscending,
    };
    return this.httpService.post<
      DashboardDataCollection<ReviewerDashboardData>
    >(
      this.apiUrl + 'ReviewerDashboard/GetReviewerDashBoardGridData',
      filterData,
      { params }
    );
  }

  getReviewerDashboardTilesCount(): Observable<ReviewerDashboardTilesCount> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<ReviewerDashboardTilesCount>(
      this.apiUrl + 'ReviewerDashboard/GetReviewerDashboardTilesCount',
      { headers }
    );
  }

  getRecentlyViewedSubmissions(
    pageNumber: number,
    pageSize: number
  ): Observable<RecentlyViewedSubmissionList> {
    return this.httpService.get<RecentlyViewedSubmissionList>(
      this.apiUrl +
        'ReviewerDashboard/GetRecentlyViewedSubmissions?pageNumber=' +
        pageNumber +
        '&pageSize=' +
        pageSize
    );
  }
}
