import { InitiateSubmissionComponent } from './initiate-submission/initiate-submission.component';
import { OpportunityDetailsComponent } from './opportunity-details/opportunity-details.component';
import { OpportunitySummaryComponent } from './opportunity-summary/opportunity-summary/opportunity-summary.component';

export const INTAKE_ROUTES = [
  {
    path: 'submission',
    children: [
      {
        path: '',
        component: InitiateSubmissionComponent,
      },
    ],
  },
  {
    path: 'submission/opportunity-details/:id',
    component: OpportunityDetailsComponent,
  },
  {
    path: 'opportunity-summary',
    component: OpportunitySummaryComponent,
  },
];
