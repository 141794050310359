import { RadioButton, RadioGroupOptions } from '@usitsdasdesign/dds-ng/radio';
import { ErrorState } from '@usitsdasdesign/dds-ng/shared';
import { IntakeConstant } from '../../../constants/intake.constant';
import { DeliverableOptionValues } from '../../../constants/intake_document_close_out_deliverable_option_values.enum';
import { capitalizeFirstLetter } from '../../../common/intake.util';

export const RADIO_GROUP_OPTIONS_FOR_DELIVERABLE_SCOPE: RadioGroupOptions = {
  name: 'deliverable',
  disabled: false,
  isError: false,
  errorState: ErrorState.default,
  isUncheckable: false,
  title:
    'Is there an updated version of the DEL that requires an additional review by the Risk Manager?',
  isRequired: true,
  isInverse: false,
};

export const DELIVERABLE_RADIO_GROUP_VALUE: RadioButton[] = [
  {
    options: {
      label: capitalizeFirstLetter(
        DeliverableOptionValues.YES_RESUBMIT_FOR_REVIEW
      ),
      theme: IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR,
      disabled: undefined,
    },
    value: DeliverableOptionValues.YES_RESUBMIT_FOR_REVIEW,
  },
  {
    options: {
      label: capitalizeFirstLetter(DeliverableOptionValues.NO),
      theme: IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR,
    },
    value: DeliverableOptionValues.NO,
  },
];
