import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubmissionService } from '../../../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import { IntakeClientSummaryModel } from '../../../../common/models/intake-client-summary.model';
import { StatusEnum } from '../../../constants/status-enum';
@Component({
  selector: 'app-intake-client',
  templateUrl: './intake-client.component.html',
  styleUrl: './intake-client.component.less',
})
export class IntakeClientComponent implements OnInit {
  clientSummaryData!: IntakeClientSummaryModel | undefined;
  unsubscriber$ = new Subject<void>();
  constructor(private readonly intakeService: SubmissionService) {}
  @Input() clientNumber: string = '';
  @Output() independenceDataEmitter = new EventEmitter<{
    services: string;
    servicesDescription: string;
    sapdgmfId: string;
  }>();
  clientnumberData: string = '';
  ngOnInit(): void {
    this.clientnumberData = this.clientNumber;
    this.intakeService
      .getIntakeClientSummary(this.clientNumber)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: IntakeClientSummaryModel) => {
          // TODO remove when client Status is not null
          
          this.clientSummaryData = response;
          this.clientSummaryData.clientStatus = response.clientStatus;
          this.independenceDataEmitter.emit({
            services: response.services,
            servicesDescription: response.servicesDescription,
            sapdgmfId: response.sapdgmfId,
          });
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  transformStatus(status: string): StatusEnum {
    return (
      Object.values(StatusEnum).find((enumValue) => enumValue === status) ||
      StatusEnum.Draft
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
