import { RadioButton, RadioGroupOptions } from '@usitsdasdesign/dds-ng/radio';
import { ErrorState, Size } from '@usitsdasdesign/dds-ng/shared';
import { capitalizeFirstLetter } from '../../../common/intake.util';
import { IntakeConstant } from '../../../constants/intake.constant';
import { CoElSowOptionValues } from '../../../constants/intake_document_close_out_co_elsow_option_values.enum';
import { SuggestionsTagsInputOptions } from '@usitsdasdesign/dds-ng/suggestions-tags-input';
import { DescriptionType } from '@usitsdasdesign/dds-ng/input';
import { SelectOptions } from '@usitsdasdesign/dds-ng/select';

export const RADIO_GROUP_OPTIONS_FOR_CO_AND_ELSOW_SCOPE: RadioGroupOptions = {
  name: 'other',
  disabled: false,
  isError: false,
  errorState: ErrorState.default,
  isUncheckable: false,
  title: 'Has the contract/change order been signed/executed?',
  isRequired: true,
  isInverse: false,
};

export const CO_AND_EL_SOW_RADIO_GROUP_VALUE: RadioButton[] = [
  {
    options: {
      label: capitalizeFirstLetter(CoElSowOptionValues.YES),
      theme: IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR,
      disabled: undefined,
    },
    value: CoElSowOptionValues.YES,
  },
  {
    options: {
      label: capitalizeFirstLetter(CoElSowOptionValues.NO_RESUBMIT_FOR_REVIEW),
      theme: IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR,
    },
    value: CoElSowOptionValues.NO_RESUBMIT_FOR_REVIEW,
  },
  {
    options: {
      label: capitalizeFirstLetter(
        CoElSowOptionValues.NO_DOCUMENT_WILL_NOT_BE_EXECUTED
      ),
      theme: IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR,
    },
    value: CoElSowOptionValues.NO_DOCUMENT_WILL_NOT_BE_EXECUTED,
  },
];

export const INPUT_SEARCH_OPTIONS: SuggestionsTagsInputOptions = {
  label: 'Label',
  ariaLabel: '',
  placeholder: 'Search for WBS',
  disabled: false,
  isRequired: false,
  isInverse: false,
  isError: false,
  errorMessage: '',
  errorState: ErrorState.default,
  customClass: '',
  size: Size.md,
  stickerWidth: '',
  stickerShift: 0,
  stickerMaxHeight: '18.75rem',
  stickerPosition: 'bottom',
  stickerIndent: 8,
  stickerCustomClass: '',
  stickerIsDynamic: true,
  tooltipIcon: 'dds-icon_info',
  tooltipPosition: 'right',
  tooltipIndent: 8,
  tooltipWidth: undefined,
  tooltipShift: 0,
  tooltipShowDelay: 0,
  tooltipHideDelay: 0,
  tooltipHasBeak: true,
  tooltipIsDisabled: false,
  tooltipCustomClass: '',
  tooltipTheme: 'dark',
  description: '',
  descriptionType: DescriptionType.text,
  labelPosition: 'external',
  maxHeight: '6.25rem',
  minSearchSymbols: 1,
  maxLength: 20,
};

export const selectOptionsArchive: SelectOptions = {
  placeholder: 'Select',
};

export const DUMMY_ARCHIVE_SELECT_OPTIONS:any = [
  {
    ArchiveDetails: [
      {
        ArchiveID: 'e1622aa2-4bb2-9cf7-062a-a77573092cff',
        ArchiveNumber: '3090486',
        ArchiveName: 'Workday Enabledghgn',
        ArchiveFoldersArray: [
          'Engagement Agreements',
          'Engagement establishment, administration, and delivery',
        ],
        ArchiveFolder:
          'Engagement Agreements,Engagement establishment, administration, and delivery',
        Business: 'Deloitte Advisory',
        ClientNumber: '1234567890',
        ClientName: 'A16Z',
        Partner: 'Bharate, Jyoti',
      },
    ],
  },
];