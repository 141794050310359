import { RadioButton, RadioGroupOptions } from '@usitsdasdesign/dds-ng/radio';
import { ErrorState } from '@usitsdasdesign/dds-ng/shared';
import { IntakeConstant } from '../../../constants/intake.constant';
import { WinningProposalOptionValues } from '../../../constants/intake_document_close_out_winning_proposal_option_values.enum';
import { capitalizeFirstLetter } from '../../../common/intake.util';

export const RADIO_GROUP_OPTIONS_FOR_WINNING_PROPOSAL_SCOPE: RadioGroupOptions =
  {
    name: 'winningProposal',
    disabled: false,
    isError: false,
    errorState: ErrorState.default,
    isUncheckable: false,
    title: 'Did we submit a winning proposal (i.e., we won the opportunity)?',
    isRequired: true,
    isInverse: false,
  };

export const WINNING_PROPOSAL_RADIO_GROUP_VALUE: RadioButton[] = [
  {
    options: {
      label: capitalizeFirstLetter(WinningProposalOptionValues.YES),
      theme: IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR,
      disabled: undefined,
    },
    value: WinningProposalOptionValues.YES,
  },
  {
    options: {
      label: capitalizeFirstLetter(WinningProposalOptionValues.NO_RESUBMIT_FOR_REVIEW),
      theme: IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR,
    },
    value: WinningProposalOptionValues.NO_RESUBMIT_FOR_REVIEW,
  },
  {
    options: {
      label: capitalizeFirstLetter(WinningProposalOptionValues.NO_OPPORTUNITY_LOST_DISCONTINUE_ABANDONED),
      theme: IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR,
    },
    value: WinningProposalOptionValues.NO_OPPORTUNITY_LOST_DISCONTINUE_ABANDONED,
  },
];
