<div class="dds-modal-overlay" (click)="close()"></div>

<div
  ddsResize
  [ngClass]="{
    'dds-modal_lg': size === 'lg',
    'dds-modal_inverse': isInverse
  }"
  class="dds-modal"
>
  <div class="dds-modal__header">
    <span class="dds-modal__title">Administratively close submission</span>
    <dds-button
      size="sm"
      icon="dds-icon_close"
      kind="silent"
      theme="dark"
      ariaLabel="Close modal"
      class="dds-modal__close"
      [isInverse]="isInverse"
      (clicked)="close()"
    ></dds-button>
  </div>

  <div class="dds-modal__body">
    <div class="body-section">
      <div class="sub-text-section d-flex margin-top-xs relative">
        <span class="icon-s inline-block relative icon-pinned"></span>
        <span>
          <div class="eclipse-header-grey">
            {{ configLableDescription }}
          </div>
          <div
            class="vertical-line-green absolute"
            *ngIf="isShowMoreDocumentNotes"
          ></div>
        </span>
      </div>
      <div class="padding-left-m">
        <span>
          <div class="margin-top-xs text-gray" *ngIf="isShowMoreDocumentNotes">
            <div class="eclipse-header-grey">
              When to Administratively Close: 
            </div>
            <div
              [innerHTML]="configLableGuidance"
              class="eclipse-content-grey"
            ></div>
          </div>
        </span>
        <dds-button
          class="showMoreCls"
          [theme]="showMoreOptions.theme ?? ''"
          [kind]="showMoreOptions.kind ?? ''"
          [size]="showMoreOptions.size ?? ''"
          [width]="showMoreOptions.width ?? ''"
          [icon]="showMoreOptions.icon ?? ''"
          [isIconLeft]="showMoreOptions.isIconLeft ?? false"
          [role]="showMoreOptions.role ?? ''"
          [ariaLabel]="showMoreText"
          [customClass]="showMoreOptions.customClass ?? ''"
          (clicked)="clickToShowDocumentMoreLepMessage()"
          >{{ showMoreText }}</dds-button
        >
      </div>
      <div class="comment margin-top-xs">
        <span class="eclipse-small-header-grey">Reason</span>
        <dds-select
          [placeholder]="selectOptions.placeholder ?? ''"
          [list]="actionDropdownList"
          [(ngModel)]="selectedReason"
        ></dds-select>
      </div>
      <div class="comment margin-bottom-l margin-top-xs">
        <span class="eclipse-small-header-grey">Additional details</span>
        <dds-textarea
          [(ngModel)]="additionDetail"
          [placeholder]="textareaOptions.placeholder ?? ''"
          [maxLength]="textareaOptions.maxLength ?? 0"
          [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''"
          [minHeight]="textareaOptions.minHeight ?? 0"
          [size]="textareaOptions.size ?? ''"
          [ariaLabel]="'Additional details'"
        >
        </dds-textarea>
      </div>
    </div>
  </div>

  <div class="dds-modal__footer">
    <div class="dds-modal__footer-content dds-modal__footer-content_left">
      <dds-button
        class="dds-modal__footer-item custom-button"
        (clicked)="saveSubmissionHistory()"
        [disabled]="!formValid()"
        (keyboardClicked)="saveSubmissionHistory()"
        [ariaLabel]="'Additional close'"
        >Administratively close</dds-button
      >
      <dds-button
        class="dds-modal__footer-item custom-button"
        kind="secondaryLoud"
        [isInverse]="isInverse"
        (clicked)="close()"
        (keyboardClicked)="close()"
        [ariaLabel]="'Cancel'"
        >Cancel</dds-button
      >
    </div>
  </div>
</div>
