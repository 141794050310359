import { Component } from '@angular/core';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { IntakeConstant } from '../../../constants/intake.constant';
import { Size } from '@usitsdasdesign/dds-ng/shared';

import {
  OpportunityDetails,
  OpportunityList,
} from '../../../../common/models/opportunity-details.model';
import { SubmissionService } from '../../../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import {
  configurationType,
  toolTipConfig,
} from '../../../../common/models/common-models';
import { opportunityTableColumns } from '../../../initiate-submission/initiate-submission-helper';

@Component({
  selector: 'app-search-elsow',
  templateUrl: './search-elsow.component.html',
  styleUrl: './search-elsow.component.less',
})
export class SearchELSOWComponent {
  uiElementSize: Size = IntakeConstant.UI_ELEMENT_SIZE
  searchOptions: SearchOptions = {
    placeholder: IntakeConstant.SEARCH_OPTION_PLACEHOLDER,
    size: this.uiElementSize,
    customClass: '',
  };
  opportunityTableColumns = opportunityTableColumns;
  opportunityList: OpportunityDetails[] = [];
  selectedOportunity: string = '';
  showDropdown: boolean = false;
  notFound: string = '';
  notAvailable: boolean = true;
  totalCount: number = 0;
  pageNumber: number = 0;
  pageSize: number = IntakeConstant.PAGE_NUMBER;
  unsubscriber$: Subject<any> = new Subject<any>();
  configurationsIntake: configurationType[] = [];
  tooltipIntake: toolTipConfig[] = [];
  showOpportunityDetails: boolean = false;
  changeOrderList: any[] = [];

  constructor(private readonly submissionService: SubmissionService) {}

  ngOnInit(): void {
    this.submissionService
      .getIntakeConfigurations()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: configurationType[]) => {
          if (response && response.length > 0) {
            this.configurationsIntake = response;
          }
        },
        error: (err) => {
          console.error('An error occurred during get config details: ', err);
        },
      });
    this.gethangeOrderTooltip();
    this.getIntakeTooltips();
  }

  getIntakeTooltips(): void {
    this.submissionService
      .getIntakeTooltips()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: toolTipConfig[]) => {
          if (response && response.length > 0) {
            this.tooltipIntake = response;
          }
        },
        error: (err) => {
          console.error('An error occurred during tooltips details fetch: ', err);
        },
      });
  }

  fieldCleared(): void {
    this.opportunityList = [];
    this.selectedOportunity = '';
    this.showDropdown = false;
    this.notFound = '';
  }

  selectOpportunityChanged(): void {
    this.totalCount = 0;
    this.pageNumber = 1;
    if (this.selectedOportunity.length > 2 && this.selectedOportunity !== '') {
      this.fetchOpportunitySearchResult(1);
    } else {
      this.notFound = '';
      this.opportunityList = [];
      this.totalCount = 0;
      this.showDropdown = false;
    }
  }

  fetchOpportunitySearchResult(pageNumber: number): void {
    this.submissionService
      .getOpportunityDetails(this.selectedOportunity, pageNumber, this.pageSize)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (opportunity: OpportunityList) => {
          if (opportunity?.totalCount > 0) {
            this.notFound = '';
            if (pageNumber === 1) {
              this.opportunityList = opportunity?.opportunities;
            } else {
              this.opportunityList = [
                ...this.opportunityList,
                ...(opportunity?.opportunities ?? []),
              ];
            }
            this.totalCount = opportunity?.totalCount;
            if (this.opportunityList.length > 0) this.showDropdown = true;
          } else {
            this.notFound = this.findText(opportunity);
            this.opportunityList = [];
            this.totalCount = 0;
            this.showDropdown = false;
          }
        },
        error: (err) => {
          console.error('Error fetching submission details', err);
        },
      });
  }

  findText(opportunity: OpportunityList): string {
    const notFoundtext = this.configurationsIntake.find(
      (config) => config.appConfigurationLabelKey === opportunity?.notFoundLabel
    )?.appConfigurationLabelValue;
    return notFoundtext ?? '';
  }

  gethangeOrderTooltip(): string {
    return (
      this.tooltipIntake.find(
        (config) => config.appConfigurationLabelKey === 'CHANGE_ORDER_TOOL_TIP'
      )?.appConfigurationLabelValue ?? ''
    );
  }

  fetchOpportunitySearchResultMore(event: any): void {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight
    ) {
      if (this.pageNumber < this.totalCount / this.pageSize) {
        this.pageNumber++;
        this.fetchOpportunitySearchResult(this.pageNumber);
      }
    }
  }

  fetchOpportunityDetails(opportunity: OpportunityDetails): void {
    this.opportunityList = [];
    this.showDropdown = false;
    this.showOpportunityDetails = true;
    this.changeOrderList = [opportunity];
    this.selectedOportunity = '';
  }

  removeOpportunity(): void {
    this.changeOrderList = [];
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next('');
    this.unsubscriber$.complete();
  }
}
