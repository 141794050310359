<div class="dds-modal-overlay" (click)="close()"></div>

<div ddsResize class="dds-modal dds-modal_lg">
  <div class="dds-modal__header">
    <span class="dds-modal__title">Manage team members</span>
    <dds-button
      size="sm"
      icon="dds-icon_close"
      kind="silent"
      theme="dark"
      ariaLabel="Close modal"
      class="dds-modal__close"
      (clicked)="close()"
    ></dds-button>
  </div>

  <div class="dds-modal__body">
    <div class="body-section">
      <div>
        <div class="padding-bottom-xxs font-size-12">
          Actively review and update team access permissions to ensure only authorized personnel have access to the record. Maintaining accurate team member roles is essential for safeguarding sensitive information and compliance.
        </div>
        <div class="padding-bottom-16">
          <span class="font-size-14 font-bold">Lead engagement partner</span>
        </div>
        <div *ngIf="lepTableData !== null">
          <table class="dds-data-table">
            <thead class="dds-data-table__header">
              <tr>
                <th
                  *ngFor="let column of lepTableColumns"
                  class="dds-data-table__header-cell"
                  [ngStyle]="{ width: column.width, minWidth: column.minWidth }"
                >
                  <div class="dds-data-table__content">
                    <span>{{ column.header || column.name }}</span>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody class="dds-data-table__body">
              <tr class="dds-data-table__row">
                <td class="dds-data-table__cell">
                  <div (click)="$event.stopPropagation()">
                    <ng-container *ngIf="showLepNameInputBox; else displayName">
                      <span>
                        <app-people-picker
                          id="lep-name-editor"
                          [isTechinalReviewerSearch]="true"
                          (userSelected)="onLepSelected($event)"
                          [isLep]="true"
                        />
                      </span>
                    </ng-container>
                    <ng-template #displayName>
                      <span class="padding-right-xxs">{{ tempLepName }}</span>
                      <span
                        class="dds-icon dds-icon_edit__s__filled edit-icon"
                        (click)="editLepName()"
                      ></span>
                    </ng-template>
                  </div>
                </td>
                <td class="dds-data-table__cell">
                  {{
                    lepTableData?.primaryIndustry
                      ? lepTableData?.primaryIndustry
                      : "-"
                  }}
                </td>
                <td class="dds-data-table__cell">
                  {{
                    lepTableData?.offeringPortfolio
                      ? lepTableData?.offeringPortfolio
                      : "-"
                  }}
                </td>
                <td class="dds-data-table__cell">
                  {{
                    lepTableData?.primaryOffering
                      ? lepTableData?.primaryOffering
                      : "-"
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="padding-top-32 padding-bottom-16">
          <div class="font-bold font-size-14 margin-bottom-vertical-s">
            Team members
          </div>
          <div class="team-member-people-picker-width">
            <app-people-picker
              *ngIf="showPeoplePicker"
              [isTeamMemberSearch]="true"
              (userSelected)="addTeamMember($event)"
            />
          </div>
        </div>
        <div>
          <div>
            <table class="dds-data-table">
              <thead class="dds-data-table__header">
                <tr>
                  <th
                    *ngFor="let column of teamMembersColumns"
                    class="dds-data-table__header-cell"
                    [ngStyle]="{
                      width: column.width,
                      minWidth: column.minWidth
                    }"
                  >
                    <ng-container
                      *ngIf="column.name !== 'poc'; else displayPocCol"
                      class="dds-data-table__content"
                    >
                      <span>{{ column.header || column.name }}</span>
                    </ng-container>
                    <ng-template #displayPocCol>
                      <div class="d-flex">
                        <span class="padding-right-xxs">{{
                          column.header || column.name
                        }}</span>
                        <span
                          class="icon-s icon-info relative position-alignment"
                          [tooltipWidth]="pocTooltip.tooltipWidth ?? 238"
                          [ddsTooltip]="tooltipText"
                          [tooltipInvokeType]="
                            pocTooltip.tooltipInvokeType ?? ''
                          "
                          [tooltipType]="pocTooltip.tooltipType ?? ''"
                          [tooltipSize]="pocTooltip.tooltipSize ?? ''"
                          [tooltipPosition]="pocTooltip.tooltipPosition ?? ''"
                          [tooltipIndent]="pocTooltip.tooltipIndent ?? 0"
                          [tooltipHasBeak]="pocTooltip.tooltipHasBeak ?? false"
                          [tooltipTheme]="pocTooltip.tooltipTheme ?? ''"
                          ddsFocus
                        ></span>
                      </div>
                    </ng-template>
                  </th>
                </tr>
              </thead>
              <tbody class="dds-data-table__body">
                <tr
                  *ngFor="let row of teamMemberData; index as index"
                  class="dds-data-table__row"
                >
                  <td class="dds-data-table__cell">
                    {{ row.fullName ? row.fullName : "-" }}
                  </td>
                  <td class="dds-data-table__cell">
                    {{ row.primaryIndustry ? row.primaryIndustry : "-" }}
                  </td>
                  <td class="dds-data-table__cell">
                    {{ row.offeringPortfolio ? row.offeringPortfolio : "-" }}
                  </td>
                  <td class="dds-data-table__cell">
                    {{ row.primaryOffering ? row.primaryOffering : "-" }}
                  </td>
                  <td class="dds-data-table__cell">
                    <input
                      type="radio"
                      name="selectedPOC"
                      [value]="row.employeeId"
                      (change)="pocStateChanged(row.employeeId)"
                      [checked]="row.isPOC"
                      [class.green-radio]="selectedPoc === row.employeeId"
                    />
                  </td>
                  <td class="dds-data-table__cell">
                    <dds-checkbox
                      [ariaLabel]="'row ' + (index + 1)"
                      [value]="row.enableNotification ? row.enableNotification : false"
                      [ngClass]="{
                        'dds-data-table__row_selected': row.enableNotification
                      }"
                      theme="green"
                      (click)="toggleRow(row)"
                    ></dds-checkbox>
                  </td>
                  <td class="dds-data-table__cell">
                    <span
                      class="dds-icon dds-icon_delete__s__stroke cursor-clickable"
                      (click)="removeTeamMember(row)"
                    ></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="dds-modal__footer">
    <div class="dds-modal__footer-content dds-modal__footer-content_left"></div>
  </div>
</div>
