<div class="dds-inline-flex">
    <button class="dds-btn">
      <div class="dds-btn__content">
        <span class="dds-btn__text">Open modal</span>
      </div>
    </button>
  </div>
  <div class="dialog-modal">
    <div class="dds-modal-init dds-modal-open">
      <div class="dds-modal-overlay"></div>
      <div class="dds-modal dds-modal_lg">
        <div class="dds-modal__header">
          <span class="dds-modal__title">{{resolve.labelHeader}}</span>
          <button class="dds-modal__close dds-icon dds-icon_close" (click)="cancel()" aria-label="Close modal"></button>
        </div>
        <div class="dds-modal__body">
          <div class="margin-top-s">
            <span class="text-label">
              {{resolve.labelBody}}
            </span>
          </div>
        </div>
        <div class="dds-modal__footer">
          <div class="dds-modal__footer-content dds-modal__footer-content_left"></div>
          <div class="dds-modal__footer-content dds-modal__footer-content_right">
            <div class="dds-modal__footer-item dds-inline-flex">
              <button class="dds-btn dds-btn_secondary-loud dds-btn_fluid-width" (click)="cancel()">
                <div class="dds-btn__content">
                  <span class="dds-btn__text">Cancel</span>
                </div>
              </button>
            </div>
            <div class="dds-modal__footer-item dds-inline-flex">
              <button class="dds-btn dds-btn_fluid-width" (click)="confirm()">
                <div class="dds-btn__content">
                  <span class="dds-btn__text">Yes</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>