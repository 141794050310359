import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitiateReviewComponent } from './initiate-review/initiate-review.component';
import { RouterModule } from '@angular/router';
import { INITIATION_ROUTES } from './initiation.states';
import { InitiationMarsDetailsComponent } from './mars-details/initiation-mars-details.component';
import { LibModule } from '@usitsdasdesign/dds-ng';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    InitiateReviewComponent,
    InitiationMarsDetailsComponent
  ],
  imports: [
    CommonModule,
    LibModule,
    NgbDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(INITIATION_ROUTES)
  ]
})
export class InitiationModule { }
