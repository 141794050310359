<div class="dds-modal-overlay" (click)="closeArchiveModel()"></div>

<div ddsResize class="dds-modal">
  <div class="dds-modal__header">
    <span class="dds-modal__title">Select archive</span>
    <dds-button
      size="sm"
      icon="dds-icon_close"
      kind="silent"
      theme="dark"
      ariaLabel="Close modal"
      class="dds-modal__close"
      (clicked)="closeArchiveModel()"
    ></dds-button>
  </div>

  <div class="dds-modal__body">
    <dds-suggestions-tags-input
      [(ngModel)]="wbsChargeCode"
      [ariaLabel]="'Archive'"
      [placeholder]="'Search for WBS'"
      [searchList]="searchList"
      (ngModelChange)="changeValue()"
    >
    </dds-suggestions-tags-input>

    <div
      *ngIf="wbsChargeCode"
      class="padding-none margin-top-s margin-bottom-s"
    >
      <p class="padding-none margin-bottom-xs">WBS description:</p>
      <p class="padding-none">{{ wbsDescription }}</p>
    </div>
    <div>
      <p class="padding-none margin-top-s margin-bottom-xs">Archive:</p>
      <dds-select
        [placeholder]="'Search'"
        [list]="actionDropdownList"
        [(ngModel)]="selectedArchive"
        (ngModelChange)="archiveChanged()"
      ></dds-select>
    </div>
  </div>

  <div class="dds-modal__footer" *ngIf="isFooter">
    <div class="dds-modal__footer-content dds-modal__footer-content_left">
      <dds-button
        class="dds-modal__footer-item"
        (clicked)="open()"
        (keyboardClicked)="open()"
        [disabled]="isSaveButtonDisabled"
        >Save</dds-button
      >
      <dds-button
        class="dds-modal__footer-item"
        kind="secondaryLoud"
        (clicked)="closeArchiveModel()"
        (keyboardClicked)="closeArchiveModel()"
        >Close</dds-button
      >
    </div>
  </div>
</div>
