import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../../environment/environment';
import { Observable,BehaviorSubject } from "rxjs";
@Injectable({
    providedIn: 'root'
  })
  export class SecurityWebapiService {

    private behaviorSubject: BehaviorSubject<any>;
    public data: Observable<any>;
    constructor(private httpService: HttpClient) { 
        const initialState: any = { empty: true };
        this.behaviorSubject = new BehaviorSubject(initialState);
        this.data = this.behaviorSubject.asObservable();

    }
    apiUrl: string = environment.apiUrl;

    public getPermissions(clientNumber : string) {
        this.httpService.get(this.apiUrl + 'Admin/Permission/GetPermissions?clientNumber='+ clientNumber)
            .subscribe((res: any) => this.setPermissions(res));
    }
    public setPermissions(permissions:string[]) {
        this.behaviorSubject.next(this.parseRules(permissions));
    }
    private parseRules(result: string[]) {
        let newRules: { [key: string]: { [key: string]: boolean } } = {};
        for (let permissionFullName of result) {
            let elementId = permissionFullName.substring(0, permissionFullName.lastIndexOf('.'));
            let permission = permissionFullName.substring(permissionFullName.lastIndexOf('.') + 1);
            if (!newRules[elementId]) {
                newRules[elementId] = {};
            }
            newRules[elementId][permission] = true;
        }
        return newRules;
    }
  }