import {
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Column, Themes } from '@usitsdasdesign/dds-ng/shared';
import { ButtonComponent, ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ToastOptions, ToastService } from '@usitsdasdesign/dds-ng/toast';
import { SubmissionService } from '../../../http/intake/submission.service';
import { forkJoin, Observable, Subject, takeUntil } from 'rxjs';
import {
  lepDataValue,
  riskManagerTableHeaderData,
} from '../opportunity-details-settings';
import { IntakeConstant } from '../../constants/intake.constant';
import {
  ADD_REVIEWER_BTN_OPTIONS,
  ARCHIVE_AND_COMPLETE_BTN_OPTIONS,
  CO_CHANGE_SCOPE_RADIO_GROUP_VALUE,
  CO_SEARCH_OPTION,
  PrimaryButton,
  RADIO_GROUP_OPTIONS_FOR_CO_CHANGE_SCOPE,
  RADIO_GROUP_OPTIONS_FOR_OTHER_SUBTYPE,
  RADIO_GROUP_OPTIONS_FOR_RFP,
  REP_RADIO_GROUP_VALUE,
  RESUBMIT_FOR_REVIEW_BTN_OPTIONS,
  SHOW_MORE_BTN_OPTIONS,
  SUBMIT_BTN_OPTIONS,
  toastMessage,
  submittedMessage,
  returnForReworkMessage,
  resubmittedToastMessage,
  OPT_OUT_SELF_REVIEW_BTN_OPTIONS,
  SELF_REVIEW_BTN_OPTIONS,
  isSelfReviewButtonVisible,
  optedOutSelfReviewToastMessage,
} from './submission-common-section.helper';
import { IntakeOppertunitySubmissionStatus } from '../../constants/intake_oppertunity_submission_status.enum';
import { IntakeDocumentSubmissionTypeCode } from '../../constants/intake_submission_type_code.enum';
import { IAutosaverOptions, Saver } from '../../../common/autosaver/saver';
import { AutosaverService } from '../../../common/autosaver/autosaver.service';
import { latestOpportunityDetails } from '../../../common/models/latestOpportunityDetails';
import { LepValue } from '../../../common/models/opportunity-details.model';
import { ProgressIndicatorService } from '../../../common/services/progress-indicator.service';
import {
  CoElSowEventEmitterType,
  DiscontinuedRequestModel,
  EmitUpdatedTitleModal,
  IntakeSubmissionDetailsCommonSectionDataReceivedModal,
  IntakeSubmissionDetailsCommonSectionDataSendingModal,
  SubmissionApprovers,
  SwiftDetails,
} from '../../../common/models/intake-submission-details-common-section.model';
import { ActivatedRoute } from '@angular/router';
import {
  configurationType,
  SubmissionSubType,
} from '../../../common/models/common-models';
import { RoleEnum } from '../../constants/Role.enum';
import { copyToClipboard, randomString } from '../../common/intake.util';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';

import { DocumentSelectionPopupComponent } from './document-selection-popup/document-selection-popup.component';
import { DocumentComponent } from './document/document.component';
import { WinningProposalOptionValues } from '../../constants/intake_document_close_out_winning_proposal_option_values.enum';

import { ConfirmPopModalComponent } from './confirm-pop-modal/confirm-pop-modal.component';
import { OtherOptionValues } from '../../constants/intake_document_close_out_other_option_values.enum';
import { IntakeDocumentTableModel } from '../../../common/models/intake-document-table.model';
import { StatusEnum } from '../../constants/status-enum';
import { AdministrativelyClosePopupComponent } from './administratively-close-popup/administratively-close-popup/administratively-close-popup.component';
import {
  CloseOutModalModel,
  DiscontinuedModalOnCloseRequestModel,
} from '../../../common/models/show-all-popup-model';
import { DeliverableOptionValues } from '../../constants/intake_document_close_out_deliverable_option_values.enum';
import { CoElSowOptionValues } from '../../constants/intake_document_close_out_co_elsow_option_values.enum';
import {
  ReviewerRole,
  ReviewerRoleType,
} from '../../../common/models/reviwer-role.model';
import { CommonService } from '../../../http/intake/common.service';
import { OppertunitySubmissionStatusCode } from '../../../common/models/oppertunity-submission-statusCode.model';

import { SubmissionActionHistoryModel } from '../../../common/models/submission-action.model';
import { RoleService } from '../../../http/intake/role.service';
import { DiscontinueModalComponent } from './discontinue-modal/discontinue-modal.component';
import { ResetStatusPopupComponent } from './reset-status-popup/reset-status-popup.component';
import { SecurityWebapiService } from '../../../http/security/security-webapi.service';
import { OptOutPopupComponent } from '../../intake-common-popups/opt-out-popup/opt-out-popup.component';
import { IntakeOptOutPopUpDataModel } from '../../../common/models/initiateSubmission.model';
import {
  ArchiveDetailsUpdatingModel,
  WbsArchiveReceivedModel,
  WbsResponseDataModel,
} from '../../../common/models/wbs-picker.model';
import { DocumentService } from '../../../http/document/document.service';
import { ArchiveDocument } from '../../../common/models/archive-document.model';
import { UserService } from '../../../http/user.service';
@Component({
  selector: 'app-submission-common-section',
  templateUrl: './submission-common-section.component.html',
  styleUrl: './submission-common-section.component.less',
})
export class SubmissionCommonSectionComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() dataOpportunityId: string = '';
  @Input() dataSubmissionId: number | null = null;
  @Input() opportunityDocsCount: number = 0;
  isAllReviewerValid: boolean = true;
  modalSetting: any = null;
  progressiveStatus: boolean | null = null;
  isEligibleTechnicalReviewer: boolean = false;
  isDocumentRequireMsgShow: boolean = false;
  isFinalDocumentAcceptedMsgShow: boolean = true;
  oppertunityStatusCodes!: OppertunitySubmissionStatusCode[];
  selfReviewDocBanner: string = '';
  defaultEmptyFields: string = IntakeConstant.DEFAULT_FOR_EMPTY_FIELDS;
  archiveDetails!: WbsArchiveReceivedModel;
  swiftDetails!: SwiftDetails;

  @Input() set dataSubmissionTypeCode(submissionTypeCode: string) {
    this.setSubMissionCodeDetails(submissionTypeCode);
  }
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updateTitle: EventEmitter<EmitUpdatedTitleModal> =
    new EventEmitter<EmitUpdatedTitleModal>();
  @Output() updateLeftMenu: EventEmitter<string> = new EventEmitter<string>();

  submissionTypeCode!: string;
  @Input() dataLepValue: LepValue = lepDataValue;
  submissionData!: IntakeSubmissionDetailsCommonSectionDataReceivedModal;
  titleValue: string = '';
  isEditingTitle: boolean = false;
  copySuccess: string | null = null;
  requiredCheckForCommonSection: boolean = false;
  submissionId: number = 0;
  submissionStatus: string | undefined = '';
  selfReviewEligibility: string | undefined = '';
  updatedRiskManagerDetails!: SubmissionApprovers[];
  isEmployeePresent: boolean = false;
  showPeoplePicker: boolean = false;
  selectedRiskManagers: string[] = [];
  unsubscriber$: Subject<any> = new Subject<any>();
  isOpportunitySelected: boolean = true;
  isVisible: boolean = true;
  textareaOptions: any = IntakeConstant.TEXT_AREA_SETTINGS_GLOBAL;
  enter_text_placeholder = IntakeConstant.ENTER_TEXT_PLACEHOLDER;
  intakeDocumentSubmissionTypeCodePro = IntakeDocumentSubmissionTypeCode.PRO;
  intakeDocumentSubmissionTypeCodeDelivery =
    IntakeDocumentSubmissionTypeCode.DEL;
  intakeDocumentSubmissionTypeCodeOther = IntakeDocumentSubmissionTypeCode.OTH;
  intakeDocumentSubmissionTypeCodeCO = IntakeDocumentSubmissionTypeCode.CO;
  intakeDocumentSubmissionTypeCodeELSOW =
    IntakeDocumentSubmissionTypeCode.EL_SOW;
  IntakeDocumentSubmissionStatusSubmitted =
    IntakeOppertunitySubmissionStatus.SUBMITTED;
  RFP_radioGroupOptions: any = RADIO_GROUP_OPTIONS_FOR_RFP;
  REP_radio_values: any[] = REP_RADIO_GROUP_VALUE;
  other_subtype_radioGroupOptions: any = RADIO_GROUP_OPTIONS_FOR_OTHER_SUBTYPE;
  RADIO_THEME_COLOR: Themes = IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR;
  COChangeScope_radioGroupOptions: any =
    RADIO_GROUP_OPTIONS_FOR_CO_CHANGE_SCOPE;
  COChangeScope_radio_values: any[] = CO_CHANGE_SCOPE_RADIO_GROUP_VALUE;
  showMoreOptions: any = SHOW_MORE_BTN_OPTIONS;
  label: string = 'Update';
  roleAdditionalReviewer: string = RoleEnum.REVIEWER;
  roleTechnicalOrIndependentReviewer: string =
    RoleEnum.TECHNICAL_OR_INDEPENDENT_REVIEWER;
  roleTechnicalOrIndependentRM: string = RoleEnum.TECHNICAL_OR_INDEPENDENT_RM;
  roleRiskManager: string = RoleEnum.RISK_MANAGER;
  roleSupportingRiskManager: string = RoleEnum.SUPPORTING_RISK_MANAGER;
  roleSpecializedRiskManager: string = RoleEnum.SPECIALIZED_RISK_MANAGER;
  private timeoutId: ReturnType<typeof setTimeout> | null = null;
  updateLabel: string = '';
  riskManagerTableHeader: Column[] = riskManagerTableHeaderData;
  isValidDocumentUploaded: boolean = false;
  changeOrderStatus: boolean = false;
  loggedInEmployeeId: string = '';
  configLableDocumet: string = '';
  configData: configurationType[] = [];
  submissionStatusCommonSection: string = '';
  showInformationalGuidanceForDeliverable: boolean = false;
  submissionActionHistory: SubmissionActionHistoryModel[] = [];
  showReviewAndReturnBtn: boolean = false;
  optOutOfSelfReviewReason: string | null | undefined = '';
  showSubmitBtnWhnDraft: boolean = false;
  showCloseOutForm: boolean = true;
  wbsCheckboxSelected: boolean = false;

  @ViewChild(DocumentComponent) documentComponent!: DocumentComponent;

  submitLabel: string = IntakeConstant.SUBMIT_BTN_LABEL;
  archiveAndCompleteBtnLabel: string =
    IntakeConstant.ARCHIVE_AND_COMPLETE_BTN_LABEL;
  reSubmitForReviewLabel: string = IntakeConstant.RESUBMIT_FOR_REVIEW_BTN_LABEL;
  selfReviewBtnLabel: string = IntakeConstant.SELF_REVIEW_BTN_LABEL;
  optOutOfSelfReviewBtnLabel: string =
    IntakeConstant.OPT_OUT_OF_SELF_REVIEW_BTN_LABEL;
  submitButtonOptions: ButtonOptions = SUBMIT_BTN_OPTIONS;
  options: ButtonOptions = ADD_REVIEWER_BTN_OPTIONS;
  archiveAndCompleteBtnOption = ARCHIVE_AND_COMPLETE_BTN_OPTIONS;
  resubmitForReviewBtnOption = RESUBMIT_FOR_REVIEW_BTN_OPTIONS;
  selfReviewBtnOption = SELF_REVIEW_BTN_OPTIONS;
  optOutOfSelfReviewBtnOption = OPT_OUT_SELF_REVIEW_BTN_OPTIONS;
  toastOptions: ToastOptions = toastMessage;
  submittedToastOption: ToastOptions = submittedMessage;
  returnForReworkToastOption: ToastOptions = returnForReworkMessage;
  resubmittedToastOption: ToastOptions = resubmittedToastMessage;
  optedOutToastOption: ToastOptions = optedOutSelfReviewToastMessage;

  reworkButton: ButtonOptions = PrimaryButton;
  internalQrmCommunicationTextareaMaxlength: number =
    IntakeConstant.TEXTAREA_CHAR_LIMIT_BIGGEST;
  public model: IntakeSubmissionDetailsCommonSectionDataSendingModal =
    {} as IntakeSubmissionDetailsCommonSectionDataSendingModal;
  public commonSectionFormModule!: Saver;
  isShowMoreDocumentNotes: boolean = false;
  showMoreText: string = IntakeConstant.SHOW_MORE_TEXT;
  coHasParentOpportunityNumber: boolean = false;
  coSearchOption = CO_SEARCH_OPTION;
  selectedOportunityForCO = '';
  isCheckboxCheckedForNoEL_SOW: boolean = true;
  readonly #route = inject(ActivatedRoute);
  opportunityNumber!: string;
  otherSubTypes$!: Observable<SubmissionSubType[]>;
  submissionTypeId: number = 0;
  submissionStatusId: number = 0;
  lepAcknowledgedBy: null | string = null;
  lepAcknowledgementDate: null | string = null;
  lepReviewedBy: null | string = null;
  showLepConfirmationMessage: boolean = false;
  employeeIdNumber: number = 0;
  showActionsHeader: boolean = false;
  isArchiveAndCompleteBtnDisabled: boolean = true;
  isResubmitForReviewBtnDisabled: boolean = true;
  otherSubmissionValueSelected: string = '';
  documentTableData: IntakeDocumentTableModel[] = [];
  showUploadFinalVersionMessage: boolean = false;
  showUploadGuidanceYellowMsgForCoAndElSowOnYes: boolean = false;
  showUploadGuidanceInfoMsgForCoAndElSowOnYes: boolean = false;
  showInfoMsgForCoElSowOnSelectNoResubmit: boolean = false;
  showUpdatedDocumentMessage: boolean = false;
  DRAFT_STATUS: string = IntakeOppertunitySubmissionStatus.DRAFT;
  RETURN_FOR_REWORK: string =
    IntakeOppertunitySubmissionStatus.RETURN_FOR_REWORK;
  roles!: ReviewerRole;
  showResetActionMessage: boolean = false;
  resetActionMessage?: SubmissionActionHistoryModel;
  isValidTechnicalReviewer: boolean = false;
  isSelfReviewButtonVisible: boolean = false;
  valueYes: string = IntakeConstant.YES;
  docStatusSubmitted: IntakeOppertunitySubmissionStatus =
    IntakeOppertunitySubmissionStatus.SUBMITTED;
  docStausSelfReviewedPendingCloseout: IntakeOppertunitySubmissionStatus =
    IntakeOppertunitySubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT;
  showCompleteCloseoutFormMsg: boolean = true;
  RMReviewedPendingCloseout: string =
    IntakeOppertunitySubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT;
  submissionStatusResubmitted: string =
    IntakeOppertunitySubmissionStatus.RESUBMITTED;
  currentLoggedInUser: string = '';
  otherSubTypes: SubmissionSubType[] = [];
  userRolesAccess: any = [];
  uploadedDocData: IntakeDocumentTableModel[] = [];
  YES_CONST: string = 'yes';
  RESUBMIT_CONST: string = 'resubmit';

  constructor(
    private readonly submissionService: SubmissionService,
    private readonly autosaverService: AutosaverService,
    private readonly modalService: ModalService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly toastService: ToastService,
    private readonly commonService: CommonService,
    private readonly roleService: RoleService,
    private readonly securityWebapiService: SecurityWebapiService,
    private readonly documentService: DocumentService,
    private readonly userService: UserService
  ) {
    this.model.submissionTitle = this.titleValue;
    this.#route.paramMap.subscribe((params) => {
      this.opportunityNumber = params.get('id')!;
    });
  }
  ngOnInit(): void {
    this.securityWebapiService.getPermissions('');
    this.securityWebapiService.data.subscribe((data) => {
      const dataString = JSON.stringify(data);
      this.isValidTechnicalReviewer = dataString.includes(
        'Workflow.AddTechnicalReviewer'
      );
      this.isAllReviewerValid = dataString.includes('Workflow.AddReviewer');
    });

    this.userService.currentLoggedInUser$.subscribe((user) => {
      this.currentLoggedInUser = user?.employeeId;
      this.userRolesAccess = user?.employeeRoles ?? [];
    });

    this.commonService.intakeDocSubmissionStatusObserable$
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: OppertunitySubmissionStatusCode[] | null) => {
          if (response && response != null) {
            this.oppertunityStatusCodes = response;
          }
        },
        error: (err) => {
          console.error('Error fetching submission status', err);
        },
      });
    this.loggedInEmployeeId = this.submissionService.employeeId;
    //Resetting the value on close of modal to null.
    this.submissionService.otherValue$.subscribe((value) => {
      this.otherSubmissionValueSelected = value;
    });
    this.loggedInEmployeeId = this.submissionService.employeeId;
    this.submissionService.updateOtherSubmissionValue('');

    let autoSaverOptions = {
      delay: 500,
      onSuccess: (saver: any, result: any) => {
        return this.postAutoSaveHandler();
      },
    } as IAutosaverOptions;
    this.commonSectionFormModule = this.autosaverService.newSaver(
      'model',
      this,
      async (model) => this.#saveCommonSectionDetails(),
      autoSaverOptions
    );
    this.commonSectionFormModule.start();

    this.submissionService.new$.subscribe((status) => {
      this.isValidDocumentUploaded = status;
    });

    this.submissionService.adminCloseModal$.subscribe((status) => {
      if (status) {
        this.openModalForAdminCloseOut();
      }
    });

    this.commonService.resetStatusModal$.subscribe((status: boolean) => {
      if (status) {
        this.openModalForResetStatus();
      }
    });
    this.submissionService.openDiscontinueModalObservable$.subscribe(
      (status: boolean) => {
        if (status) {
          this.openDiscontinueModal();
        }
      }
    );

    this.updateCloseoutBanner();
  }

  openModalForAdminCloseOut(modalBtn?: ButtonComponent): void {
    let modalRef = this.modalService.open(AdministrativelyClosePopupComponent, {
      isFooter: false,
      size: 'lg',
      isInverse: false,
      submissionId: this.submissionId,
    });
    modalRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe(() => {
        if (this.submissionId) {
          this.timeoutId = setTimeout(() => {
            this.loadSubmission();
            this.updateLeftMenu.emit(this.opportunityNumber);
          }, IntakeConstant.SET_TIMEOUT_DURATION);
        }
      });
  }

  openDiscontinueModal(): void {
    let discontinueModalRef = this.modalService.open(
      DiscontinueModalComponent,
      {
        isFooter: true,
        size: 'lg',
        isInverse: false,
      }
    );
    discontinueModalRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((response: DiscontinuedModalOnCloseRequestModel) => {
        if (this.submissionId) {
          let statusId =
            this.oppertunityStatusCodes.find(
              (status: OppertunitySubmissionStatusCode) =>
                status.statusName ===
                IntakeOppertunitySubmissionStatus.DISCONTINUED
            )?.id ?? 0;
          let discontinuReasonId = response.reasonId ?? 0;
          let discontinueSubmissionActionId = response.actionId ?? 0;
          if (
            statusId > 0 &&
            discontinuReasonId > 0 &&
            discontinueSubmissionActionId > 0
          ) {
            let discontinueDetails: DiscontinuedRequestModel = {
              submissionId: this.submissionId,
              currentStatusId: statusId,
              reasonId: discontinuReasonId,
              submissionActionId: discontinueSubmissionActionId,
            };
            this.submissionService
              .updateDiscontinueAsSubmissionStatus(discontinueDetails)
              .pipe(takeUntil(this.unsubscriber$))
              .subscribe({
                next: (response) => {
                  //  TODO : as from backend no success response so page is not refreshing
                  this.loadSubmission();
                },
                error: (err) => {
                  console.error('discontinue save error', err);
                },
              });
            //  TODO : as from backend no success response come remove this force refresh one
            this.timeoutId = setTimeout(() => {
              location.reload();
            }, IntakeConstant.SET_TIMEOUT_DURATION);
          }
        }
      });
  }

  SubmissionActionHistoryForAdminComplete(id: string): void {
    this.submissionService
      .getSubmissionActionHistory(id)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: SubmissionActionHistoryModel[]) => {
          this.submissionActionHistory = response;
          for (let i in this.submissionActionHistory) {
            if (
              this.submissionActionHistory[i].submissionActionId ===
              IntakeConstant.ResetActionID
            ) {
              this.showResetActionMessage = true;
              this.resetActionMessage = this.submissionActionHistory[i];
              return;
            } else {
              this.showResetActionMessage = false;
            }
          }
        },
        error: (err) => {
          this.progressIndicator.hide();
          console.error('Error fetching data', err);
        },
      });
  }

  checkStatusCompletedByAdmin(): boolean {
    if (
      this.submissionStatus ===
      IntakeOppertunitySubmissionStatus.COMPLETED_ADMIN
    ) {
      return true;
    }
    return false;
  }

  checkStatusDiscontinued(): boolean {
    return (
      this.submissionStatus === IntakeOppertunitySubmissionStatus.DISCONTINUED
    );
  }

  disableFieldOnComplete(): boolean {
    return (
      this.submissionStatus ===
      IntakeOppertunitySubmissionStatus.COMPLETED_ADMIN
    );
  }

  openModalForResetStatus(modalBtn?: ButtonComponent): void {
    let resetStatusModalRef = this.modalService.open(
      ResetStatusPopupComponent,
      {
        isFooter: false,
        size: 'lg',
        isInverse: false,
        submissionId: this.submissionId,
        submissionStatus: this.submissionStatus,
      }
    );
    resetStatusModalRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((resetButtonSelected) => {
        if (this.submissionId && resetButtonSelected) {
          this.timeoutId = setTimeout(() => {
            this.loadSubmission();
            this.updateLeftMenu.emit(this.opportunityNumber);
          }, IntakeConstant.SET_TIMEOUT_DURATION);
        }
      });
  }

  getChangeOrderStatus(): void {
    const submissionStatusId = this.getSubmissionStatusCode(
      IntakeOppertunitySubmissionStatus.SUBMITTED
    );
    if (submissionStatusId) {
      this.submissionService
        .getChangeOrderStatus(this.opportunityNumber, submissionStatusId)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: any) => {
            this.changeOrderStatus = response;
          },
          error: (err) => {
            console.error('Error fetching submission', err);
          },
        });
    }
  }

  getSubmissionStatusCode(statusName: string): number | undefined {
    return this.oppertunityStatusCodes?.find(
      (status: OppertunitySubmissionStatusCode) =>
        status.statusName === statusName
    )?.id;
  }

  setSubMissionCodeDetails(submissionTypeCode: string): void {
    this.submissionTypeCode = submissionTypeCode;
    this.otherSubTypes$ = this.submissionService.getSubmissionSubTypes(5);
    this.otherSubTypes$.subscribe((response) => {
      this.otherSubTypes = response;
    });
  }

  postAutoSaveHandler() {
    //TODO: fetch the save details
  }

  #saveCommonSectionDetails() {
    let commonSectionData = { ...this.model };
    commonSectionData.submissionId = this.submissionId;
    commonSectionData.parentOpportunityNumber = '';
    // TODO: add value from search field for CO, or add from existing value

    if (commonSectionData.coChangeScope != null) {
      commonSectionData.coChangeScope =
        IntakeConstant.YES_NO_CHECKBOX_VALUE_TO_BOOLEAN.get(
          commonSectionData.coChangeScope
        );
    }

    if (commonSectionData.relatedToRFP != null) {
      commonSectionData.relatedToRFP =
        IntakeConstant.YES_NO_CHECKBOX_VALUE_TO_BOOLEAN.get(
          commonSectionData.relatedToRFP
        );
    }

    if (
      this.model.submissionSubTypeId &&
      this.model.submissionSubTypeId != ''
    ) {
      commonSectionData.submissionSubTypeId = Number(
        this.model.submissionSubTypeId
      );
    }

    return this.submissionService
      .saveIntakeDetailsCommonData(commonSectionData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: any) => {},
        error: (err) => {
          this.progressIndicator.hide();
          console.error('Error saving data', err);
        },
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['dataOpportunityId'] ||
      changes['dataSubmissionId'] ||
      changes['submissionTypeCode']
    ) {
      this.submissionService.updateOtherSubmissionValue('');
      this.submissionService.otherValue$.subscribe((value) => {
        this.otherSubmissionValueSelected = value;
      });
      this.isArchiveAndCompleteBtnDisabled = true;
      this.isResubmitForReviewBtnDisabled = true;
      this.showInformationalGuidanceForDeliverable = false;
      this.showSubmitBtnWhnDraft = false;
      this.loadSubmission();
      this.getLabelConfiguration();
      if (this.submissionTypeCode === IntakeConstant.CHANGE_ORDER_LABEL_CODE) {
        this.getChangeOrderStatus();
      }
      this.showResetActionMessage = false;
    }
  }

  checkValidation(): void {
    this.valueChange.emit(this.checkValidationOfForm());
  }

  //selecting row of the riskmanager table
  toggleRow(rowEmail: string): void {
    if (rowEmail) {
      let reviewerIndex = this.updatedRiskManagerDetails.findIndex(
        (approver) => approver.emailId === rowEmail
      );
      this.updatedRiskManagerDetails[reviewerIndex].isRequired =
        !this.updatedRiskManagerDetails[reviewerIndex].isRequired;
      if (this.updatedRiskManagerDetails[reviewerIndex].isRequired) {
        this.pushInSelectedRiskManagers(
          this.updatedRiskManagerDetails[reviewerIndex].emailId
        );
      } else {
        this.selectedRiskManagers = this.selectedRiskManagers.filter(
          (element) =>
            element != this.updatedRiskManagerDetails[reviewerIndex].emailId
        );
      }
      this.#updateReviewers();
    }
  }

  #updateReviewers() {
    this.progressIndicator.show();

    const reviewerSet = new Set();
    let updatedArr = structuredClone(this.updatedRiskManagerDetails);
    updatedArr = updatedArr
      .filter(
        (reviewer) =>
          reviewer.emailId || reviewer.fullName || reviewer.displayName
      )
      // Filtering and adding in set to avoid data duplication based on emailid
      .filter((reviewer) => {
        const identifier = `${reviewer.emailId?.toLowerCase()}`;
        if (reviewerSet.has(identifier)) {
          return false;
        } else {
          reviewerSet.add(identifier);
          return true;
        }
      });

    updatedArr.forEach((reviewer) => {
      delete reviewer.isEditing;
      delete reviewer.isEditable;
      delete reviewer.isForTechnicalReviewer;
    });

    this.submissionService
      .updateSubmissionReviewers(updatedArr, Number(this.submissionId))
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: any) => {
          // do operation post updating the reviewers details
          if (response?.isSuccess) {
            this.submissionService
              .getSubmissionReviewers(this.submissionId.toString())
              .subscribe((response: SubmissionApprovers[]) => {
                if (response.length > 0) {
                  this.setWorkflowReviewersDetails([], response);
                  this.checkValidation();
                  this.progressIndicator.hide();
                }
              });
          }
        },
        error: (err) => {
          console.error('Error updating submission', err);
          this.progressIndicator.hide();
        },
      });
  }

  addReviewer(roleName: string): void {
    // this.isAllReviewerValid = roleName === this.roleTechnicalOrIndependentRM;
    if (
      this.checkUserAccess('AddReviewer') && // TODO: Check the role to add technical reviewer
      !this.disableFieldOnComplete() &&
      !this.isWorkflowDisabled()
    ) {
      const newReviewer: SubmissionApprovers = {
        submissionApproverId: 0, // 0 indicates a new reviewer to be saved
        reviewerId: randomString(20), // Unique identifier
        displayName: '',
        fullName: '',
        emailId: '',
        location: '',
        reviewerRoleId: Number(
          this.roles.roles.find(
            (item: ReviewerRoleType) => item.role === roleName
          )?.id
        ), // Assign and increment reviewerRoleId
        isRequired: false, // Assuming all additional reviewers are required; adjust as needed
        roleName: roleName,
        isEditing: true, // Start in editing mode to show People Picker,
        isEditable: true,
        isForTechnicalReviewer: roleName === this.roleTechnicalOrIndependentRM,
        submissionApproverStatus: null,
        submissionApproverDocumentId: null,
        statusReviewedDate: null,
      };

      let addReviewerPresent;
      if (roleName === this.roleAdditionalReviewer) {
        // Only checking for reviewer role  as technical check is present at caller function
        addReviewerPresent = this.updatedRiskManagerDetails.some(
          (reviewer) =>
            reviewer.roleName === this.roleAdditionalReviewer &&
            reviewer.submissionApproverId === 0
        );
      }
      if (!addReviewerPresent) {
        this.updatedRiskManagerDetails.push(newReviewer);
      }
    }
  }

  onMemberSelected(
    reviewerEmail: string,
    reviewerId: number | string,
    member: any
  ): void {
    let reviewerIndex = -1;

    if (
      reviewerId &&
      reviewerId != undefined &&
      reviewerId != null &&
      reviewerId != ''
    ) {
      reviewerIndex = this.updatedRiskManagerDetails.findIndex(
        (r) => r.reviewerId === reviewerId
      );
    } else {
      reviewerIndex = this.updatedRiskManagerDetails.findIndex(
        (r) => r.emailId === reviewerEmail
      );
    }
    if (reviewerIndex > -1) {
      this.isAllReviewerValid = true;
      this.updatedRiskManagerDetails[reviewerIndex].isEditing = false;
      this.updatedRiskManagerDetails[reviewerIndex].displayName =
        member.displayName;
      this.updatedRiskManagerDetails[reviewerIndex].fullName = member.fullname;
      this.updatedRiskManagerDetails[reviewerIndex].emailId = member.email;
      this.updatedRiskManagerDetails[reviewerIndex].location = member.location;
      this.updatedRiskManagerDetails[reviewerIndex].reviewerId =
        member?.reviewerId ?? '';
      this.#updateReviewers();
    }
  }

  editReviewer(reviewerEmailId: string, submissionApproverId: number): void {
    if (!this.isWorkflowDisabled()) {
      let editReviewerIndex = this.updatedRiskManagerDetails.findIndex(
        (reviewer) => reviewer.emailId && reviewer.emailId === reviewerEmailId
      );
      // If email not present in the list, then check with submissionApproverId if present
      if (editReviewerIndex < 0 && submissionApproverId > -1) {
        editReviewerIndex = this.updatedRiskManagerDetails.findIndex(
          (reviewer) =>
            reviewer.submissionApproverId &&
            reviewer.submissionApproverId === submissionApproverId
        );
      }
      if (editReviewerIndex > -1) {
        this.updatedRiskManagerDetails[editReviewerIndex].isEditing = true;
        this.updatedRiskManagerDetails[editReviewerIndex].displayName = '';
        this.updatedRiskManagerDetails[editReviewerIndex].fullName = '';
        this.updatedRiskManagerDetails[editReviewerIndex].emailId = '';
        this.updatedRiskManagerDetails[editReviewerIndex].location = '';
        this.updatedRiskManagerDetails[editReviewerIndex].reviewerId =
          randomString(20);
      }
    }
  }

  //save on clicking outside of the input box
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (this.isEditingTitle && !target.closest('#title-editor')) {
      this.saveTitle();
    }
  }

  //initiate title editing
  editTitle(): void {
    this.isEditingTitle = true;
    this.model.submissionTitle = this.titleValue;
  }

  //save the title and update header
  saveTitle(): void {
    this.titleValue = this.model.submissionTitle;
    this.isEditingTitle = false;
    this.updateTitle.emit({
      submissionId: this.dataSubmissionId,
      updatedTitle: this.titleValue,
    });
  }

  //copy the ID to clipboard
  copyToClipboard(value: string | null): void {
    if (value) {
      copyToClipboard(value);
    }
  }

  loadSubmission(): void {
    this.progressIndicator.show();
    this.roleService
      .getReviewerRolesDetails()
      .subscribe((response: ReviewerRole) => {
        this.roles = response;
      });
    const submissionId: string =
      this.dataSubmissionId !== null ? this.dataSubmissionId.toString() : '';
    this.employeeIdNumber = Number(this.submissionService.employeeId);
    this.SubmissionActionHistoryForAdminComplete(submissionId);
    this.submissionService.changeSubmissionId({
      opportunitynumber: this.dataOpportunityId,
      submissionid: this.dataSubmissionId,
    });
    if (submissionId !== '') {
      this.isOpportunitySelected = false;
      this.updatedRiskManagerDetails = [];
      this.selectedRiskManagers = [];
      this.coHasParentOpportunityNumber = false;
      forkJoin([
        this.submissionService.getSubmissionBySubmissionId(
          this.dataOpportunityId,
          submissionId
        ),
        this.submissionService.getSubmissionReviewers(submissionId),
      ])
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: any) => {
            const submissionDetails = response[0];
            const reviewerDetailsFromReviewerApi = response[1];

            if (Object.keys(submissionDetails).length !== 0) {
              this.submissionData = { ...submissionDetails };
              this.submissionTypeId = Number(
                this.submissionData.submissionTypeId
              );
              this.submissionStatusId = Number(
                this.submissionData.submissionStatusId
              );
              this.titleValue = this.submissionData.submissionTitle;
              this.submissionId = this.submissionData.submissionId;
              this.lepAcknowledgedBy = this.submissionData.lepAcknowledgedBy;
              this.lepAcknowledgementDate =
                this.submissionData.lepAcknowledgementDate;
              this.lepReviewedBy = this.submissionData.lepReviewedBy;
              this.submissionStatus = this.submissionData.submissionStatusName;
              this.archiveDetails = {
                archiveName: this.submissionData.archiveName,
                archiveNumber: this.submissionData.archiveNumber,
                archiveDescription: this.submissionData.archiveDescription,
              };
              this.swiftDetails = {
                projectName: this.submissionData.swiftDetails?.projectName,
                projectStartDate:
                  this.submissionData.swiftDetails?.projectStartDate,
                projectEndDate:
                  this.submissionData.swiftDetails?.projectEndDate,
                projectPPMD: this.submissionData.swiftDetails?.projectPPMD,
              };
              if (
                this.lepAcknowledgedBy === null &&
                this.lepAcknowledgementDate === null &&
                this.lepReviewedBy === null
              ) {
                this.showLepConfirmationMessage = true;
              } else {
                this.showLepConfirmationMessage = false;
              }

              if (
                this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.OTH
              ) {
                this.model.submissionSubTypeId =
                  this.submissionData.submissionSubTypeId?.toString() ?? '';
              }

              if (
                this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.CO
              ) {
                this.checkCOhasParentOpportunityNoOrNot();
              }
              this.selfReviewAllOperationSet();
              this.submissionService.setSelectedOpportunityDocStatus(
                (this.submissionStatus as IntakeOppertunitySubmissionStatus) ??
                  null
              );

              if (
                this.submissionStatus ===
                  IntakeOppertunitySubmissionStatus.SUBMITTED ||
                this.submissionStatus ===
                  IntakeOppertunitySubmissionStatus.RESUBMITTED
              ) {
                this.showReviewAndReturnBtn = true;
              } else {
                this.showReviewAndReturnBtn = false;
              }
              this.selfReviewEligibility =
                this.submissionData.selfReviewEligibilityStatus;
              this.model.coChangeScope =
                IntakeConstant.BOOLEAN_CHECKBOX_VALUE_TO_YES_NO.get(
                  this.submissionData.coChangeScope
                );
              this.model.coDescription = this.submissionData.coDescription;
              this.model.submissionTitle = this.submissionData.submissionTitle;
              this.model.otherDescription =
                this.submissionData.otherDescription;
              this.model.proposalDescription =
                this.submissionData.proposalDescription;
              this.model.relatedToRFP =
                IntakeConstant.BOOLEAN_CHECKBOX_VALUE_TO_YES_NO.get(
                  this.submissionData.relatedToRFP
                );
              this.model.delDescription = this.submissionData.delDescription;
              this.model.notesAndFeedback =
                this.submissionData.notesAndFeedback;
              this.model.internalQRMTeamCommunication =
                this.submissionData.internalQRMTeamCommunication;
              this.model.winningProposal = this.submissionData.winningProposal;
              this.isEligibleTechnicalReviewer =
                this.submissionData.isEligibleTechnicalReviewer;
              this.model.wbsChargeCode = this.submissionData.wbsChargeCode;
              this.model.wbsDescription = this.submissionData.wbsDescription;
              this.showCloseOutForm = false;
              setTimeout(() => {
                this.showCloseOutForm = true;
              }, 100);
              this.setWorkflowReviewersDetails(
                this.submissionData.submissionApprovers,
                reviewerDetailsFromReviewerApi
              );
              this.optOutOfSelfReviewReason =
                submissionDetails?.reasonForOptOutSelfReview;
              if (
                this.optOutOfSelfReviewReason &&
                this.submissionStatus ===
                  IntakeOppertunitySubmissionStatus.DRAFT
              ) {
                this.showSubmitBtnWhnDraft = true;
              } else {
                this.showSubmitBtnWhnDraft = false;
              }
              // --------------- close out change order section start ----------------
              this.setCoElSowCloseOutDetails(this.submissionData);
              // --------------- close out change order section end ----------------

              // TODO: Once Employee ID is implemented
              // this.checkActionsColumn();

              this.isEmployeePresent = this.updatedRiskManagerDetails.some(
                (employee: SubmissionApprovers) =>
                  Number(employee.reviewerId) === this.employeeIdNumber
              );

              this.submissionService.enableAdminComplete(
                this.isStatusSelfReviewedPendingCloseoutOrRmReviewedPendingCloseout()
              );
              this.commonService.enableResetStatus(
                this.showResetStatusDropdown()
              );
              this.submissionService.disableDocumentButtonOnAdminComplte(
                this.checkStatusCompletedByAdmin()
              );
            }
            this.model.updatedVersionForReview =
              submissionDetails.updatedVersionForReview;
            this.otherSubmissionValueSelected =
              submissionDetails.otherUpdatedVersionForReview;
            this.progressIndicator.hide();

            this.getUploadedDocumentData();
            this.#updateReviewers();
          },
          error: (err) => {
            this.progressIndicator.hide();
            console.error('Error fetching submission', err);
          },
        });
    } else {
      this.isOpportunitySelected = true;
    }
  }

  isProposalAccordionOpen(): boolean {
    return this.isAccordianOpenCommon();
  }

  isDeliverableAccordionOpen(): boolean {
    return this.isAccordianOpenCommon();
  }

  isOtherAccordionOpen(): boolean {
    return this.isAccordianOpenCommon();
  }

  isCOAccordionOpen(): boolean {
    return this.isAccordianOpenCommon();
  }

  isAccordianOpenCommon(): boolean {
    return (
      this.submissionStatus != '' &&
      this.submissionStatus !==
        IntakeOppertunitySubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT &&
      this.submissionStatus !==
        IntakeOppertunitySubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT
    );
  }

  isAccordionVisible(
    submissionCodeType: IntakeDocumentSubmissionTypeCode
  ): boolean {
    return (
      this.submissionTypeCode != '' &&
      this.submissionTypeCode === submissionCodeType
    );
  }

  isCloseOutAccordionVisible(): boolean {
    return (
      this.submissionStatus != '' &&
      this.isStatusSelfReviewedPendingCloseoutOrRmReviewedPendingCloseout()
    );
  }

  proposalDescriptionValueChanged(): void {
    this.checkValidation();
  }

  RelatedtoRFPstateChanged(): void {
    this.checkValidation();
  }

  deliverableDescValueChanged(): void {
    this.checkValidation();
  }

  internalQrmCommunicationInputValueChanged(): void {}
  feedbackInputValueChanged(): void {}
  OtherDescriptionValueChanged(): void {
    this.checkValidation();
  }

  COChangeScopeStateChanged(): void {
    if (this.submissionTypeCode === this.intakeDocumentSubmissionTypeCodeCO) {
      this.checkValidation();
    }
    //TODO: 4000 is the timeout value, need to check if it can be reduced or logic can be replaced
    setTimeout(() => {
      this.submissionService
        .getSubmissionBySubmissionId(
          this.dataOpportunityId,
          this.submissionId.toString()
        )
        .subscribe({
          next: (
            response: IntakeSubmissionDetailsCommonSectionDataReceivedModal
          ) => {
            this.submissionData = { ...response };
            this.selfReviewEligibility =
              this.submissionData.selfReviewEligibilityStatus;
            this.isSelfReviewButtonVisible =
              this.selfReviewEligibility == 'Yes' ? true : false;
          },
          error: (err) => {
            console.error('Error fetching submission', err);
          },
        });
    }, IntakeConstant.TIMEOUT_TIMER_BIGGEST);
  }

  CODescriptionValueChanged(): void {
    this.checkValidation();
  }

  clickToShowDocumentMoreLepMessage(): void {
    this.isShowMoreDocumentNotes = !this.isShowMoreDocumentNotes;
    if (!this.isShowMoreDocumentNotes) {
      this.showMoreText = IntakeConstant.SHOW_MORE_TEXT;
      this.showMoreOptions.icon = IntakeConstant.DDS_ICON_ARROW_DOWN;
    } else {
      this.showMoreText = IntakeConstant.SHOW_LESS_TEXT;
      this.showMoreOptions.icon = IntakeConstant.DDS_ICON_ARROW_UP;
    }
  }

  checkCOhasParentOpportunityNoOrNot(): void {
    this.submissionService
      .getOpportunitySelected(this.dataOpportunityId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: latestOpportunityDetails) => {
          this.coHasParentOpportunityNumber =
            (data?.parentOpportunityNumber == null ||
              data?.parentOpportunityNumber == '') &&
            this.opportunityDocsCount === 1;
          this.isCheckboxCheckedForNoEL_SOW = this.coHasParentOpportunityNumber;
        },
        error: (err) => {
          console.error('Error fetching submission', err);
        },
      });
  }

  handleCompleteReview(data: SubmissionApprovers): void {
    this.openModal(data);
  }

  handleReturnforRework(data: SubmissionApprovers): void {
    const submissionStatusId = this.getSubmissionStatusCode(
      IntakeOppertunitySubmissionStatus.RETURN_FOR_REWORK
    );
    if (submissionStatusId) {
      this.progressIndicator.show();
      this.submissionService
        .submitCommonSectionData(this.submissionId, submissionStatusId)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: string) => {
            this.submissionStatusCommonSection = response;
          },
          error: (err) => {
            console.error('An error occurred during submission: ', err);
          },
        });
      this.timeoutId = setTimeout(() => {
        this.loadSubmission();
        this.documentComponent.triggerDocumentTableDataApi();
        this.updateLeftMenu.emit(this.opportunityNumber);
        this.progressIndicator.hide();
        this.setToast(this.returnForReworkToastOption);
      }, IntakeConstant.SET_TIMEOUT_DURATION);
    }
  }

  resubmitCommonSectionData(): void {
    const submissionStatusId = this.getSubmissionStatusCode(
      IntakeOppertunitySubmissionStatus.RESUBMITTED
    );
    if (submissionStatusId) {
      this.progressIndicator.show();
      this.submissionService
        .submitCommonSectionData(this.submissionId, submissionStatusId)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: string) => {
            this.submissionStatusCommonSection = response;
          },
          error: (err) => {
            console.error('An error occurred during submission: ', err);
          },
        });
      this.timeoutId = setTimeout(() => {
        this.loadSubmission();
        this.documentComponent.triggerDocumentTableDataApi();
        this.updateLeftMenu.emit(this.opportunityNumber);
        this.progressIndicator.hide();
        this.setToast(this.resubmittedToastOption);
      }, IntakeConstant.SET_TIMEOUT_DURATION);
    }
  }

  openModal(rowDetails: SubmissionApprovers): void {
    let modalRef = this.modalService.open(DocumentSelectionPopupComponent, {
      submissionID: this.submissionId,
      rowDetails: rowDetails,
    });
    modalRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (showToastMessage: boolean) => {
          if (this.submissionId) {
            this.timeoutId = setTimeout(() => {
              this.loadSubmission();
              this.documentComponent.triggerDocumentTableDataApi();
              this.updateLeftMenu.emit(this.opportunityNumber);
              if (showToastMessage) {
                this.setToast(this.toastOptions);
              }
            }, IntakeConstant.SET_TIMEOUT_DURATION);
          }
        },
        error: (err) => {
          console.error('An error occurred during opening modal: ', err);
        },
      });
  }

  setWorkflowReviewersDetails(
    reviewerDetailsFromSubmissionDetailsApi: SubmissionApprovers[],
    reviewerDetailsFromGetReviewerApi: SubmissionApprovers[]
  ): void {
    this.updatedRiskManagerDetails = [];
    this.selectedRiskManagers = [];

    const reviewerDetails =
      reviewerDetailsFromGetReviewerApi.length > 0
        ? reviewerDetailsFromGetReviewerApi
        : reviewerDetailsFromSubmissionDetailsApi ?? [];

    reviewerDetails.forEach((element) => {
      const findUserDetailsFromSumissionApi: SubmissionApprovers[] =
        this.findUserByEmailFromResponse(element.emailId);

      if (element.isRequired) {
        this.pushInSelectedRiskManagers(element.emailId);
      }

      const existingReviewerIndex = this.updatedRiskManagerDetails.findIndex(
        (reviewer: SubmissionApprovers) => reviewer.emailId === element.emailId
      );

      // If reviewer is not present in the list, then add it
      if (existingReviewerIndex === -1) {
        this.updatedRiskManagerDetails.push({
          ...element,
          isRequired: element.isRequired ?? false,
          reviewerId: this.getReviewerId(
            element.reviewerId,
            findUserDetailsFromSumissionApi[0]?.reviewerId
          ),
          isEditing: false,
          isEditable:
            element.roleName === this.roleRiskManager ||
            element.roleName === this.roleSupportingRiskManager ||
            element.roleName === this.roleSpecializedRiskManager
              ? false
              : true,
          isForTechnicalReviewer:
            element.roleName === this.roleTechnicalOrIndependentRM,
        });
      }
    });

    const technicalPresent = this.updatedRiskManagerDetails.some(
      (reviewer) => reviewer.roleName === this.roleTechnicalOrIndependentRM
    );

    if (this.isEligibleTechnicalReviewer && !technicalPresent) {
      this.addReviewer(this.roleTechnicalOrIndependentRM);
    }
  }

  pushInSelectedRiskManagers(emailId: string): void {
    if (!this.selectedRiskManagers.includes(emailId)) {
      this.selectedRiskManagers.push(emailId);
    }
  }

  getReviewerId(
    reviewerIdFromGetReviewerApi: string | number,
    reviewerIdFromGetSubmissionApi: string | number
  ): string | number {
    if (
      reviewerIdFromGetReviewerApi &&
      reviewerIdFromGetReviewerApi != null &&
      reviewerIdFromGetReviewerApi != ''
    ) {
      return reviewerIdFromGetReviewerApi;
    } else if (
      reviewerIdFromGetSubmissionApi &&
      reviewerIdFromGetSubmissionApi != null &&
      reviewerIdFromGetSubmissionApi != ''
    ) {
      return reviewerIdFromGetSubmissionApi;
    } else {
      return '';
    }
  }

  findUserByEmailFromResponse(reviewerMaiId: string): SubmissionApprovers[] {
    const submissionReviewerFromSubmissionDetailsApi = [
      ...this.submissionData.submissionApprovers,
    ];
    let submissionReviewer = submissionReviewerFromSubmissionDetailsApi.filter(
      (user: SubmissionApprovers) => user.emailId === reviewerMaiId
    );
    return submissionReviewer.length > 0 ? submissionReviewer : [];
  }

  handleLEPCompletion(): void {
    this.submissionService
      .putLepStatus(this.dataSubmissionId, this.dataLepValue.lepReviewedBy)
      .toPromise()
      .then(() => {});
    this.timeoutId = setTimeout(() => {
      this.loadSubmission();
    }, IntakeConstant.SET_TIMEOUT_DURATION);
  }

  getLabelConfiguration(): void {
    this.submissionService
      .getIntakeConfigurations()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: configurationType[]) => {
          if (response) {
            this.configData = response;
            if (
              this.submissionTypeCode === IntakeConstant.CHANGE_ORDER_LABEL_CODE
            ) {
              this.configLableDocumet =
                this.configData.find(
                  (config: configurationType) =>
                    config.appConfigurationLabelKey === 'UPLOAD_CO_DOCUMENTS'
                )?.appConfigurationLabelValue || '';
            } else {
              this.configLableDocumet =
                this.configData.find(
                  (config: configurationType) =>
                    config.appConfigurationLabelKey === 'UPLOAD_DOCUMENTS'
                )?.appConfigurationLabelValue || '';
            }
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  updateSubmitButtonStatus(): boolean {
    if (
      (this.changeOrderStatus &&
        this.model.coChangeScope &&
        this.model.coDescription &&
        !this.showLepConfirmationMessage) ||
      (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.CO &&
        this.model.coChangeScope &&
        this.model.coDescription &&
        this.isValidDocumentUploaded &&
        !this.showLepConfirmationMessage) ||
      (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.DEL &&
        this.model.delDescription &&
        this.isValidDocumentUploaded &&
        !this.showLepConfirmationMessage) ||
      (this.isValidDocumentUploaded &&
        this.model.otherDescription &&
        this.model.submissionSubTypeId &&
        IntakeDocumentSubmissionTypeCode.OTH) ||
      (this.isValidDocumentUploaded &&
        this.model.relatedToRFP &&
        this.model.proposalDescription &&
        !this.showLepConfirmationMessage) ||
      (this.isValidDocumentUploaded &&
        this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.EL_SOW &&
        !this.showLepConfirmationMessage)
    ) {
      return false;
    }
    return true;
  }

  submitCommonSectionData(statusCode: IntakeOppertunitySubmissionStatus): void {
    const submittedubmissionStatusID = this.getSubmissionStatusCode(statusCode);
    if (submittedubmissionStatusID) {
      this.submissionService
        .submitCommonSectionData(this.submissionId, submittedubmissionStatusID)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: string) => {
            this.submissionStatusCommonSection = response;
            if (response) {
              this.updateAfterSubmit();
            }
          },
          error: (error) => {
            this.updateAfterSubmit();
          },
        });
    }
  }

  updateAfterSubmit(): void {
    this.setToast(this.submittedToastOption);
    this.loadSubmission();
    this.updateLeftMenu.emit(this.opportunityNumber);
  }

  isSubmitBtnVisible(): boolean {
    return (
      (this.submissionStatus === IntakeOppertunitySubmissionStatus.DRAFT &&
        this.selfReviewEligibility != IntakeConstant.YES) ||
      this.showSubmitBtnWhnDraft
    );
  }

  isResubmitBtnVisible(): boolean {
    return (
      this.submissionStatus ===
      IntakeOppertunitySubmissionStatus.RETURN_FOR_REWORK
    );
  }

  isSubmitForReviewBtnVisible(): boolean {
    return this.isStatusSelfReviewedPendingCloseoutOrRmReviewedPendingCloseout();
  }

  isArchiveAndCompleteBtnVisible(): boolean {
    return this.isStatusSelfReviewedPendingCloseoutOrRmReviewedPendingCloseout();
  }

  isStatusSelfReviewedPendingCloseoutOrRmReviewedPendingCloseout(): boolean {
    return (
      this.isSelfReviewPendingCloseout() || this.isRMReviewPendingCloseout()
    );
  }

  isSelfReviewPendingCloseout(): boolean {
    return (
      this.submissionStatus ===
      IntakeOppertunitySubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT
    );
  }

  isRMReviewPendingCloseout(): boolean {
    return (
      this.submissionStatus ===
      IntakeOppertunitySubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT
    );
  }

  showResetStatusDropdown(): boolean {
    return (
      this.isStatusCompleted() ||
      this.submissionStatus === IntakeOppertunitySubmissionStatus.DISCONTINUED
    );
  }

  onclickArchiveAndCompleteBtn(): void {
    const submissionStatusId = this.getSubmissionStatusCode(
      IntakeOppertunitySubmissionStatus.COMPLETED
    );
    this.progressIndicator.show();
    this.documentService
      .getDocumentArchiveId(this.submissionId, this.dataOpportunityId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: ArchiveDocument) => {
          const documentArchiveId: number = data?.archiveDocumentId;
          if (
            documentArchiveId &&
            documentArchiveId != undefined &&
            documentArchiveId != null &&
            submissionStatusId
          ) {
            this.submissionService
              .submitCommonSectionData(
                this.submissionId,
                submissionStatusId,
                documentArchiveId
              )
              .pipe(takeUntil(this.unsubscriber$))
              .subscribe({
                next: (response: string) => {
                  this.submissionStatusCommonSection = response;
                },
                error: (err: any) => {
                  console.error(
                    'An error occurred updating document status as completed with archiveid : ',
                    err
                  );
                },
              });

            this.timeoutId = setTimeout(() => {
              this.loadSubmission();
              this.updateLeftMenu.emit(this.opportunityNumber);
              this.progressIndicator.hide();
            }, IntakeConstant.SET_TIMEOUT_DURATION);
          }
        },
        error: (err: any) => {
          console.error('An error occurred getting document archive id: ', err);
        },
      });
  }

  onclickResubmitForReviewBtn(): void {
    const submissionStatusId = this.getSubmissionStatusCode(
      IntakeOppertunitySubmissionStatus.RESUBMITTED
    );
    if (submissionStatusId) {
      this.progressIndicator.show();
      this.submissionService
        .submitCommonSectionData(this.submissionId, submissionStatusId)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: string) => {
            this.submissionStatusCommonSection = response;
          },
        });
      this.timeoutId = setTimeout(() => {
        this.loadSubmission();
        this.updateLeftMenu.emit(this.opportunityNumber);
        this.progressIndicator.hide();
      }, IntakeConstant.SET_TIMEOUT_DURATION);
    }
  }

  onChangeWinningProposalInCloseOut(
    winningProposalValue: string,
    calledFrom: number
  ): void {
    this.model.winningProposal = winningProposalValue;
    this.modalSetting = null;
    this.showUpdatedDocumentMessage = false;
    this.isDocumentRequireMsgShow = false;
    if (calledFrom === 0) {
      this.documentComponent.triggerDocumentTableDataApi();
    }
    if (this.isStatusSelfReviewedPendingCloseoutOrRmReviewedPendingCloseout()) {
      if (winningProposalValue === WinningProposalOptionValues.YES) {
        this.progressiveStatus = true;
        this.isDocumentRequireMsgShow = true;

        const showArchiveButton = this.checkDocumentStatus(
          IntakeConstant.FINAL_ARCHIVE_READY_VERSION
        );
        if (showArchiveButton) {
          this.isArchiveAndCompleteBtnDisabled = false;
          this.isResubmitForReviewBtnDisabled = true;
          this.isFinalDocumentAcceptedMsgShow = false;
        } else {
          this.isFinalDocumentAcceptedMsgShow = true;
          this.isArchiveAndCompleteBtnDisabled = true;
          this.isResubmitForReviewBtnDisabled = true;
        }
      } else if (
        winningProposalValue ===
        WinningProposalOptionValues.NO_OPPORTUNITY_LOST_DISCONTINUE_ABANDONED
      ) {
        this.modalSetting = {
          selectedOption:
            WinningProposalOptionValues.NO_OPPORTUNITY_LOST_DISCONTINUE_ABANDONED,
        };
        this.#closeOutModalOpen();
      } else if (
        winningProposalValue ===
        WinningProposalOptionValues.NO_RESUBMIT_FOR_REVIEW
      ) {
        this.progressiveStatus = false;
        this.isArchiveAndCompleteBtnDisabled = true;
        this.setDraftFlags();
      } else {
        this.model.winningProposal = null;
      }
    }
    this.updateCloseoutBanner();
  }

  onChangeOtherInCloseOut(otherValue: string, calledFrom: number): void {
    this.model.otherUpdatedVersionForReview = otherValue;
    this.modalSetting = null;
    if (calledFrom === 0) {
      this.documentComponent.triggerDocumentTableDataApi();
    }
    if (this.isStatusSelfReviewedPendingCloseoutOrRmReviewedPendingCloseout()) {
      if (otherValue === OtherOptionValues.YES) {
        this.submissionService.updateOtherSubmissionValue(
          OtherOptionValues.YES
        );
        const showArchiveButton = this.checkDocumentStatus(
          IntakeConstant.FINAL_ARCHIVE_READY_VERSION
        );
        showArchiveButton
          ? (this.showUploadFinalVersionMessage = false)
          : (this.showUploadFinalVersionMessage = true);
        this.isArchiveAndCompleteBtnDisabled = !showArchiveButton;
        this.showUpdatedDocumentMessage = false;
        this.isResubmitForReviewBtnDisabled = true;
      } else if (otherValue === OtherOptionValues.NO_RESUBMIT_FOR_REVIEW) {
        this.submissionService.updateOtherSubmissionValue(
          OtherOptionValues.NO_RESUBMIT_FOR_REVIEW
        );
        const checkdraft = this.checkDocumentStatus(
          IntakeOppertunitySubmissionStatus.DRAFT
        );
        checkdraft
          ? (this.showUpdatedDocumentMessage = false)
          : (this.showUpdatedDocumentMessage = true);
        this.isArchiveAndCompleteBtnDisabled = true;
        checkdraft
          ? (this.isResubmitForReviewBtnDisabled = false)
          : (this.isResubmitForReviewBtnDisabled = true);
        this.showUploadFinalVersionMessage = false;
      } else if (
        otherValue === OtherOptionValues.NO_DOCUMENT_WILL_NOT_BE_EXECUTED
      ) {
        this.submissionService.updateOtherSubmissionValue(
          OtherOptionValues.NO_DOCUMENT_WILL_NOT_BE_EXECUTED
        );
        this.modalSetting = {
          selectedOption: OtherOptionValues.NO_DOCUMENT_WILL_NOT_BE_EXECUTED,
        };
        this.isArchiveAndCompleteBtnDisabled = true;
        this.isResubmitForReviewBtnDisabled = true;
        this.showUploadFinalVersionMessage = false;
        this.showUpdatedDocumentMessage = false;
        this.#closeOutModalOpen();
      } else {
        this.model.otherUpdatedVersionForReview = null;
        this.isArchiveAndCompleteBtnDisabled = true;
        this.isResubmitForReviewBtnDisabled = true;
        this.showUploadFinalVersionMessage = false;
        this.showUpdatedDocumentMessage = false;
      }
    }
  }

  onChangeDeliverableInCloseOut(
    deliverableValue: string,
    callFrom: number
  ): void {
    this.model.updatedVersionForReview = deliverableValue;
    this.modalSetting = null;
    if (callFrom === 0) {
      this.documentComponent.triggerDocumentTableDataApi();
    }
    if (deliverableValue === DeliverableOptionValues.YES_RESUBMIT_FOR_REVIEW) {
      const showResubmitButton = this.checkDocumentStatus(
        IntakeOppertunitySubmissionStatus.DRAFT
      );
      showResubmitButton
        ? (this.showUpdatedDocumentMessage = false)
        : (this.showUpdatedDocumentMessage = true);
      this.isResubmitForReviewBtnDisabled = !showResubmitButton;

      this.showInformationalGuidanceForDeliverable = false;
      this.isArchiveAndCompleteBtnDisabled = true;
    } else if (deliverableValue === DeliverableOptionValues.NO) {
      const showArchiveButton = this.checkDocumentStatus(
        IntakeConstant.FINAL_ARCHIVE_READY_VERSION
      );
      showArchiveButton
        ? (this.showInformationalGuidanceForDeliverable = false)
        : (this.showInformationalGuidanceForDeliverable = true);
      this.isArchiveAndCompleteBtnDisabled = !showArchiveButton;
      this.isResubmitForReviewBtnDisabled = true;
      this.showUpdatedDocumentMessage = false;
    } else {
      this.model.updatedVersionForReview = null;
      this.isArchiveAndCompleteBtnDisabled = true;
      this.isResubmitForReviewBtnDisabled = true;
      this.showUpdatedDocumentMessage = false;
      this.showInformationalGuidanceForDeliverable = false;
    }
  }

  async onChangeCoAndElSowCloseOut(
    coElSowWithWdsValue: CoElSowEventEmitterType,
    callingFrom: number
  ): Promise<void> {
    let selectedRadioValue = coElSowWithWdsValue?.contractSignedExecuted;
    this.model.contractSignedExecuted =
      coElSowWithWdsValue?.contractSignedExecuted;
    this.model.wbsChargeCode = coElSowWithWdsValue?.wbsChargeCode;
    this.model.numberOfOpenArchieve = coElSowWithWdsValue?.numberOfOpenArchieve;
    this.model.wbsDescription = coElSowWithWdsValue?.wbsDescription;
    this.modalSetting = null;
    if (this.isStatusSelfReviewedPendingCloseoutOrRmReviewedPendingCloseout()) {
      this.modalSetting = null;
      if (callingFrom === 0) {
        this.documentComponent?.triggerDocumentTableDataApi();
      }
      if (selectedRadioValue === CoElSowOptionValues.YES) {
        const showArchiveButton = await this.checkDocumentStatus(
          IntakeConstant.FINAL_ARCHIVE_READY_VERSION
        );

        this.showUploadGuidanceYellowMsgForCoAndElSowOnYes = !showArchiveButton;
        this.isArchiveAndCompleteBtnDisabled = !showArchiveButton;
        this.showUploadGuidanceInfoMsgForCoAndElSowOnYes = true;
        this.showInfoMsgForCoElSowOnSelectNoResubmit = false;
        this.isResubmitForReviewBtnDisabled = true;
      } else if (
        selectedRadioValue === CoElSowOptionValues.NO_RESUBMIT_FOR_REVIEW
      ) {
        this.showUploadGuidanceInfoMsgForCoAndElSowOnYes = false;

        this.showUploadGuidanceYellowMsgForCoAndElSowOnYes = false;
        this.isArchiveAndCompleteBtnDisabled = true;
        const isAnyDocumentStausIsDraft = await this.checkDocumentStatus(
          IntakeOppertunitySubmissionStatus.DRAFT
        );
        this.isResubmitForReviewBtnDisabled = !isAnyDocumentStausIsDraft;
        this.showInfoMsgForCoElSowOnSelectNoResubmit =
          !isAnyDocumentStausIsDraft;
      } else if (
        selectedRadioValue ===
        CoElSowOptionValues.NO_DOCUMENT_WILL_NOT_BE_EXECUTED
      ) {
        this.modalSetting = {
          selectedOption: selectedRadioValue,
        };
        this.isResubmitForReviewBtnDisabled = true;
        this.isArchiveAndCompleteBtnDisabled = true;
        this.showInfoMsgForCoElSowOnSelectNoResubmit = false;
        this.showUploadGuidanceInfoMsgForCoAndElSowOnYes = false;
        this.showUploadGuidanceYellowMsgForCoAndElSowOnYes = false;
        this.#closeOutModalOpen();
      }
    }
  }

  setCoElSowCloseOutDetails(
    submissionData: IntakeSubmissionDetailsCommonSectionDataReceivedModal
  ): void {
    this.model.contractSignedExecuted = submissionData.contractSignedExecuted;
    this.model.wbsChargeCode = submissionData.wbsChargeCode;
    this.model.numberOfOpenArchieve = submissionData.numberOfOpenArchieve;
    this.model.wbsDescription = submissionData.wbsDescription;
    const seletedCoElSow: CoElSowEventEmitterType = {
      contractSignedExecuted: submissionData.contractSignedExecuted,
      wbsChargeCode: submissionData.wbsChargeCode,
      numberOfOpenArchieve: submissionData.numberOfOpenArchieve,
      wbsDescription: submissionData.wbsDescription,
    };

    setTimeout(() => {
      this.onChangeCoAndElSowCloseOut(seletedCoElSow, 0);
    }, IntakeConstant.TIMOUT_TIMER);
  }

  handleDocumentTableData(data: IntakeDocumentTableModel[]): void {
    this.documentTableData = data;
    if (this.isStatusSelfReviewedPendingCloseoutOrRmReviewedPendingCloseout()) {
      if (
        this.submissionTypeCode === this.intakeDocumentSubmissionTypeCodePro
      ) {
        this.onChangeWinningProposalInCloseOut(
          this.model.winningProposal ?? '',
          1
        );
      } else if (
        this.submissionTypeCode === this.intakeDocumentSubmissionTypeCodeOther
      ) {
        this.onChangeOtherInCloseOut(
          this.model.otherUpdatedVersionForReview ?? '',
          1
        );
      } else if (
        this.submissionTypeCode === this.intakeDocumentSubmissionTypeCodeCO ||
        this.submissionTypeCode === this.intakeDocumentSubmissionTypeCodeELSOW
      ) {
        let existingCloseOutValue: CoElSowEventEmitterType = {
          wbsChargeCode: this.model.wbsChargeCode ?? null,
          numberOfOpenArchieve: this.model.numberOfOpenArchieve ?? null,
          contractSignedExecuted: this.model.contractSignedExecuted ?? null,
          wbsDescription: this.model.wbsDescription ?? null,
        };
        this.onChangeCoAndElSowCloseOut(existingCloseOutValue, 1);
      } else if (
        this.submissionTypeCode ===
        this.intakeDocumentSubmissionTypeCodeDelivery
      ) {
        this.onChangeDeliverableInCloseOut(
          this.model.updatedVersionForReview ?? '',
          1
        );
      }
    }
    this.updateCloseoutBanner();
  }

  setDraftFlags(): void {
    this.updateFlags(
      this.checkDocumentStatus(IntakeOppertunitySubmissionStatus.DRAFT),
      {
        isResubmitForReviewBtnDisabled: false,
        showUpdatedDocumentMessage: false,
      },
      { isResubmitForReviewBtnDisabled: true, showUpdatedDocumentMessage: true }
    );
  }

  isStatusCompletedOrAdminOrSubmittedorResubmittedOrDiscontinued(): boolean {
    return (
      this.isStatusCompleted() ||
      this.submissionStatus === IntakeOppertunitySubmissionStatus.SUBMITTED ||
      this.submissionStatus === IntakeOppertunitySubmissionStatus.RESUBMITTED ||
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.COMPLETED_ADMIN ||
      this.isDisContinued()
    );
  }

  isStatusCompleted(): boolean {
    return (
      this.submissionStatus === IntakeOppertunitySubmissionStatus.COMPLETED ||
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.COMPLETED_ADMIN ||
      this.submissionStatus === IntakeOppertunitySubmissionStatus.DISCONTINUED
    );
  }

  isDisContinued(): boolean {
    return (
      this.submissionStatus === IntakeOppertunitySubmissionStatus.DISCONTINUED
    );
  }

  updateFlags(
    condition: boolean,
    trueValues: {
      [key: string]: boolean;
    },
    falseValues: {
      [key: string]: boolean;
    }
  ): void {
    if (condition) {
      Object.keys(trueValues).forEach((key) => {
        (this as any)[key] = trueValues[key];
      });
    } else {
      Object.keys(falseValues).forEach((key) => {
        (this as any)[key] = falseValues[key];
      });
    }
  }

  checkDocumentStatus(status: string): boolean {
    return this.documentTableData.some((doc) => doc.documentStatus === status);
  }

  #closeOutModalOpen(modalBtn?: ButtonComponent): void {
    let closeOutModalRef = this.modalService.open(ConfirmPopModalComponent, {
      isFooter: true,
      size: IntakeConstant.MODAL_SIZE,
      isInverse: false,
      modalSetting: {
        ...this.modalSetting,
        selectedSubmissionType: this.submissionTypeCode,
      },
    });

    closeOutModalRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe((data: CloseOutModalModel) => {
        if (
          data !== null &&
          (data?.moveToCompleteOther ||
            data?.moveToCompleteProposal ||
            data?.moveToCompleteCoElSow)
        ) {
          this.onclickArchiveAndCompleteBtn();
        } else {
          this.model.winningProposal = null;
          this.model.contractSignedExecuted = null;
        }
        modalBtn?.focus();
      });
  }

  clearReviewer(
    emailId: string,
    isForTechnicalReviewer: boolean | undefined
  ): void {
    /*
      1. If the workflow is disabled, then the user cannot be removed.
      2. If the required managers length is > 1, then the user can be removed.
      3. If the required manager length is = 1 and user is not required, then the user can be removed.
      4. If the required manager length is = 0 and number of managers are greater than 1, then the user can be removed.
    */
    const canRemoveUser =
      !this.isWorkflowDisabled() &&
      (this.selectedRiskManagers.length > 1 ||
        (this.selectedRiskManagers.length === 1 &&
          !this.selectedRiskManagers.includes(emailId)) ||
        (this.selectedRiskManagers.length === 0 &&
          this.updatedRiskManagerDetails.length > 1));

    if (canRemoveUser) {
      const index: number = this.updatedRiskManagerDetails.findIndex(
        (reviewer) => reviewer.emailId === emailId
      );
      if (index !== -1) {
        this.updatedRiskManagerDetails[index]['isActive'] = false;

        const technicalPresent = this.updatedRiskManagerDetails.some(
          (reviewer) => reviewer.roleName === this.roleTechnicalOrIndependentRM
        );

        if (
          isForTechnicalReviewer &&
          this.isEligibleTechnicalReviewer &&
          !technicalPresent
        ) {
          this.addReviewer(this.roleTechnicalOrIndependentRM);
        }

        this.#updateReviewers();
      }
    }
  }

  clearUpdatedRiskManagers(): void {
    this.updatedRiskManagerDetails = [];
    this.selectedRiskManagers = [];
  }

  isEditingReviewer(row: SubmissionApprovers): boolean | undefined {
    const reviewerRoleID = this.roles.roles.find(
      (item: ReviewerRoleType) => item.role === this.roleAdditionalReviewer
    )?.id;
    return (
      row.isEditing &&
      row.reviewerRoleId === reviewerRoleID &&
      !row.isForTechnicalReviewer
    );
  }

  isEditingTechnicalReviewer(row: SubmissionApprovers): boolean | undefined {
    return (
      row.isEditing &&
      row.isForTechnicalReviewer && // if reviewer role is of technical reviewer
      this.isEligibleTechnicalReviewer && // if submission is eligible for technical reviewer
      this.isValidTechnicalReviewer // if user is eligible for technical reviewer
    );
  }

  openOptOutReasonModal(modalBtn?: any): void {
    let optOutModelRef = this.modalService.open(OptOutPopupComponent);
    optOutModelRef
      .onClosed()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: IntakeOptOutPopUpDataModel) => {
          if (data.submissionLevelUpdateSubmission) {
            this.model.reasonForOptOutSelfReview = data.optOutReason;
            this.optOutOfSelfReviewReason = data.optOutReason;
            this.isSelfReviewButtonVisible = false;
            this.showSubmitBtnWhnDraft =
              this.submissionStatus === IntakeOppertunitySubmissionStatus.DRAFT;
            this.#saveCommonSectionDetails();
            this.setToast(this.optedOutToastOption);
          }
        },
        error: (err) => {
          console.error('An error occurred during opening modal: ', err);
        },
      });
  }

  // TODO: Once Employee ID is implemented this needs to be checked
  // checkActionsColumn() {
  //   for (let i in this.updatedRiskManagerDetails) {
  //     if (
  //       this.loggedInEmployeeId ===
  //         this.updatedRiskManagerDetails[i].reviewerId &&
  //       this.submissionStatus === 'Submitted' &&
  //       this.updatedRiskManagerDetails[i].statusReviewedDate === null
  //     ) {
  //       this.showActionsHeader = true;
  //       break;
  //     } else {
  //       this.showActionsHeader = false;
  //     }
  //   }
  // }

  setToast(toast: any): void {
    this.toastService.createToast(toast);
  }

  onclickSelfReviewBtn(): void {
    this.submitCommonSectionData(this.docStausSelfReviewedPendingCloseout);
  }

  onclickOptOutOfSelfReviewBtn(): void {
    this.openOptOutReasonModal();
  }

  selfReviewAllOperationSet(): void {
    if (this.submissionData.selfReviewEligibilityStatus) {
      this.isSelfReviewButtonVisible = isSelfReviewButtonVisible(
        this.submissionData.selfReviewEligibilityStatus,
        this.submissionStatus as IntakeOppertunitySubmissionStatus | null,
        this.submissionData.reasonForOptOutSelfReview
      );
      if (this.isSelfReviewButtonVisible) {
        this.selfReviewDocBanner =
          this.configData.find(
            (config: configurationType) =>
              config.appConfigurationLabelKey === 'SELFREVIEW_UPLOAD_DOCUMENTS'
          )?.appConfigurationLabelValue || '';
      }
      return;
    }
    this.selfReviewDocBanner = IntakeConstant.EMPTY_SPACE;
  }

  transformStatusStyle(status: string | undefined): StatusEnum {
    if (status) {
      return (
        Object.values(StatusEnum).find((enumValue) => enumValue === status) ||
        StatusEnum.Draft
      );
    } else {
      return StatusEnum.Draft;
    }
  }

  saveArchiveValue(archiveValue: ArchiveDetailsUpdatingModel): void {
    this.model.archiveNumber = archiveValue.archiveNumber;
    this.model.archiveName = archiveValue.archiveName;
    this.model.archiveID = archiveValue.archiveID;
    this.model.archiveFolder = archiveValue.archiveFolder;
    this.model.archiveDescription = archiveValue.archiveDescription;
    this.model.archiveStatus = archiveValue.archiveStatus;
    this.model.archiveFoldersArray = archiveValue.archiveFoldersArray;
    this.model.business = archiveValue.business;
    this.model.clientNumber = archiveValue.clientNumber;
    this.model.clientName = archiveValue.clientName;
    this.model.partner = archiveValue.partner;
    this.#saveCommonSectionDetails();
  }

  updateCloseoutBanner(): void {
    if (
      this.model.winningProposal ===
        WinningProposalOptionValues.NO_RESUBMIT_FOR_REVIEW &&
      this.checkDocumentStatus(IntakeOppertunitySubmissionStatus.DRAFT)
    ) {
      this.showCompleteCloseoutFormMsg = false;
    } else {
      this.showCompleteCloseoutFormMsg = true;
    }
  }

  getSubmissionSubType(subTypeId: string | number | undefined | null): string {
    return (
      this.otherSubTypes.find((item) => item.subTypeId.toString() === subTypeId)
        ?.subTypeName || '-'
    );
  }

  isReviewerCheckboxChecked(isRequired: boolean, index: number): boolean {
    if (
      this.submissionStatus == this.DRAFT_STATUS &&
      this.selfReviewEligibility == this.valueYes &&
      !this.optOutOfSelfReviewReason
    ) {
      return false;
    }

    return isRequired ?? false;
  }

  isReviewerCheckboxDisabled(emailId: string, index: number): boolean {
    if (
      this.selectedRiskManagers.length === 1 &&
      this.selectedRiskManagers.includes(emailId)
    ) {
      return true;
    }

    return this.isWorkflowDisabled();
  }

  isWorkflowDisabled(): boolean {
    if (this.submissionStatus == this.DRAFT_STATUS) {
      if (
        this.selfReviewEligibility == this.valueYes &&
        !this.optOutOfSelfReviewReason
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      this.submissionStatus == this.IntakeDocumentSubmissionStatusSubmitted ||
      this.submissionStatus == this.submissionStatusResubmitted
    ) {
      return false;
    } else {
      return true;
    }
  }

  checkUserAccess(section: string): boolean {
    const roles: string[] = this.userRolesAccess?.map(
      (role: any) => role.roleName
    );
    if (section === 'AddReviewer') {
      return (
        roles.includes(this.roleRiskManager) ||
        roles.includes(this.roleSupportingRiskManager) ||
        roles.includes(this.roleSpecializedRiskManager) ||
        roles.includes(this.roleTechnicalOrIndependentRM) ||
        roles.includes(this.roleAdditionalReviewer)
      );
    }
    return false;
  }

  onChangeWbsChargeCodeInCloseOut(wbsChargeCode: WbsResponseDataModel): void {
    this.model.wbsChargeCode = wbsChargeCode.wbsChargeCode;
    this.model.wbsDescription = wbsChargeCode.wbsDescription;
    this.wbsCheckboxSelected = wbsChargeCode.wbsPending;
    this.#saveCommonSectionDetails();
    setTimeout(() => {
      this.loadSubmission();
    }, 1000);
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next('');
    this.unsubscriber$.complete();
    this.submissionService.openAdminCompleteModal(false);
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.submissionService.setSelectedOpportunityDocStatus(null);
  }

  checkValidationOfForm(): boolean {
    const submissionData = this.model; //
    const submissionStatusId = this.submissionData?.submissionStatusId ?? -1;
    const submissionTypeId = this.submissionData?.submissionTypeId ?? -1;

    // Submitted, Re-submitted, Completed, Completed(Admin)
    if ([3, 4, 7, 8, 9].includes(submissionStatusId)) {
      return true;
    }

    // Draft, Returned for rework
    if (
      [1, 2].includes(submissionStatusId) &&
      this.submissionData.lepReviewedBy !== null
    ) {
      switch (submissionTypeId) {
        case 1:
          return (
            submissionData.proposalDescription !== null &&
            submissionData.proposalDescription !== '' &&
            submissionData.relatedToRFP !== null &&
            this.uploadedDocData.length > 0
          );
        case 2:
          return this.uploadedDocData.length > 0;
        case 3:
          return (
            submissionData.coDescription !== null &&
            submissionData.coDescription !== '' &&
            submissionData.coChangeScope !== null &&
            this.uploadedDocData.length > 0
          );
        case 4:
          return (
            submissionData.delDescription !== null &&
            submissionData.delDescription !== '' &&
            this.uploadedDocData.length > 0
          );
        case 5:
          return (
            submissionData.otherDescription !== null &&
            submissionData.otherDescription !== '' &&
            this.uploadedDocData.length > 0
          );
        default:
          return false;
      }
    }

    // RM/Self - Pending Closeout
    if ([5, 6].includes(submissionStatusId)) {
      switch (submissionTypeId) {
        case 1:
          return (
            ((submissionData.winningProposal?.toLowerCase() ?? '').includes(
              this.YES_CONST
            ) &&
              this.uploadedDocData.length > 0) ||
            ((submissionData.winningProposal?.toLowerCase() ?? '').includes(
              this.RESUBMIT_CONST
            ) &&
              this.uploadedDocData.length > 0)
          );
        case 2:
          return (
            (submissionData.wbsChargeCode !== null &&
              (
                submissionData.contractSignedExecuted?.toLowerCase() ?? ''
              ).includes(this.YES_CONST) &&
              this.uploadedDocData.length > 0) ||
            ((
              submissionData.contractSignedExecuted?.toLowerCase() ?? ''
            ).includes(this.RESUBMIT_CONST) &&
              this.uploadedDocData.length > 0)
          );
        case 3:
          return (
            (submissionData.wbsChargeCode !== null &&
              (
                submissionData.contractSignedExecuted?.toLowerCase() ?? ''
              ).includes(this.YES_CONST) &&
              this.uploadedDocData.length > 0) ||
            ((
              submissionData.contractSignedExecuted?.toLowerCase() ?? ''
            ).includes(this.RESUBMIT_CONST) &&
              this.uploadedDocData.length > 0)
          );
        case 4:
          return (
            ((
              submissionData.updatedVersionForReview?.toLowerCase() ?? ''
            ).includes(this.YES_CONST) &&
              this.uploadedDocData.length > 0) ||
            ((
              submissionData.updatedVersionForReview?.toLowerCase() ?? ''
            ).includes('no') &&
              this.uploadedDocData.length > 0)
          );
        case 5:
          return (
            ((
              submissionData.otherUpdatedVersionForReview?.toLowerCase() ?? ''
            ).includes(this.YES_CONST) &&
              this.uploadedDocData.length > 0) ||
            ((
              submissionData.otherUpdatedVersionForReview?.toLowerCase() ?? ''
            ).includes(this.RESUBMIT_CONST) &&
              this.uploadedDocData.length > 0)
          );
        default:
          return false;
      }
    }
    return false;
  }

  getUploadedDocumentData(): void {
    this.progressIndicator.show();
    this.submissionService
      .getDocumentTableData(this.submissionId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: IntakeDocumentTableModel[]) => {
          this.uploadedDocData = data;
        },
        error: (err: any) => {
          console.log('Error in fetching document data', err);
        },
      });
    this.progressIndicator.hide();
  }
}
