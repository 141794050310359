import { EmployeeView } from "../common-models";
import { DashboardData } from "./start-page.model";
  
  export interface NCASpecialistDashboardModel extends DashboardData{
    clientNumber: string;
    priorWatchListStatus: string;
    clientMilestoneId?: number;
    clientMilestoneStatusId: number;
    clientMilestoneProcessTypeId: string;
    recommendedClientGroupNumber: string;
    recommendedClientGroupCode  : string;
    recommendedClientGroupName  : string;
    prevClientMilestoneStatusId: number;
    business?: string;
    recommendedGroup: string;
    opportunityExpectedCloseDate: string;
    opportunityExpectedCloseDateValueOrder: number;
    ncaTriggeredDate?: string;
    isActiveMsaExists?: boolean;
    isNcaNotRequiredAvailable ?: boolean;
    isOnHoldOptionAvailable ?: boolean;
    isDiscontinueAvailable  ?: boolean;
    isConvertNca2CcAavailable ?: boolean;
    isAssignToAvailable ?: boolean;
    actionBy: EmployeeView;
    discontinueReason: string;
    notRequiredReason: string;
    onHoldReason: string;
    expandStatus: boolean;
    [key: string]: any;
    doesNcaFormExists: boolean;
    watchlistId?: number;
    watchlistStatusId?: number;
    watchlistStatus: string;
    statusComment: string;
    clientWatchlistCreatedBy: string;
    clientWatchlistCreateddate: string;
    prevWatchlistStatusId?: number;
    prevWatchlistStatus: string;
    prevStatusComment: string;
    prevModifiedBy: string;
    prevModifiedDate: string;
    netServiceRevenue: string;
    daysPendingForCCCompletion: string;
    continuanceExpiredDate: string;
    approverName:string;
    priorFyClosedWbsCount: string;
    lastInvoiceDate: string;
    programAccount: string;
    isGlobalCrownJewel: string;
    isMarsUser: boolean;
    globalProgram: string;
  }

  
export interface NCADashboardTilesCount{
    ncasToBeSubmittedCount: number;
    ncasAssignedToMeCount: number;
    ncasNotRequiredOrAcceptedCount: number;
    onHoldNcasCount: number;
    discontinuedNcasCount: number;
    marsNcasToBeSubmittedCount: number;
    marsNcasDraftCount:number;
    ccDueCount: number;
  }
  
  
  export enum NCATileCode{
    CompleteNcaForm = 'CompleteNcaForm',
    PreScreenNcaForm = 'PreScreenNcaForm',
    NcaNotRequired = 'NcaNotRequired',
    OnHold = 'OnHold',
    NcaDiscontinued = 'NcaDiscontinued',
    CompleteMarsNcaForm = 'CompleteMarsNcaForm',
    CCDue = 'CCDue'
  }
  
  export const NCATileCodeDetails = new Map<NCATileCode, { title: string, countKey: keyof NCADashboardTilesCount, tileCode: string }>([
    [NCATileCode.CompleteNcaForm, { title: 'NCAs to be submitted', countKey: 'ncasToBeSubmittedCount', tileCode: 'CompleteNcaForm' }],
    [NCATileCode.PreScreenNcaForm, { title: 'NCAs assigned to me', countKey: 'ncasAssignedToMeCount', tileCode: 'PreScreenNcaForm' }],
    [NCATileCode.NcaNotRequired, { title: 'NCA procedures not required/accepted', countKey: 'ncasNotRequiredOrAcceptedCount', tileCode: 'NcaNotRequired' }],
    [NCATileCode.OnHold, { title: 'On hold', countKey: 'onHoldNcasCount', tileCode: 'OnHold' }],
    [NCATileCode.NcaDiscontinued, { title: 'Discontinued NCAs', countKey: 'discontinuedNcasCount', tileCode: 'NcaDiscontinued' }],
    [NCATileCode.CompleteMarsNcaForm, { title: 'MARS NCAs to be submitted', countKey: 'marsNcasToBeSubmittedCount', tileCode: 'CompleteMarsNcaForm' }],
    [NCATileCode.CCDue, { title: 'Client continuances due', countKey: 'ccDueCount', tileCode: 'CCDueNow' }],
  ]);
  
  