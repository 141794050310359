import { SelectOptions } from '@usitsdasdesign/dds-ng/select';
export const selectOptions: SelectOptions = {
  placeholder: 'Select Reason',
};
import { RadioGroupOptions } from '@usitsdasdesign/dds-ng/radio';
import { ErrorState } from '@usitsdasdesign/dds-ng/shared';

export const RADIO_GROUP_OPTIONS_FOR_STATUS: RadioGroupOptions = {
  name: 'status',
  disabled: false,
  isError: false,
  errorState: ErrorState.default,
  isUncheckable: false,
  title: 'Select a status',
  isRequired: true,
  isInverse: false,
};
