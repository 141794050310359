<div class="dds-modal-overlay" (click)="close()"></div>

<div
  ddsResize
  [ngClass]="{
    'dds-modal_lg': size === 'lg',
    'dds-modal_inverse': isInverse
  }"
  class="dds-modal"
>
  <div class="dds-modal__header">
    <span class="dds-modal__title">Reset submission status</span>
    <dds-button
      size="sm"
      icon="dds-icon_close"
      kind="silent"
      theme="dark"
      ariaLabel="Close modal"
      class="dds-modal__close"
      [isInverse]="isInverse"
      (clicked)="close()"
    ></dds-button>
  </div>

  <div class="dds-modal__body">
    <div class="body-section">
      <div class="comment margin-top-xs">
        <div>
          <span class="eclipse-small-header-grey">{{
            status_radioGroupOptions.title
          }}</span>
        </div>
        <dds-radio-group
          autosave
          [(ngModel)]="status"
          [name]="status"
          [isUncheckable]="status_radioGroupOptions.isUncheckable"
          [ariaLabel]="'ResetStatusPopUpRadio'"
          [isRequired]="status_radioGroupOptions.isRequired"
          [errorState]="status_radioGroupOptions.errorState"
        >
          <ng-container *ngFor="let radio of statusRadioValues">
            <dds-radio
              *ngIf="radio.options.label && radio.options.theme"
              [value]="radio.value"
              [customClass]="
                'padding-right-half-rem margin-right-s font-size-14'
              "
              [label]="radio.options.label"
              [theme]="radio.options.theme"
              [ariaLabel]="radio.options.label"
            ></dds-radio>
          </ng-container>
        </dds-radio-group>
      </div>

      <div class="comment margin-top-xs">
        <span class="eclipse-small-header-grey"
          >Reason for resetting status</span
        >
        <dds-select
          [placeholder]="selectOptions.placeholder ?? ''"
          [list]="actionDropdownList"
          [(ngModel)]="selectedReason"
          (valueChanged)="handleDropDownSelected($event)"
          [ariaLabel]="'SelectOptions'"
          [size]="selectOptions.size ?? ''"
        ></dds-select>
      </div>
      <div
        *ngIf="showReasonTextBox"
        class="comment margin-bottom-l margin-top-xs"
      >
        <span class="eclipse-small-header-grey">Reason</span>
        <dds-textarea
          [(ngModel)]="otherReasonDescription"
          [placeholder]="textareaOptions.placeholder ?? ''"
          [maxLength]="textareaOptions.maxLength ?? 0"
          [maxLengthPosition]="textareaOptions.maxLengthPosition ?? ''"
          [minHeight]="textareaOptions.minHeight ?? 0"
          [size]="textareaOptions.size ?? ''"
          [ariaLabel]="'Additional details'"
        >
        </dds-textarea>
      </div>
    </div>
  </div>

  <div class="dds-modal__footer">
    <div class="dds-modal__footer-content dds-modal__footer-content_left">
      <dds-button
        class="dds-modal__footer-item"
        (clicked)="saveSubmissionHistory()"
        [disabled]="!formValid()"
        (keyboardClicked)="saveSubmissionHistory()"
        [ariaLabel]="'Additional close'"
        >Reset status</dds-button
      >
      <dds-button
        class="dds-modal__footer-item"
        kind="secondaryLoud"
        [isInverse]="isInverse"
        (clicked)="close()"
        (keyboardClicked)="close()"
        [ariaLabel]="'Cancel'"
        >Cancel</dds-button
      >
    </div>
  </div>
</div>
