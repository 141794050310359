import {
  SelectItemOptions,
  SelectOptions,
} from '@usitsdasdesign/dds-ng/select';
import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { IntakeConstant } from '../../../../constants/intake.constant';
import { SHOW_MORE_BTN_OPTIONS } from '../../submission-common-section.helper';
import { SubmissionService } from '../../../../../http/intake/submission.service';
import { ProgressIndicatorService } from '../../../../../common/services/progress-indicator.service';
import { Subject, takeUntil } from 'rxjs';
import { configurationType } from '../../../../../common/models/common-models';
import { selectOptions } from './administartively-helper';
import { CommonService } from '../../../../../http/intake/common.service';
import { OppertunitySubmissionStatusCode } from '../../../../../common/models/oppertunity-submission-statusCode.model';
import { IntakeOppertunitySubmissionStatus } from '../../../../constants/intake_oppertunity_submission_status.enum';
import{IntakeSubmissionActionReasonDropdown} from '../../../../../common/models/submission-action.model'

@Component({
  selector: 'app-administratively-close-popup',
  templateUrl: './administratively-close-popup.component.html',
  styleUrl: './administratively-close-popup.component.less',
})
export class AdministrativelyClosePopupComponent implements OnInit {
  constructor(
    private readonly modalService: ModalService,
    private readonly intakeDetailService: SubmissionService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly commonServive: CommonService
  ) {}

  @Input() isFooter: boolean = false;
  @Input() size: string = '';
  @Input() isInverse: boolean = false;
  @Input() submissionId: number = 0;
  isShowMoreDocumentNotes: boolean = true;
  showMoreText: string = IntakeConstant.SHOW_LESS_TEXT;
  showMoreOptions: any = SHOW_MORE_BTN_OPTIONS;
  actionDropdownList: SelectItemOptions[] = [];
  selectedReason!: number;
  additionDetail: string = '';
  configData: configurationType[] = [];
  configLableDescription: string = '';
  configLableGuidance: string = '';
  unsubscriber$: Subject<void> = new Subject<void>();
  textareaOptions = IntakeConstant.TEXT_AREA_SETTINGS_GLOBAL;
  selectOptions = selectOptions;
  statusCodeList!: OppertunitySubmissionStatusCode[];

  ngOnInit(): void {
    this.getActionDropdownData(IntakeConstant.SubmissionActionID);
    this.getLabelConfiguration();
    this.showMoreOptions.icon = IntakeConstant.DDS_ICON_ARROW_UP;
    this.commonServive
      .getOppertunityStatusCode()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: OppertunitySubmissionStatusCode[]) => {
          this.statusCodeList = response;
        },
        error: (err) => {
          console.error('An error occurred during fetching data: ', err);
        },
      });
  }

  getLabelConfiguration(): void {
    this.intakeDetailService
      .getIntakeConfigurations()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: configurationType[]) => {
          if (response) {
            this.configData = response;
            this.configLableGuidance =
              this.configData.find(
                (config: configurationType) =>
                  config.appConfigurationLabelKey ===
                  'ADMINCOMPLETE_SUBMISSION_GUIDANCE'
              )?.appConfigurationLabelValue || '';

            this.configLableDescription =
              this.configData.find(
                (config: configurationType) =>
                  config.appConfigurationLabelKey ===
                  'ADMINCOMPLETE_SUBMISSION_DESCRIPTION'
              )?.appConfigurationLabelValue || '';
          }
        },
        error: (err) => {
          console.error('An error occurred during submission: ', err);
        },
      });
  }

  getActionDropdownData(id: number): void {
    this.intakeDetailService.getActionReasons(id).subscribe({
      next: (result: IntakeSubmissionActionReasonDropdown[]) => {
        const convertedArray = result.map((item: any) => ({
          value: item.reasonId,
          heading: item.reason,
        }));
        this.actionDropdownList = convertedArray;
      },
      error: (err: any) => {
        this.progressIndicator.hide();
      },
    });
  }

  clickToShowDocumentMoreLepMessage(): void {
    this.isShowMoreDocumentNotes = !this.isShowMoreDocumentNotes;
    if (!this.isShowMoreDocumentNotes) {
      this.showMoreText = IntakeConstant.SHOW_MORE_TEXT;
      this.showMoreOptions.icon = IntakeConstant.DDS_ICON_ARROW_DOWN;
    } else {
      this.showMoreText = IntakeConstant.SHOW_LESS_TEXT;
      this.showMoreOptions.icon = IntakeConstant.DDS_ICON_ARROW_UP;
    }
  }

  async saveSubmissionHistory(): Promise<void> {
    const postRequestData = {
      submissionId: this.submissionId,
      currentStatusId: Number(
        this.statusCodeList.find(
          (x) =>
            x.statusName === IntakeOppertunitySubmissionStatus.COMPLETED_ADMIN
        )?.id
      ),
      submissionActionId: IntakeConstant.SubmissionActionID,
      reasonId: this.selectedReason,
      additionalDetails: this.additionDetail,
    };
    this.intakeDetailService
      .saveSubmissionHistory(postRequestData)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: any) => {
          console.log(response);
        },
        error: (err) => {
          console.error('An error occurred while saving the data: ', err);
        },
      });
    this.close();
  }

  formValid(): boolean {
    if (this.selectedReason != 0 && this.additionDetail != '') return true;
    return false;
  }

  close():void {
    this.modalService.close();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
