import { Component, QueryList, ViewChildren, ElementRef, ViewChild } from '@angular/core';
import {  PositionState, Themes, ExtThemes, ButtonKind, Size, WidthState } from '@usitsdasdesign/dds-ng/shared';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
import { Filter, FilterItem } from '@usitsdasdesign/dds-ng/filter';
import { StickerDirective, StickerOptions } from '@usitsdasdesign/dds-ng/sticker';
import { WatchlistApiService, Watchlist, WatchlistDashboardFilterSortParameterCode, WatchlistDashboardGridLevelFilter, GridColumn } from '../../http/watchlist/watchlist-api.service'
import { DropdownItemOptions, DropdownOptions } from '@usitsdasdesign/dds-ng/dropdown';
import { ButtonOptions } from "@usitsdasdesign/dds-ng/button";
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ShowMoreComponent } from '../show-more/show-more.component'
import { ProgressIndicatorService } from '../../common/services/progress-indicator.service';
import { environment } from '../../../environment/environment';
import { Subscription } from 'rxjs';
import { MultiSelectOptions, MultiSelectItem, SelectType, SelectControlTypes } from '@usitsdasdesign/dds-ng/multi-select';
import { FormControl } from "@angular/forms";
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SecurityWebapiService } from '../../http/security/security-webapi.service';

@Component({
  selector: 'app-watchlist-grid',
  templateUrl: './watchlist-grid.component.html',
  styleUrl: './watchlist-grid.component.less',
  providers: [DatePipe]
})
export class WatchlistGridComponent {

  @ViewChildren('stickerDir') sticker: QueryList<StickerDirective> | undefined;
  public countforPagination: number = 5;
  multiSelectFormCtrl = new FormControl({ value: [], disabled: false });
  public isShownFilter!: boolean;
  public model: Watchlist[] = [];
  public dropdownItems: DropdownItemOptions[] = [];
  public gotoPage: number = 0;
  private subscriptions: Subscription[] = [];
  public isLoading: boolean = true;
  public enumFilterSortParameterCode = WatchlistDashboardFilterSortParameterCode;
  public filteringColumn!: GridColumn;
  public isAscending: boolean = true;
  public watchlistDashboardfilters: WatchlistDashboardGridLevelFilter[] = [];
  selectedItems: string[] = [];
  isAllFilterSelected: boolean = false;
  public sortParamaterCode: number = 1;
  public itemsPerPage: number = 5;

  private securityAddAndEditButton : string = 'Watchlist.AddAndEditButton';
  private securityAuditTrail : string = 'Watchlist.AuditTrail';
  private securityDownLoadWatchList : string = 'Watchlist.DownLoadWatchList';


  public isAddAndEditButtonVisible: boolean = false;
  public isAuditTrailButtonDisabled: boolean = false;
  public isDownloadWatchlistButtonDisabled: boolean = false;
  @ViewChild('statusCommentText', { static: false }) statusCommentText: ElementRef | undefined;

  constructor(private watchlistwebapiservice: WatchlistApiService,
    private modalService: ModalService,
    private progressIndicator: ProgressIndicatorService,
    private router: Router,
    private datePipe: DatePipe,
    private securityWebapiService: SecurityWebapiService
  ) {
  }
  rootUrl: string = environment.rootUrl;


  public filterStickerOptions: StickerOptions = {
    stickerPosition: PositionState.bottomLeft,
    stickerIsOutsideClick: true,
    stickerIndent: -1,
    stickerWidth: 380,
  };

  ngOnInit() {
    this.progressIndicator.show();
    this.securityWebapiService.getPermissions('');

    this.securityWebapiService.data.subscribe((rules: any) => {
      if (!rules.empty) {
        this.isAddAndEditButtonVisible = rules[this.securityAddAndEditButton] && rules[this.securityAddAndEditButton].Visible !== undefined ? rules[this.securityAddAndEditButton].Visible : false;
        if (!this.isAddAndEditButtonVisible) {
          this.router.navigate(['/']);
        }
        this.isAuditTrailButtonDisabled = !(rules[this.securityAuditTrail] && rules[this.securityAuditTrail].Enable);
        this.isDownloadWatchlistButtonDisabled = (rules[this.securityDownLoadWatchList] && rules[this.securityDownLoadWatchList].Enable);
        this.getDropdownItems();
      }
    });

    const parameterCode = this.enumFilterSortParameterCode['watchlistStatusName'];

    this.watchlistDashboardfilters = [
      { filtertypecode: parameterCode, filtercriteria: 'Do not serve' },
      { filtertypecode: parameterCode, filtercriteria: 'Serve with conditions' }
    ];

    this.selectedItems = [...new Set([...this.selectedItems, 'Do not serve', 'Serve with conditions'])];

    this.subscriptions.push(
      this.watchlistwebapiservice.getClientWatchlistGridDetails(this.gotoPage, this.itemsPerPage, this.sortParamaterCode, this.isAscending, this.watchlistDashboardfilters).subscribe((data: any) => {
        this.model = data.watchlist;
        this.countforPagination = data.totalDataCount;
        this.isLoading = false;
        this.progressIndicator.hide();
      })
    );
    this.getDropdownItems();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }


  public defaultGridColumns: GridColumn[] = [
    { name: 'clientName', header: 'Client name', dataType: 'string', minWidth: '10rem', filterable: false, searchValue: '' },
    { name: 'parentclientName', header: 'Parent client name', dataType: 'string', minWidth: '10rem', filterable: false, searchValue: '' },
    { name: 'watchlistStatusName', header: 'Status', dataType: 'string', minWidth: '10.75rem', filterable: true, searchValue: '' },
    { name: 'statusComment', header: 'Client risk comment', dataType: 'string', minWidth: '15rem', filterable: false, searchValue: '' },
    { name: 'industry', header: 'Industry', dataType: 'string', minWidth: '10rem', filterable: false, searchValue: '' },
    { name: 'sector', header: 'Sector', dataType: 'string', minWidth: '7rem', filterable: false, searchValue: '' },
    { name: 'modifiedBy', header: 'Status changed by', dataType: 'string', minWidth: '10rem', filterable: false, searchValue: '' },
    { name: 'actions', header: 'Action', dataType: '', minWidth: '1rem', filterable: false, searchValue: '' },
  ];

  public sortingState = {
    property: this.defaultGridColumns[0].name,
    ascending: true,
    dataType: this.defaultGridColumns[0].dataType,
  };


  filterOptions: Filter = {
    theme: Themes.green,
    isInverse: false,
    propName: 'name',
    itemPropNameValue: 'value'
  };

  filterData: FilterItem[] = [
    {
      title: 'Do not serve',
      name: 'Do not serve',
      isChecked: true
    },
    {
      title: 'Serve with conditions',
      name: 'Serve with conditions',
      isChecked: true
    },
    {
      title: 'Removed',
      name: 'Removed',
      isChecked: false
    }
  ];

  clientTooltipOptions: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'top',
    tooltipIndent: 5,
    tooltipHasBeak: true,
    tooltipType: 'regular',
    tooltipSize: 'md',
    tooltipTheme: Themes.dark
  };
  dropdownOptions: DropdownOptions = {
    label: '',
    ariaLabel: '',
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.md,
    width: WidthState.fixed,
    icon: 'dds-icon_dots-v',
    disabled: false,
    stickerWidth: 200,
  };

  options: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.silent,
    size: Size.lg,
    width: WidthState.fixed,
    disabled: false,
    role: 'button'
  }

  addwatchlistoptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primaryLoud,
    size: Size.lg,
    width: WidthState.fixed,
    disabled: false,
    role: 'button'
  }

  downlaodbuttonoptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: Size.lg,
    width: WidthState.fixed,
    icon: 'dds-icon_download',
    role: 'button'
  }

  showmoreoptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.silent,
    size: Size.lg,
    width: WidthState.fixed,
    isLoading: false,
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: false,
    ariaLabel: 'Show Less',
    customClass: '',
    role: 'button'
  }

  getDropdownItems() {
    this.dropdownItems = [
      {
        heading: 'Edit',
        value: 'Edit',
        disabled:  !this.isAddAndEditButtonVisible
      },
      {
        heading: 'Audit trail/view history',
        value: 'Audit trail/view history',
        disabled:  this.isAuditTrailButtonDisabled
      }
    ];

  }


  isTextOverflowing(element: HTMLElement): boolean {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  }

  public clickshowmore(row: Watchlist, event: MouseEvent) {
    this.stopEventPropagation(event);
    let modalRef = this.modalService.open(ShowMoreComponent);
    modalRef.componentInstance.labelValues = {
      labelHeader: 'Client risk comment',
      clientname: row.clientName,
      clientnumber: row.clientNumber,
      comment: row.statusComment,
      watchliststatusId: row.watchlistStatusId,
      watchliststatusname: row.watchlistStatusName,
    };

  }

  public gotoClientStatus(clientNumber: string) {
    if (clientNumber != null) {
      let url = this.rootUrl + 'client/' + clientNumber + '/status';
      window.open(url, '_blank');
    }
  }

  public toggleFilter(column: GridColumn) {
    this.filteringColumn = column;
    this.isShownFilter = true;
  }

  public filterOnHidden(): void {
    this.isShownFilter = false;
  }

  refreshGrid() {
    this.progressIndicator.show();

    this.watchlistwebapiservice.getClientWatchlistGridDetails(this.gotoPage, this.itemsPerPage, this.sortParamaterCode, this.isAscending, this.watchlistDashboardfilters).subscribe((response : any) => {
      this.model = response.watchlist;
      this.countforPagination = response.totalDataCount;
      this.progressIndicator.hide();
    });

  }

  public selectAllFilter(event: any): void {
    if (event) {
      this.selectedItems = this.filterData.map(item => item['name']);
      this.filterData.forEach(item => item['isChecked'] = true);
      this.isAllFilterSelected = true;
    } else {
      this.selectedItems = [];
      this.filterData.forEach(item => item['isChecked'] = false);
      this.isAllFilterSelected = false;
    }
    this.filteringColumn.searchValue = this.selectedItems.join(', ');

    this.filterData.forEach(item => {
      item['isChecked'] = this.selectedItems.includes(item['name']);
    });
  }

  public selectFilterItem(event: any, filterItem: any): void {
    if (event) {
      if (!this.selectedItems.includes(filterItem.name)) {
        this.selectedItems.push(filterItem.name);
      }
    } else {
      this.selectedItems = this.selectedItems.filter(item => item !== filterItem.name);
    }

    this.isAllFilterSelected = this.selectedItems.length === this.filterData.length;
    this.filteringColumn.searchValue = this.selectedItems.join(', ');

    // Update the filterData to reflect the current state of selectedItems
    this.filterData.forEach(item => {
      item['isChecked'] = this.selectedItems.includes(item['name']);
    });
  }

  public filterSorted(isAscending: boolean): void {
    const parameterCode = this.enumFilterSortParameterCode[this.filteringColumn.name as keyof typeof WatchlistDashboardFilterSortParameterCode];
    this.sortParamaterCode = parameterCode;
    this.isAscending = isAscending;

    this.sortingState = {
      property: this.filteringColumn.name,
      ascending: isAscending,
      dataType: this.filteringColumn.dataType,
    };

    this.filterHide();
    this.refreshGrid();
  }

  public clearFilter(column: string) {
    //remove the filter for the specified column from the list
    this.watchlistDashboardfilters = this.watchlistDashboardfilters.filter(filter => {
      const parametercode = this.enumFilterSortParameterCode[column as keyof typeof WatchlistDashboardFilterSortParameterCode];
      return filter.filtertypecode !== parametercode;
    });

    // reset the filterable and searchvalue properties for the specified column
    this.filteringColumn.filterable = false;
    this.filteringColumn.searchValue = '';

    this.filterHide();
    this.refreshGrid();
  }

  public filterApply(searchValue: string) {
    if (searchValue === '' || searchValue === null) {
      this.clearFilter(this.filteringColumn.name);
      return;
    }

    const parameterCode = this.enumFilterSortParameterCode[this.filteringColumn.name as keyof typeof WatchlistDashboardFilterSortParameterCode];
    let filterCriteria: string;

    this.watchlistDashboardfilters = this.watchlistDashboardfilters.filter(filter => filter.filtertypecode !== parameterCode);

    if (this.filteringColumn.name === 'watchlistStatusName') {
      const values = searchValue.split(',');
      const newFilters = values.map(value => ({
        filtertypecode: parameterCode,
        filtercriteria: value.trim()
      }));

      this.watchlistDashboardfilters = [...(this.watchlistDashboardfilters || []), ...newFilters];
    } else {
      const newFilter = { filtertypecode: parameterCode, filtercriteria: searchValue };
      this.watchlistDashboardfilters = [...(this.watchlistDashboardfilters || []), newFilter];
    }

    this.filteringColumn.filterable = true;
    this.filterHide();
    this.refreshGrid();
  }

  public filterHide(): void {
    this.sticker?.forEach((item) => {
      if (item.isActive) {
        item.hide();
      }
    });
  }

  public cancel(value: number) {
    if (value != null && this.filteringColumn.filterable == false) {
      this.filteringColumn.searchValue = '';
    }
    this.filterHide();
  }

  onPageChanged(value: number) {
    this.gotoPage = value - 1;
    this.refreshGrid();
  }

  onValueChanged(item: number) {
    this.itemsPerPage = item;
    this.gotoPage = 0;
    this.refreshGrid();
  }

  isApplyButtonEnabled(): boolean {
    if (this.filteringColumn.name === 'watchlistStatusName') {
      return this.filterData.some(item => item['isChecked']);
    }

    if (this.filteringColumn.name === 'statusComment' && this.filteringColumn.searchValue.length < 3) {
      return false;
    }

    return this.filteringColumn.filterable || !!this.filteringColumn.searchValue;
  }

  public clearAllFilter() {

    const parameterCode = this.enumFilterSortParameterCode['watchlistStatusName'];

    this.watchlistDashboardfilters = [];
    this.watchlistDashboardfilters = [
      { filtertypecode: parameterCode, filtercriteria: 'Do not serve' },
      { filtertypecode: parameterCode, filtercriteria: 'Serve with conditions' }
    ];

    this.selectedItems = [...new Set(['Do not serve', 'Serve with conditions'])];

    this.filterData = this.filterData.map(item => ({
      ...item,
      isChecked: this.selectedItems.includes(item['name'])
    }));

    this.defaultGridColumns.forEach(column => {
      if (column.name !== 'watchlistStatusName') {
        column.filterable = false;
      }
      column.searchValue = '';
    });
    this.filterHide();
    this.refreshGrid();
  }
  addNewWatchlist() {
    this.router.navigate(['/watchlist/add']);
  }
  actionButtonSelected(value: string, clientNumber: string, watchlistId: number | undefined, clientName: string) {
    if (value == 'Edit') {
      if (this.isAddAndEditButtonVisible == true) {
        this.editWatchlistClient(clientNumber);
      }
    }
    else if (value == 'Audit trail/view history') {

      const url = this.rootUrl + 'watchlist/audit-trail/watchlistId/' + watchlistId;

      const newTab = window.open(url, '_blank');
      if (newTab) {
        newTab.addEventListener = () => {
          newTab.history.replaceState({ clientNumber, clientName }, '');
        };
      }
    }
  }

  onRowClick(row: any, event: MouseEvent, clientNumber: string) {
    if (!this.isAddAndEditButtonVisible || this.isAddAndEditButtonVisible == undefined) {
      return;
    }
    let target = event.target as HTMLElement;

    while (target && target.tagName !== 'TD') {
      target = target.parentElement as HTMLElement;
    }

    if (target) {
      const columnName = target.getAttribute('data-column-name');

      if (columnName !== 'actions' && columnName !== 'clientName' && columnName !== 'parentclientName') {
        this.navigateToAddWatchlist(clientNumber);
      }
    }
  }

  public editWatchlistClient(clientNumber: string) {
    this.navigateToAddWatchlist(clientNumber);
  }

  navigateToAddWatchlist(clientNumber: string) {
    this.router.navigateByUrl('/watchlist/add', { state: { clientNumber: clientNumber } });
  }
  downloadWatchlistClientReport() {
    this.watchlistwebapiservice.getWatchlistClientsReport().subscribe((response: any) =>
    {
      const base64Data = response.base64String;
      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Data}`;
      const downloadLink = document.createElement('a');
      const formattedDate = this.datePipe.transform(new Date(), 'MMddyyyy');
      const fileName = `${response.fileName} - ${formattedDate}.xlsx`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    );
  }
  public stopEventPropagation(event: MouseEvent) {
    event.stopPropagation();
  }
}
