<div class="padding-top-s new-pagination-and-grid">
  <div class="pagination-container">
    <span class="dds-item">
      <dds-pager
        [disabled]="paging.disabled ?? false"
        [label]="paging.label ?? ''"
        [size]="paging.size ?? ''"
        [minValue]="paging.minValue ?? 1"
        [maxValue]="paging.maxValue ?? 5"
        [customClass]="paging.customClass ?? ''"
        [value]="pageUI"
        (pageChanged)="onPageChanged($event)"
      >
      </dds-pager>
    </span>
    <span>
      <dds-pagination
        [size]="paginationOptions.size ?? ''"
        [disabled]="paginationOptions.disabled ?? false"
        [isInverse]="paginationOptions.isInverse ?? false"
        [mode]="paginationOptions.mode ?? ''"
        [pageLength]="paginationOptions.pageLength ?? 1"
        [pageNumberInSection]="paginationOptions.pageNumberInSection ?? 1"
        [theme]="paginationOptions.theme ?? ''"
        [ariaLabel]="paginationOptions.ariaLabel ?? ''"
        [value]="pageUI"
        [customClass]="paginationOptions.customClass ?? ''"
        [isIndefinitePageLength]="
          paginationOptions.isIndefinitePageLength ?? false
        "
        [isResponsive]="paginationOptions.isResponsive ?? false"
        (pageChanged)="onPageChanged($event)"
      >
      </dds-pagination>
    </span>
    <span class="dds-item-per-page">
      <dds-items-per-page
        [isResponsive]="itemPerPageConfig.isResponsive ?? false"
        [isInverse]="itemPerPageConfig.isInverse ?? false"
        [icon]="itemPerPageConfig.icon ?? ''"
        [placeholder]="itemPerPageConfig.placeholder ?? ''"
        [customClass]="itemPerPageConfig.customClass ?? ''"
        [title]="itemPerPageConfig.title ?? ''"
        [size]="itemPerPageConfig.size ?? ''"
        [list]="list"
        [value]="itemsPerPage"
        [disabled]="itemPerPageConfig.disabled ?? false"
        [stickerMaxHeight]="itemPerPageConfig.stickerMaxHeight ?? ''"
        [stickerIsDisabled]="itemPerPageConfig.stickerIsDisabled ?? false"
        [stickerPosition]="itemPerPageConfig.stickerPosition ?? ''"
        [stickerIndent]="itemPerPageConfig.stickerIndent ?? 0"
        [stickerCustomClass]="itemPerPageConfig.stickerCustomClass ?? ''"
        [stickerIsDynamic]="itemPerPageConfig.stickerIsDynamic ?? true"
        (valueChanged)="onValueChanged($event)"
      >
      </dds-items-per-page>
    </span>
  </div>
</div>
