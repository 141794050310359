import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StartPageComponent } from './start-page/start-page.component';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';

const routes: Routes = [
  {
    path: '',
    component: StartPageComponent,
    pathMatch: 'full',
    canActivate: [MsalGuard]
  },
  {
    path: 'initiate',
    loadChildren: () => import('./initiation/initiation.module').then(m => m.InitiationModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'client',
    loadChildren: () => import('./client/client.module').then(m => m.ClientModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'intake',
    loadChildren: () => import('./intake/intake.module').then(m => m.IntakeModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'watchlist',
    loadChildren: () => import('./watchlist/watchlist.module').then(m => m.WatchlistModule),
    canActivate: [MsalGuard]
  },
  {
    path: 'thirdpartyrepository',
    loadChildren: () => import('./third-party-repository/third-party-repository.module').then(m => m.ThirdPartyRepositoryModule),
    canActivate: [MsalGuard]
  }
];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation:
      !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
        ? "enabledNonBlocking"
        : "disabled", // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
