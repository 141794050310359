import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SuggestionsTagsInputOptions } from '@usitsdasdesign/dds-ng/suggestions-tags-input';
import {
  INPUT_SEARCH_OPTIONS,
  selectOptionsArchive,
} from '../close-out-change-order/close-out-change-order.helper';
import {
  ArchiveDetailsExtendedModel,
  ArchiveDetailsModel,
  ArchiveDetailsResponseModel,
  ArchiveDetailsUpdatingModel,
  WbsArchiveReceivedModel,
  WbsResponseModel,
} from '../../../../common/models/wbs-picker.model';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { CloseOutService } from '../../../../http/intake/close-out.service';
import { copyToClipboard } from '../../../common/intake.util';
import { ProgressIndicatorService } from '../../../../common/services/progress-indicator.service';
import { IntakeConstant } from '../../../constants/intake.constant';
import { SwiftDetails } from '../../../../common/models/intake-submission-details-common-section.model';
import { Themes } from '@usitsdasdesign/dds-ng/shared';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';
@Component({
  selector: 'app-wbs-charge-code',
  templateUrl: './wbs-charge-code.component.html',
  styleUrl: './wbs-charge-code.component.less',
})
export class WbsChargeCodeComponent implements OnInit, OnDestroy {
  constructor(
    private readonly closeoutService: CloseOutService,
    private readonly progressIndicator: ProgressIndicatorService
  ) {}
  @Input() wbsChargeCode: null | string = IntakeConstant.EMPTY_SPACE;
  @Input() wbsDescription: null | string = IntakeConstant.EMPTY_SPACE;
  @Input() dataSubmissionId: number | null = null;
  @Input() dataOpportunityId: string = IntakeConstant.EMPTY_SPACE;
  @Input() set archiveDetails(details: WbsArchiveReceivedModel | null) {
    if (details?.archiveNumber != null && details?.archiveName != null) {
      this.archive = details;
      this.archiveDescription =
        details?.archiveNumber + ' ' + details?.archiveName;
      this.showArchiveDropdown = false;
    } else {
      this.archiveDescription = IntakeConstant.EMPTY_SPACE;
      this.showArchiveDropdown = true;
    }
  }
  @Input() swiftDetails: SwiftDetails | null = null;

  @Output() wbsChargeCodeChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() wbsArchiveChange: EventEmitter<ArchiveDetailsUpdatingModel> =
    new EventEmitter<ArchiveDetailsUpdatingModel>();

  readonly #unsubscriber$: Subject<void> = new Subject<void>();
  archive: WbsArchiveReceivedModel | null = null;
  showEditWbsChargeCode: boolean = false;
  wbsPendingCheckbox: boolean = false;
  reloadWbsChargeCode: boolean = true;
  searchList: string[] = [];
  options: SuggestionsTagsInputOptions = INPUT_SEARCH_OPTIONS;
  wbsObjectArray: WbsResponseModel[] = [];
  archiveSelectOptions = selectOptionsArchive;
  selectedArchive: string = IntakeConstant.EMPTY_SPACE;
  wbsInputDisabled: boolean = false;
  actionDropdownList: SelectItemOptions[] = [];
  dropdownListData!: ArchiveDetailsResponseModel;
  wbsWarning: boolean = true;
  showArchiveDropdown: boolean = true;
  archiveDescription: string = IntakeConstant.EMPTY_SPACE;
  checkboxThemeColor: string = IntakeConstant.INTAKE_CHECKBOX_THEME_COLOR;
  emptyString: string = IntakeConstant.EMPTY_SPACE;

  tooltipOptions: TooltipOptions = {
    tooltipInvokeType: 'hover',
    tooltipPosition: 'top',
    tooltipIndent: 15,
    tooltipIsOutsideClick: false,
    tooltipHasBeak: true,
    tooltipIsDisabled: false,
    tooltipIsInverse: false,
    tooltipIsDynamic: false,
    tooltipCustomClass: '',
    tooltipType: 'regular',
    tooltipSize: 'md',
    tooltipShowDelay: 0,
    tooltipHideDelay: 0,
    tooltipTheme: Themes.dark,
  };

  ngOnInit(): void {
    if (
      this.wbsChargeCode === IntakeConstant.EMPTY_SPACE ||
      this.wbsChargeCode === null
    ) {
      this.showEditWbsChargeCode = true;
      this.fetchWbsChargeCode();
    } else {
      this.fetchArchiveDropDownData();
    }

    // TODO: API CALL TO BE IMPLEMENTED FOR LOADING ARCHIVE DATA
    // this.actionDropdownList = this.dropdownListData[0].ArchiveDetails.map(
    //   (item: any) => {
    //     return {
    //       heading: item.ArchiveNumber + ' ' + item.ArchiveName,
    //       value: item.ArchiveID,
    //     };
    //   }
    // );
  }

  clipboardCopy(): void {
    if (
      this.wbsChargeCode != null &&
      this.wbsChargeCode !== IntakeConstant.EMPTY_SPACE
    ) {
      copyToClipboard(this.wbsChargeCode);
    }
  }

  editWBSChargeCode(): void {
    this.showEditWbsChargeCode = true;
    this.fetchWbsChargeCode();
  }

  fetchWbsChargeCode(): void {
    this.searchList = [];
    this.wbsChargeCode = IntakeConstant.EMPTY_SPACE;
    this.wbsPendingCheckbox = false;
    this.closeoutService
      .getWbsChargeCodes(this.dataOpportunityId)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: WbsResponseModel[]) => {
          if (response) {
            this.wbsObjectArray = response;
            this.searchList = response.map((wbs) => wbs.wbsChargeCode);
            this.reloadWbsChargeCode = false;
            setTimeout(() => {
              this.reloadWbsChargeCode = true;
            }, 10);
          }
        },
        error: (error) => {
          console.log('error', error);
        },
      });
  }

  onWbsChangeValue(): void {
    if (this.isElementInArray(this.wbsChargeCode, this.searchList)) {
      const selectedWBSCode: WbsResponseModel | undefined =
        this.wbsObjectArray.find(
          (wbs) => wbs.wbsChargeCode === this.wbsChargeCode
        );
      if (selectedWBSCode) {
        this.wbsDescription = selectedWBSCode?.wbsDescription;
        this.wbsChargeCode = selectedWBSCode?.wbsChargeCode;
        if (!this.wbsPendingCheckbox) {
          this.wbsWarning = false;
          this.updateWbsValues();
          this.fetchArchiveDropDownData();
          this.showEditWbsChargeCode = false;
        }
      }
    }
  }

  updateWbsValues(): void {
    this.wbsChargeCodeChange.emit({
      wbsChargeCode: this.wbsChargeCode,
      wbsDescription: this.wbsDescription,
      wbsPending: this.wbsPendingCheckbox,
    });
  }

  wbsPendingCheckboxChange(event: boolean): void {
    this.wbsPendingCheckbox = event;
    if (this.wbsPendingCheckbox) {
      this.wbsChargeCode = IntakeConstant.EMPTY_SPACE;
      this.wbsDescription = IntakeConstant.EMPTY_SPACE;
      this.wbsInputDisabled = true;
    } else {
      this.wbsInputDisabled = false;
    }
  }

  isElementInArray(element: string | null, array: string[]): boolean {
    if (element) return array.includes(element);
    else return false;
  }

  onArchiveSelect(): void {
    this.showArchiveDropdown = false;
    const selectedArchiveDetails = this.dropdownListData.archiveDetails.find(
      (item) => item.archiveNumber === this.selectedArchive
    );

    const updateArchiveDetails: ArchiveDetailsUpdatingModel = {
      archiveNumber:
        selectedArchiveDetails?.archiveNumber ?? IntakeConstant.EMPTY_SPACE,
      archiveName:
        selectedArchiveDetails?.archiveName ?? IntakeConstant.EMPTY_SPACE,
      archiveID:
        selectedArchiveDetails?.archiveID ?? IntakeConstant.EMPTY_SPACE,
      archiveFolder:
        selectedArchiveDetails?.archiveFolder ?? IntakeConstant.EMPTY_SPACE,
      archiveFoldersArray: selectedArchiveDetails?.archiveFoldersArray ?? [],
      clientName:
        selectedArchiveDetails?.clientName ?? IntakeConstant.EMPTY_SPACE,
      clientNumber:
        selectedArchiveDetails?.clientNumber ?? IntakeConstant.EMPTY_SPACE,
      partner: selectedArchiveDetails?.partner ?? IntakeConstant.EMPTY_SPACE,
      business: selectedArchiveDetails?.business ?? IntakeConstant.EMPTY_SPACE,
      archiveDescription: IntakeConstant.EMPTY_SPACE,
      archiveStatus: IntakeConstant.EMPTY_SPACE,
    };

    this.archiveDescription =
      selectedArchiveDetails?.archiveNumber +
      ' ' +
      selectedArchiveDetails?.archiveName;
    this.closeoutService
      .postGetArchiveDetails(this.selectedArchive)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: ArchiveDetailsExtendedModel) => {
          if (response) {
            updateArchiveDetails.archiveDescription =
              response.archiveDescription;
            updateArchiveDetails.archiveStatus = response.archiveStatus;
            this.wbsArchiveChange.emit(updateArchiveDetails);
          }
        },
        error: (error) => {
          this.progressIndicator.hide();
          console.log('error', error);
        },
      });
  }

  fetchArchiveDropDownData(): void {
    this.progressIndicator.show();
    this.closeoutService
      .postSearchArchiveByWbs(this.wbsChargeCode)
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe({
        next: (response: ArchiveDetailsResponseModel) => {
          if (response) {
            this.dropdownListData = response;
            this.progressIndicator.hide();
            this.actionDropdownList = this.dropdownListData.archiveDetails.map(
              (item: ArchiveDetailsModel) => {
                return {
                  heading: item.archiveNumber + ' ' + item.archiveName,
                  value: item.archiveNumber,
                };
              }
            );
          }
        },
        error: (error) => {
          this.progressIndicator.hide();
          console.log('error', error);
        },
      });
  }

  editArchiveDetails(): void {
    this.showArchiveDropdown = true;
  }

  showDescriptionAndArchive(): boolean {
    return (
      (this.wbsChargeCode !== IntakeConstant.EMPTY_SPACE ||
        this.wbsChargeCode !== null) &&
      !this.showEditWbsChargeCode
    );
  }

  ngOnDestroy(): void {
    this.#unsubscriber$.next();
    this.#unsubscriber$.complete();
  }
}
