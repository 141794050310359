<div class="nav-item-content d-flex align-items-baseline">
  <div class="icon-info-display align-items-baseline d-flex">
    <img src="\assets\images\file-icon.svg" alt="file img" />
    <div>
      <h4
        class="eclipse-content no-break no-scroll"
        [ddsTooltip]="item.submissionTitle"
      >
        {{ item.submissionTitle }}
      </h4>
      <div class="sub-info d-flex font-size-12">
        <span class="item-type">{{ item.submissionTypeCode }}</span>
        <span
          class="status-padding"
          [style.color]="
            transformStatus(item.submissionStatusName) | statusStyle : 'color'
          "
          [style.backgroundColor]="
            transformStatus(item.submissionStatusName)
              | statusStyle : 'backgroundColor'
          "
          >{{ item.submissionStatusName }}</span
        >
      </div>
    </div>
  </div>
  <div
    (click)="onShowMore()"
    class="show-more font-size-12 cursor-clickable dds-header__container"
  >
    <span>See more </span>
    <span> <img src="\assets\images\Right icon.png" /></span>
  </div>
</div>

<div class="margin-left-s">
  <div>
    <div
      *ngIf="!item.isFormCompleted"
      class="styled-box label-expand font-size-12 padding-left-sm"
    >
      <span>Complete required action(s)</span>
    </div>

    <div *ngIf="item.isFormCompleted">
      <div *ngIf="isSubmitBtnVisible() || isResubmitBtnVisibleForSummary()">
        <div *ngIf="!updateSubmitButtonStatus()">
          <div
            *ngIf="checkForSelfReviewEligibilityStatus()"
            class="d-flex btn-gap"
          >
            <dds-button
              [size]="smallButton.size ?? ''"
              [width]="smallButton.width ?? ''"
              [ariaLabel]="'Self-review'"
              (clicked)="submitCommonSectionDataForSummary()"
            >
              Self-review
            </dds-button>

            <dds-button
              [size]="smallButton.size ?? ''"
              [width]="smallButton.width ?? ''"
              [theme]="smallButton.theme ?? ''"
              [kind]="smallButton.kind ?? ''"
              [ariaLabel]="'Opt out of self-review'"
              (clicked)="openDocumentEditModel()"
            >
              Opt out of self-review
            </dds-button>
          </div>
          <div>
            <dds-button
              *ngIf="
                isSubmitBtnVisible() && !checkForSelfReviewEligibilityStatus()
              "
              [size]="smallButton.size ?? ''"
              [width]="smallButton.width ?? ''"
              [ariaLabel]="'Submit'"
              (click)="submitCommonSectionDataForSummary()"
            >
              Submit
            </dds-button>

            <dds-button
              *ngIf="
                isResubmitBtnVisibleForSummary() &&
                !checkForSelfReviewEligibilityStatus()
              "
              (clicked)="onclickResubmitForReviewBtnForSummary()"
              [size]="smallButton.size ?? ''"
              [ariaLabel]="'Resubmit'"
              [width]="smallButton.width ?? ''"
            >
              Resubmit
            </dds-button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isSubmittedResubmittedSectionVisible()">
      <dds-button
        (click)="onShowMore()"
        [size]="smallButton.size ?? ''"
        [width]="smallButton.width ?? ''"
        [ariaLabel]="'Complete review'"
        >Complete review</dds-button
      >
    </div>
    <div>
      <div *ngIf="isPendingCloseoutVisible()" class="d-flex btn-gap">
        <dds-button
          *ngIf="showArchiveButtonForSummary()"
          [size]="smallButton.size ?? ''"
          [width]="smallButton.width ?? ''"
          [ariaLabel]="'Archive and complete'"
          (clicked)="onclickArchiveAndCompleteBtnForSummary()"
          >Archive and complete</dds-button
        >
        <dds-button
          *ngIf="showReturnToReworkButtonForSummary()"
          [size]="smallButton.size ?? ''"
          [width]="smallButton.width ?? ''"
          [ariaLabel]="'Resubmit for review'"
          (clicked)="handleReturnforReworkForSummary()"
          >Resubmit for review</dds-button
        >
      </div>
    </div>
  </div>
</div>

<hr />
