import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { SubmissionService } from '../../../../../../http/intake/submission.service';
import { SelectItemOptions } from '@usitsdasdesign/dds-ng/select';
import { Subject, takeUntil } from 'rxjs';
import { IntakeConstant } from '../../../../../constants/intake.constant';
import { DocumentStatus, DocumentType } from '../../../../../../common/models/document-dropdown.models';

@Component({
  selector: 'app-document-edit-popup',
  templateUrl: './document-edit-popup.component.html',
  styleUrl: './document-edit-popup.component.less',
})
export class DocumentEditPopupComponent implements OnInit {
  @Input() public documentInitiatedDetails: any = {};
  unsubscriber$: Subject<void> = new Subject<void>();

  constructor(
    public readonly modalService: ModalService,
    private readonly intakeDetailService: SubmissionService
  ) {}

  documentTitle: string = this.documentInitiatedDetails.documentTitle;
  documentComment: string = this.documentInitiatedDetails.comments;
  documentTypeId: number = 0;
  documentStatusId: number = 0;
  documentTypeItemsList: SelectItemOptions[] = [];
  documentStatusItemList: SelectItemOptions[] = [];
  allStatusAvailable: SelectItemOptions[] = [];
  documentTypeMap!: number;
  documeentStatusMap!: number;
  documentTypeDropdownData: string = '';
  documentStatusDropdownData: string = '';
  unchangedDocumentTypeList: DocumentType[] = [];
  unchangedDocumentStatusList: DocumentStatus[] = [];
  isSaveDisabled: boolean = false;
  otherStatus: number = IntakeConstant.OTHER_STATUS;

  ngOnInit(): void {
    this.documentTitle = this.documentInitiatedDetails.documentTitle;
    this.documentComment = this.documentInitiatedDetails.comments;
    this.documentTypeMap = this.documentInitiatedDetails.documentTypeId;
    this.documeentStatusMap = this.documentInitiatedDetails.documentStatusId;
    this.getDocumentTypeDropdownData(
      this.documentInitiatedDetails.submissionTypeId
    );
    this.getDocumentStatusDropdownData(
      this.documentInitiatedDetails.submissionStatusId,
      this.documentInitiatedDetails.submissionTypeId
    );
  }

  getDocumentStatusDropdownData(id: number, typeId: number): void {
  this.intakeDetailService.getDocumentStatusDropdown(id, typeId).subscribe({
    next: (result: DocumentStatus[]) => {
      this.unchangedDocumentStatusList = result;
      const convertedArray = result.map((item: DocumentStatus) => ({
        value: item.documentStatusName,
        heading: item.documentStatusName,
      }));
      this.documentStatusItemList = convertedArray;
      this.allStatusAvailable = convertedArray;
      if (this.documentStatusItemList) this.prepopulateStatusDropdown();
    },
    error: (err: any) => {
      console.log(err);
    },
  });
}

  getDocumentTypeDropdownData(id: number): void {
  this.intakeDetailService.getDocumentTypeDropdown(id).subscribe({
    next: (result: DocumentType[]) => {
      this.unchangedDocumentTypeList = result;
      const convertedArray = result.map((item: DocumentType) => ({
        value: item.documentTypeName,
        heading: item.documentTypeName,
      }));
      this.documentTypeItemsList = convertedArray;
      if (this.documentTypeItemsList) this.prepopulateTypeDropdown();
    },
    error: (err: any) => {
      console.log(err);
    },
  });
}

  updateStatusDropdown(): void {
    if (
      this.documentInitiatedDetails.type ===
      IntakeConstant.CHANGE_ORDER_LABEL_CODE
    ) {
      if (
        this.documentTypeMap === IntakeConstant.ENGAGEMENT_LETTER_CODE ||
        this.documentTypeMap ===
          IntakeConstant.ORIGNAL_Letter_SOW_Contract_CODE ||
        this.documentTypeMap === IntakeConstant.ORIGNAL_Letter_CODE
      ) {
        this.documentStatusItemList = this.allStatusAvailable;
      } else {
        this.documentStatusItemList = this.documentStatusItemList.filter(
          (item) => item.heading !== IntakeConstant.FINAL_ARCHIVE_READY_VERSION
        );
        if (this.documeentStatusMap === IntakeConstant.FINAL_ARCHIVE_READY_ID) {
          this.documeentStatusMap = 0;
          this.documentStatusDropdownData = '';
          this.isSaveDisabled = true;
        }
      }
    }
  }

  async editDocument(): Promise<void> {
    const postRequestData = {
      submissionDocumentId: this.documentInitiatedDetails.submissionDocumentId,
      submissionId: this.documentInitiatedDetails.submissionId,
      documentTypeId: this.documentTypeMap,
      documentStatusId: this.documeentStatusMap,
      submissionDocumentTypeId:
        this.documentInitiatedDetails.submissionDocumentTypeId,
      documentTitle: this.documentTitle,
      comments: this.documentComment,
      modifiedBy: '00000000',
    };
    return new Promise((resolve, reject) => {
      this.intakeDetailService
        .postDocumentEdit(postRequestData)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: any) => {
            resolve();
          },
          error: (err) => {
            console.error('An error occurred during document edit:', err);
            reject(err);
          },
        });
    });
  }

  async closeAll(): Promise<void> {
    try {
      await this.editDocument();
    } catch (error) {
      console.log(error);
    }
    this.close();
  }

  close(): void {
    this.modalService.close();
  }

  updateTypeId(): void {
  const selectedType = this.unchangedDocumentTypeList.find(
    (item: DocumentType) => item.documentTypeName === this.documentTypeDropdownData
  )?.documentTypeId;
  this.documentTypeMap = Number(selectedType) 
  this.updateStatusDropdown();
}

updateStatusId(): void {
  const selectedStatus = this.unchangedDocumentStatusList.find(
    (item: DocumentStatus) => item.documentStatusName === this.documentStatusDropdownData
  )?.documentStatusId
  this.documeentStatusMap = Number(selectedStatus); 
  this.isSaveDisabled = false;
}

  prepopulateTypeDropdown(): void {
    this.documentTypeDropdownData =
      this.documentInitiatedDetails.documentTypeDropdownType;
    this.updateTypeId();
  }

  prepopulateStatusDropdown(): void {
    this.documentStatusDropdownData =
      this.documentInitiatedDetails.documentStatusDropdownType;
    this.updateStatusId();
  }

  isSaveDisabledCheck(): boolean {
    if (this.documentTypeMap === this.otherStatus) {
      if (this.documentComment.length > 0 && !this.isSaveDisabled) {
        return false;
      }
      return true;
    }
    return this.isSaveDisabled;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
