import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { START_PAGE_COMPONENTS } from './';
import { FormsModule } from '@angular/forms';
import { LibModule } from '@usitsdasdesign/dds-ng';
import { EclipseCommonModule } from '../common/common.module';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { NcaSpecialistGridAreaItemComponent } from './nca-specialist-dashboard/nca-specialist-gridarea-item/nca-specialist-gridarea-item.component';
import { FooterComponent } from './footer/footer.component';
import { RecentlyViewedComponent } from './recently-viewed/recently-viewed.component';
import { StatusTranformPipe } from './status-transform.pipe/status-tranform.pipe';
import { EngagementDashboardComponent } from './engagement-dashboard/engagement-dashboard.component';
import { RiskmanagerDashboardComponent } from './riskmanager-dashboard/riskmanager-dashboard.component';

@NgModule({
  declarations: [START_PAGE_COMPONENTS, FooterComponent, RecentlyViewedComponent, StatusTranformPipe, EngagementDashboardComponent, RiskmanagerDashboardComponent],
  imports: [
    CommonModule,
    FormsModule,
    LibModule,
    EclipseCommonModule,
    NgbPopover,
  ],
  exports: [NcaSpecialistGridAreaItemComponent],
})
export class StartPageModule {}
