import { RadioButton, RadioGroupOptions } from '@usitsdasdesign/dds-ng/radio';
import { Column, ErrorState } from '@usitsdasdesign/dds-ng/shared';
import { IntakeConstant } from '../../../constants/intake.constant';
import { TooltipOptions } from '@usitsdasdesign/dds-ng/tooltip';

export interface ExampleData {
  name: string;
  primaryIndustry: string;
  offeringPortfolio: string;
  primaryOffering: string;
}

export interface ExampleMembers extends ExampleData {
  poc: boolean;
  notifications: boolean;
  id: number;
}

export const PocTooltipOptions: TooltipOptions = {
  tooltipInvokeType: 'hover',
  tooltipWidth: 238,
  tooltipPosition: 'top',
  tooltipIndent: 15,
  tooltipIsOutsideClick: false,
  tooltipHasBeak: true,
  tooltipIsDisabled: false,
  tooltipIsInverse: false,
  tooltipIsDynamic: false,
  tooltipCustomClass: '',
  tooltipType: 'regular',
  tooltipSize: 'md',
  tooltipShowDelay: 0,
  tooltipHideDelay: 0,
  tooltipTheme: IntakeConstant.INTAKE_INFO_TOOLTIP_THEME_COLOR,
};

export const LEP_TABLE_COLUMNS: Column[] = [
  { name: 'name', header: 'Name', dataType: 'string', minWidth: '34%' },
  {
    name: 'primaryIndustry',
    header: 'Primary Industry',
    dataType: 'string',
    minWidth: '21.68%',
  },
  {
    name: 'offeringPortfolio',
    header: 'Offering Portfolio',
    dataType: 'string',
    minWidth: '21.68%',
  },
  {
    name: 'primaryOffering',
    header: 'Primary Offering',
    dataType: 'string',
    minWidth: '21.68%',
  },
];

export const TEAM_MEMBER_COLUMN: Column[] = [
  { name: 'name', header: 'Name', dataType: 'string', minWidth: '34.9%' },
  {
    name: 'primaryIndustry',
    header: 'Primary Industry',
    dataType: 'string',
    minWidth: '13.27%',
  },
  {
    name: 'offeringPortfolio',
    header: 'Offering Portfolio',
    dataType: 'string',
    minWidth: '13.27%',
  },
  {
    name: 'primaryOffering',
    header: 'Primary Offering',
    dataType: 'string',
    minWidth: '13.27%',
  },
  {
    name: 'poc',
    header: 'POC',
    dataType: 'string',
    minWidth: '7.68%',
  },
  {
    name: 'notifications',
    header: 'Notifications',
    dataType: 'string',
    minWidth: '10.27%',
  },
  {
    name: 'actions',
    header: 'Actions',
    dataType: 'string',
    minWidth: '7.28%',
  },
];
