<div class="margin-top-xxs relative">
  <div class="search-box" *ngIf="!showSelected">
    <dds-search
      [size]="searchOptions.size ?? ''"
      [customClass]="searchOptions.customClass ?? ''"
      [icon]="searchOptions.icon || 'dds-icon_search'"
      (fieldCleared)="fieldCleared()"
    >
      <input
        ddsSearch
        [placeholder]="searchOptions.placeholder ?? ''"
        [(ngModel)]="searchedUser"
        (ngModelChange)="findUsers($event)"
      />
    </dds-search>
  </div>
  <div
    *ngIf="showdropDown"
    class="list-of-oppportunities d-flex flex-column padding-xs margin-top-xs absolute z-index box-shadow-xs"
  >
    <div *ngIf="userList.length > 0; else noUsers">
      <div *ngFor="let item of userList">
        <div
          class="opportunity-items d-flex flex-column margin-bottom-vertical-s"
          (click)="fetchUserDetails(item)"
        >
          <div
            class="opportunity-details font-size-11 text-align-left font-bold-400"
          >
            <span
              class="opportunity-details-name font-size-12 font-bold-400 text-align-left"
              >{{ item?.displayName ?? item?.fullName }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <ng-template #noUsers>
      <div class="no-users-found">
        {{ notFound }}
      </div>
    </ng-template>
  </div>
</div>
