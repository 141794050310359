<div class="text-align-left padding-top-m">
  <div class="d-flex">
    <span
      *ngIf="clientSummaryData?.clientName"
      class="font-size-12 padding-top-xxs"
      [style.color]="
        transformStatus(clientSummaryData!.clientStatus ) | statusStyle : 'color'
      "
      >{{ clientSummaryData?.clientName }}</span
    >
    <span
      ><span class="eclipse-small-header-grey">SWIFT client ID:</span>
      <span class="eclipse-content">{{
        " " + clientSummaryData?.clientNumber
      }}</span></span
    >
  </div>
  <span
    *ngIf="clientSummaryData?.clientStatus"
    class="font-size-12 px-25"
    [style.color]="
      transformStatus(clientSummaryData!.clientStatus) | statusStyle : 'color'
    "
    [style.backgroundColor]="
      transformStatus(clientSummaryData!.clientStatus)
        | statusStyle : 'backgroundColor'
    "
    >{{ clientSummaryData?.clientStatus }}</span
  >
  <div class="d-flex gap-80 padding-top-m">
    <div class="">
      <p class="eclipse-small-header-grey">Industry</p>
      <p class="eclipse-content">
        {{
          clientSummaryData?.clientIndustry +
            ": " +
            clientSummaryData?.clientIndustrySector
        }}
      </p>
    </div>
    <div class="">
      <p class="eclipse-small-header-grey">Program account</p>
      <p class="eclipse-content">{{ clientSummaryData?.acpDesignation }}</p>
    </div>
  </div>
  <div class="">
    <p class="eclipse-small-header-grey">Global Crown Jewel</p>
    <p *ngIf="clientSummaryData?.isGlobalCrownJewel" class="eclipse-content">
      GCJ
    </p>
    <!-- TODO 5402524
 Indicates that the account is either a Global Crown Jewel or a Global Strategic account. If neither, select Non-Program Client) -->
  </div>
</div>
