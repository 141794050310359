import { Component, OnDestroy } from '@angular/core';
import { ButtonComponent, ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { SubmissionService } from '../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import { DocumentService } from '../../http/document/document.service';
import {
  Column,
  DocumentTableData,
  sampleColumns,
} from './export-pdf-popup-component-helper';
import {
  ButtonKind,
  ExtThemes,
  WidthState,
} from '@usitsdasdesign/dds-ng/shared';
import { IntakeConstant } from '../../intake/constants/intake.constant';
@Component({
  selector: 'app-export-pdf-popup',
  templateUrl: './export-pdf-popup.component.html',
  styleUrl: './export-pdf-popup.component.less',
})
export class ExportPdfPopupComponent implements OnDestroy {
  submissionID: number | null = null;
  opportunityNumber!: string;
  unsubscriber$: Subject<void> = new Subject<void>();
  docMetaData: any;
  selectedDocumentIds: number[] = [];

  constructor(
    private readonly modalService: ModalService,
    private readonly submissionService: SubmissionService,
    private readonly documentService: DocumentService,
    private readonly submissioService: SubmissionService
  ) {}
  sampleColumns: Column[] = sampleColumns;
  docToShow: DocumentTableData[] = [];
  isAllSelected: boolean = false;
  selectedRowCount: number = 0;

  cancelButtonOptions: ButtonOptions = {
    theme: ExtThemes.green,
    kind: ButtonKind.primary,
    size: IntakeConstant.UI_ELEMENT_SIZE,
    width: WidthState.fixed,
    isLoading: false,
    isIconLeft: false,
    isColorBg: false,
    isInverse: false,
    disabled: true,
    customClass: '',
    role: 'button',
  };

  ngOnInit(): void {
    this.submissioService.currentSubmissionId$.subscribe((request) => {
      this.submissionID = request.submissionid;
      this.opportunityNumber = request.opportunitynumber;
    });
    this.loadDocumentTableData();
  }

  loadDocumentTableData(): void {
    this.submissionService
      .getDocumentTableData(+this.submissionID!)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: any) => {
          this.docMetaData = data;
          this.docToShow = data;
          this.docToShow.forEach((item: any) => {
            item.selected = false;
          });
        },
        error: (err) => {
          console.error('An error occurred during fetching the data ', err);
        },
      });
  }

  toggleRow(rowData: DocumentTableData): void {
    rowData.selected = !rowData.selected;
    this.checkSelectedRow(this.docToShow);
  }

  selectAllRows(status: boolean, data: DocumentTableData[]): void {
    data.forEach((item) => (item.selected = status));
    this.checkSelectedRow(this.docToShow);
  }

  checkSelectedRow(data: DocumentTableData[]): void {
    this.selectedRowCount = data.filter((row) => row.selected).length;
    this.isAllSelected = data.length === this.selectedRowCount;
  }

  exportSelectedDocs(): void {
    this.docToShow
      .filter((document) => document.selected)
      .forEach((document) =>
        this.selectedDocumentIds.push(document.submissionDocumentId)
      );

    let requestBody = {
      submissionid: this.submissionID,
      opportunitynumber: this.opportunityNumber,
      DocumentIds: this.selectedDocumentIds,
    };

    this.documentService.downloadPdf(requestBody).subscribe({
      next: (response: any) => {
        const blob = new Blob([response.body], { type: 'application/pdf' });
        const contentDisposition = response.headers.get('Content-Disposition');
        let fileName = 'Submission.pdf'; // Default file name

        if (contentDisposition) {
          const matches = /filename="([^"]*)"/.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1];
          }
        }

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        this.close(true);
      },
      error: (error) => {
        console.error('Download error:', error);
      },
    });
  }

  close(isConfirmed: boolean): void {
    this.modalService.close();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
