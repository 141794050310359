<div class="dds-modal-overlay" (click)="close()"></div>

<div ddsResize class="dds-modal dds-modal_md">
  <div class="dds-modal__header">
    <span class="dds-modal__title">Reason for opt-out</span>
    <dds-button
      size="sm"
      icon="dds-icon_close"
      kind="silent"
      theme="dark"
      ariaLabel="Close modal"
      class="dds-modal__close"
      (clicked)="close()"
    ></dds-button>
  </div>

  <div class="dds-modal__body">
    <p class="dec font-size-14">
      Opting-out from Self-review will require this submission to be reviewed by
      the risk manager.
    </p>

    <div class="inputs-container">
      <div class="margin-bottom-l">
        <p class="input-label font-size-12">
          Enter reason for opting-out this submission of Self-review
        </p>
        <dds-textarea
          [minHeight]="minHeight"
          [ariaLabel]="'Enter the reason for opting out'"
          [(ngModel)]="optOutReason"
          [placeholder]="'Write a reason'"
          [label]="textareaOptions.label"
          [labelPosition]="textareaOptions.labelPosition"
          [description]="textareaOptions.description"
          [minHeight]="textareaOptions.minHeight"
          [isRequired]="textareaOptions.isRequired"
          [customClass]="textareaOptions.customClass"
          [isError]="textareaOptions.isError"
          [errorMessage]="textareaOptions.errorMessage"
          [errorState]="textareaOptions.errorState"
          [maxLength]="textareaOptions.maxLength"
          [maxLengthPosition]="textareaOptions.maxLengthPosition"
          [readonly]="textareaOptions.readonly"
        ></dds-textarea>
      </div>
    </div>
  </div>

  <div class="dds-modal__footer">
    <div class="dds-modal__footer-content dds-modal__footer-content_left">
      <dds-button
        class="custom-button"
        [ariaLabel]="'Opt out of self-review'"
        class="dds-modal__footer-item"
        (clicked)="save()"
        (keyboardClicked)="save()"
        [disabled]="optOutReason.length < 15"
        >Opt out of self-review</dds-button
      >
      <dds-button
        class="custom-button"
        [ariaLabel]="'Close'"
        class="dds-modal__footer-item"
        kind="secondaryLoud"
        (clicked)="close()"
        (keyboardClicked)="close()"
        >Cancel</dds-button
      >
    </div>
  </div>
</div>
