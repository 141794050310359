import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DocumentEditPopupComponent } from '../document-edit-popup/document-edit-popup.component';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import {
  Column,
  toast,
  ToggleGroupOptions,
  ToggleOptions,
} from './document-table-settings';
import { ToggleComponent } from '@usitsdasdesign/dds-ng/toggle';
import { SubmissionService } from '../../../../../../http/intake/submission.service';
import { delay, Subject, takeUntil } from 'rxjs';
import { ProgressIndicatorService } from '../../../../../../common/services/progress-indicator.service';
import { IntakeDocumentTableModel } from '../../../../../../common/models/intake-document-table.model';
import { DocumentService } from '../../../../../../http/document/document.service';
import {
  IntakeConstant,
  VALID_DOCUMENT_TYPES,
} from '../../../../../constants/intake.constant';
import { IntakeDocumentSubmissionTypeCode } from '../../../../../constants/intake_submission_type_code.enum';
import { StatusEnum } from '../../../../../constants/status-enum';
import { IntakeOppertunitySubmissionStatus } from '../../../../../constants/intake_oppertunity_submission_status.enum';
import { ToastService } from '@usitsdasdesign/dds-ng/toast';
import { RoleEnum } from '../../../../../constants/Role.enum';
import {
  DocumentArchivalStatus,
  DocumentStatus,
} from '../../../../../constants/document-status.enum';
import {
  CheckboxGroupOptions,
  CheckboxOptions,
} from '@usitsdasdesign/dds-ng/checkbox';
import { Themes, ErrorState } from '@usitsdasdesign/dds-ng/shared';

@Component({
  selector: 'app-document-table',
  templateUrl: './document-table.component.html',
  styleUrl: './document-table.component.less',
})
export class DocumentTableComponent implements OnChanges, OnDestroy {
  @Input() submissionId: number = 0;
  @Input() submissionTypeId: number = 0;
  @Input() submissionStatusId: number = 0;
  @Input() type: string = '';
  @Input() isEditable: boolean = false;
  @Input() submissionStatus: string | undefined = '';
  // TODO remove static values
  enumRoles: string[] = Object.values(RoleEnum);
  role: string = '';
  // TODO : NEED TO REMOVE THIS FUNCTIONALITY
  getRandomRole(): string {
    const randomIndex = Math.floor(Math.random() * this.enumRoles.length);
    return this.enumRoles[randomIndex];
  }
  @Input() set progressiveStatus(status: boolean | null) {
    setTimeout(() => {
      if (status != null) {
        this.updateServerValue(status);
      }
    }, 150);
  }
  @Output() documentTableData: EventEmitter<IntakeDocumentTableModel[]> =
    new EventEmitter<IntakeDocumentTableModel[]>();

  unsubscriber$: Subject<void> = new Subject<void>();
  groupOptions = ToggleGroupOptions;
  @ViewChild('toggle', { static: false })
  toggle!: ToggleComponent;
  options = ToggleOptions;
  toggleOpen: boolean = false;
  docToShow: IntakeDocumentTableModel[] = [];
  public docMetaData: IntakeDocumentTableModel[] = [];
  toasteOption = toast;
  public sampleColumns = Column;
  constructor(
    private readonly modalService: ModalService,
    private readonly intakeDetailService: SubmissionService,
    private readonly progressIndicator: ProgressIndicatorService,
    private readonly documentService: DocumentService,
    private readonly toastService: ToastService
  ) {}

  private updateStatus(): void {
    const validTypes =
      VALID_DOCUMENT_TYPES[this.type as IntakeDocumentSubmissionTypeCode];
    if (this.type === IntakeConstant.CHANGE_ORDER_LABEL_CODE) {
      const status = this.docMetaData.some(
        (item) =>
          validTypes.includes(item.documentType) &&
          item.documentStatus === IntakeConstant.FINAL_ARCHIVE_READY_VERSION
      );
      this.intakeDetailService.docISValid.next(status);
    } else {
      const status = this.docMetaData.some((item) =>
        validTypes.includes(item.documentType)
      );
      this.intakeDetailService.docISValid.next(status);
    }
  }
  archiveItHeader: string = IntakeConstant.DOCUMENT_TABLE_ARCHIVE_HEADING;
  showArchiveIt: boolean = false;

  checkBoxGroupOptions: CheckboxGroupOptions = {
    title: '',
    isRequired: false,
    isInverse: false,
  };

  checkBoxOptions: CheckboxOptions = {
    label: '',
    theme: Themes.green,
    isIndeterminate: false,
    isError: false,
    isRequired: false,
    errorState: ErrorState.default,
    ariaLabel: 'checkbox',
    customClass: '',
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['submissionId'] &&
      changes['submissionId'].currentValue &&
      changes['submissionStatus']
    ) {
      this.showArchiveIt = false;
      this.getDocumentMetaData();
      // TODO: Remove hardcoded roles check
      this.role = this.getRandomRole();
      if (
        this.submissionStatus ===
          IntakeOppertunitySubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT ||
        this.submissionStatus ===
          IntakeOppertunitySubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT
      ) {
        this.showArchiveIt = true;
      }
      this.isEditable = false;
    }
  }

  stateChanged(event: boolean): void {
    this.updateServerValue(event);
  }

  updateServerValue(event: boolean): void {
    this.filterDocumentData(event);
    if (this.toggleOpen !== event) {
      setTimeout(() => this.toggle?.toggleState(true), 100);
    }
  }

  filterDocumentData(draftHidden: boolean): void {
    if (draftHidden) {
      this.docToShow = this.docMetaData.filter(
        (doc: any) =>
          ![DocumentStatus.Draft, DocumentStatus.SupportingDocument].includes(
            doc.documentStatus
          )
      );
    } else {
      this.docToShow = this.docMetaData;
    }
  }

  onDownload(event: IntakeDocumentTableModel): void {
    if (event.archivalStatus == DocumentArchivalStatus.ARCHIVED) {
      if (event.attachmentId) {
        this.documentService
          .getArchivedDocDownloadUrl(event.attachmentId)
          .subscribe({
            next: (response: any) => {
              if (response?.documentDownloadUrl) {
                window.open(response.documentDownloadUrl, '_blank');
              }
            },
            error: (err: any) => {},
          });
      }
    } else {
      let downloadRequest = {
        DocumentName: event.documentTitle,
        DocumentKey: event.attachmentGuid,
        FileFolderPath: 'Intake',
        archivalStatus: event.archivalStatus == DocumentArchivalStatus.ARCHIVED,
      };
      this.documentService.getDownloadUrl(downloadRequest).subscribe({
        next: (response: any) => {
          if (response != null && response.documentDownloadUrl != null) {
            window.open(response.documentDownloadUrl, '_blank');
          }
        },
        error: (err: any) => {},
      });
    }
  }

  openLink(item: string): void {
    if (item) {
      window.open(item, '_blank');
    }
  }

  onEdit(item: IntakeDocumentTableModel, modalBtn?: any): void {
    let modalRef = this.modalService.open(DocumentEditPopupComponent, {
      isFooter: true,
      size: 'lg',
      isInverse: false,
      documentInitiatedDetails: {
        moduleName: 'Intake',
        submissionStatusId: this.submissionStatusId,
        submissionTypeId: this.submissionTypeId,
        comments: item.comments,
        submissionDocumentId: item.submissionDocumentId,
        documentTitle: item.documentTitle,
        submissionDocumentTypeId: item.submissionDocumentTypeId,
        submissionId: this.submissionId,
        documentTypeId: item.documentTypeId,
        documentStatusId: item.documentStatusId,
        type: this.type,
        attachmentId: item.attachmentId,
        documentTypeDropdownType : item.documentType,
        documentStatusDropdownType : item.documentStatus,
      },
    });
    modalRef.onClosed().subscribe((response: any) => {
      this.getDocumentMetaData();
      if (modalBtn) {
        modalBtn.focus();
      }
    });
  }

  onDelete(item: IntakeDocumentTableModel): void {
    if (
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT &&
      this.role != RoleEnum.PERSUIT_TEAM
    ) {
      const allowedStatuses = [
        StatusEnum.RMReviewed,
        IntakeOppertunitySubmissionStatus.FINAL_ARCHIVE_READY_VERSION,
      ];
      const otherObjectsWithAllowedStatus = this.docMetaData.filter(
        (obj) =>
          obj.submissionDocumentId !== item.submissionDocumentId &&
          allowedStatuses.includes(item.documentStatus as StatusEnum)
      );
      if (otherObjectsWithAllowedStatus.length === 0) {
        this.showToast();
        return;
      }
    }

    this.intakeDetailService
      .deleteDocument(item.submissionDocumentId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: any) => {
          // TODO: document delete response are not calling so we have to call getDocumentMetaData here
          this.getDocumentMetaData();
        },
        error: (err: any) => {
          console.log('Error in delete document data', err);
        },
      });

    this.docMetaData = this.docMetaData.filter(
      (doc: { submissionDocumentId: number }) =>
        doc.submissionDocumentId !== item.submissionDocumentId
    );
    this.docToShow = this.docToShow.filter(
      (doc: { submissionDocumentId: number }) =>
        doc.submissionDocumentId !== item.submissionDocumentId
    );
    this.updateStatus();
    this.documentTableData.emit(this.docToShow);
  }

  getDocumentMetaData(): void {
    this.progressIndicator.show();
    this.intakeDetailService
      .getDocumentTableData(this.submissionId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: any) => {
          this.docMetaData = data.map((item: IntakeDocumentTableModel) => ({
            ...item,
            isChecked: this.setItemCheckedStatus(item),
            isDisabled: this.diableArchiveIt(item.documentStatus),
            ariaLabel: item.documentTitle + ' checkbox',
            isVisible: item.archivalStatus === null,
            docArchiveStatus: this.getDocumentStatus(item.archivalStatus ?? ''),
          }));
          this.updateStatus();
          this.filterDocumentData(this.toggleOpen);
          this.documentTableData.emit(this.docToShow);
        },
        error: (err: any) => {
          console.log('Error in fetching document data', err);
        },
      });
    this.progressIndicator.hide();
  }

  getDocumentStatus(archivalStatus: string): string {
    return archivalStatus != null
      ? String(archivalStatus).charAt(0).toUpperCase() +
          String(archivalStatus).slice(1)
      : '';
  }

  setItemCheckedStatus(item: IntakeDocumentTableModel): boolean {
    if (
      item.isArchiveReady === null &&
      item.documentStatus === DocumentStatus.FinalArchiveReadyVersion
    ) {
      this.updateDocumentArchiveState(item, true);
    }
    return (
      item.isArchiveReady ||
      item.documentStatus === DocumentStatus.FinalArchiveReadyVersion ||
      !!item.isArchiveReady
    );
  }

  transformStatus(status: string): StatusEnum {
    return (
      Object.values(StatusEnum).find((enumValue) => enumValue === status) ||
      StatusEnum.Draft
    );
  }

  showToast(): void {
    this.toastService.createToast(this.toasteOption);
  }

  isDeleteEditBtnVisible(): boolean {
    if (
      this.submissionStatus ===
      IntakeOppertunitySubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT
    ) {
      return this.role != RoleEnum.PERSUIT_TEAM && !this.isEditable;
    }
    return !this.isEditable;
  }

  // TODO: Impletement this function once checkbox for transfer to archive is available
  // public toggleRow(rowData: ExampleData): void {
  //   rowData.selected = !rowData.selected;
  // }

  diableArchiveIt(docStatus: string): boolean {
    return (
      docStatus ===
      IntakeOppertunitySubmissionStatus.FINAL_ARCHIVE_READY_VERSION
    );
  }

  updateRow(item: IntakeDocumentTableModel) {
    if (!item.isDisabled) {
      this.docToShow = this.docToShow.map((doc) => ({
        ...doc,
        isChecked:
          doc.submissionDocumentId === item.submissionDocumentId
            ? (doc.isChecked = !doc.isChecked)
            : doc.isChecked,
      }));

      let selectedItem: IntakeDocumentTableModel | undefined =
        this.docToShow.find(
          (doc: IntakeDocumentTableModel) =>
            doc.submissionDocumentId === item.submissionDocumentId
        );
      if (selectedItem) {
        this.updateDocumentArchiveState(
          selectedItem,
          selectedItem.isChecked ?? false
        );
      }
    }
  }

  updateDocumentArchiveState(
    item: IntakeDocumentTableModel,
    isArchiveReady: boolean
  ) {
    const postRequestData = {
      submissionDocumentId: item.submissionDocumentId,
      submissionId: item.submissionId,
      documentTypeId: item.documentTypeId,
      documentStatusId: item.documentStatusId,
      submissionDocumentTypeId: item.submissionDocumentTypeId,
      documentTitle: item.documentTitle,
      comments: item.comments,
      isArchiveReady: isArchiveReady,
      modifiedBy: '00000000',
    };
    return new Promise((resolve, reject) => {
      this.intakeDetailService
        .postDocumentEdit(postRequestData)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: () => {},
          error: (err) => {
            console.error(
              'An error occurred during update document archive status:',
              err
            );
          },
        });
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
