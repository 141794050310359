import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { IntakeConstant } from '../../../constants/intake.constant';
import { Size } from '@usitsdasdesign/dds-ng/shared';
import {
  CANCEL_BTN_LABEL,
  CANCEL_BTN_OPTIONS,
  DISCONTINUE_BTN_LABEL,
  DISCONTINUE_BTN_OPTIONS,
  selectedReasonOptions,
} from './discontinue-modal.component.helper';
import { combineLatest, Subject, takeUntil } from 'rxjs';
import { SubmissionService } from '../../../../http/intake/submission.service';
import { CommonService } from '../../../../http/intake/common.service';
import { IntakeAction } from '../../../../common/models/intake-action.model';
import { IntakeActionEnum } from '../../../constants/intake_action.enum';
import { DiscontinueReasonModel } from '../../../../common/models/discontinue-reason.model';
import { DiscontinuedModalOnCloseRequestModel } from '../../../../common/models/show-all-popup-model';

@Component({
  selector: 'app-discontinue-modal',
  templateUrl: './discontinue-modal.component.html',
  styleUrl: './discontinue-modal.component.less',
})
export class DiscontinueModalComponent implements OnInit, OnDestroy {
  @Input() isFooter!: boolean;
  @Input() size!: string;
  @Input() isInverse!: boolean;

  elementSize: Size = IntakeConstant.UI_ELEMENT_SIZE;
  discontinueReason: number = 0;
  discontinueBtnLabel: string = DISCONTINUE_BTN_LABEL;
  cancelBtnLabel: string = CANCEL_BTN_LABEL;
  discontinueBtnOption: any = DISCONTINUE_BTN_OPTIONS;
  cancelBtnOption: any = CANCEL_BTN_OPTIONS;
  discontinueSelectOptions: any = selectedReasonOptions;
  unsubscriber$: Subject<void> = new Subject<void>();
  discontinueSelectedItems: { value: number; heading: string }[] = [];
  discontinueActionId: number | null = null;
  moveToDiscontinue: DiscontinuedModalOnCloseRequestModel = {
    reasonId: this.discontinueReason,
    actionId: this.discontinueActionId,
  };
  constructor(
    private readonly modalService: ModalService,
    private readonly submissionService: SubmissionService,
    private readonly commonService: CommonService
  ) {}

  ngOnInit(): void {
    combineLatest([this.commonService.intakeActionObserable$])
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (result: [IntakeAction[] | null]) => {
          let response = result[0];
          if (response != null) {
            const action = response?.find(
              (action: IntakeAction) =>
                action.actionName === IntakeActionEnum.DISCONTINUE
            );
            this.discontinueActionId = action ? action.id : null;
            if (this.discontinueActionId) {
              this.submissionService
                .getDiscontinueReasons(this.discontinueActionId)
                .pipe(takeUntil(this.unsubscriber$))
                .subscribe({
                  next: (result: DiscontinueReasonModel[]) => {
                    this.discontinueSelectedItems = result.map(
                      (item: DiscontinueReasonModel) => ({
                        value: item.reasonId,
                        heading: item.reason,
                      })
                    );
                  },
                  error: (err) => {
                    console.error('Error fetching intake actions', err);
                  },
                });
            }
          }
        },
        error: (err) => {
          console.error('Error fetching intake actions', err);
        },
      });
  }

  open(): void {
    this.modalService.open(DiscontinueModalComponent);
  }

  close(): void {
    this.modalService.close(this.moveToDiscontinue);
  }

  onClickDiscontinueBtnLabelBtn(): void {
    const reasonId = Number(this.discontinueReason);
    if (!isNaN(reasonId) && this.discontinueActionId !== undefined) {
      this.moveToDiscontinue.reasonId = reasonId;
      this.moveToDiscontinue.actionId = this.discontinueActionId;
      this.close();
    }
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
