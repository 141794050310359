import { Component, OnInit } from '@angular/core';
import { EntityType, UserService } from '../http/user.service';
import { MsGraphService } from '../http/msgraph.service';
import { combineLatest, filter, Observable, Subject, takeUntil } from 'rxjs';
import { HeaderOptions, HeaderElmnts } from '@usitsdasdesign/dds-ng/header';
import {
  WidthState,
  Themes,
  Size,
  ButtonKind,
  ExtThemes,
  PositionState,
} from '@usitsdasdesign/dds-ng/shared';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { ProfileOptions } from '@usitsdasdesign/dds-ng/profile';
import { SearchOptions } from '@usitsdasdesign/dds-ng/search';
import { NavigationEnd, Router } from '@angular/router';
import {
  DropdownOptions,
  DropdownItemOptions,
} from '@usitsdasdesign/dds-ng/dropdown';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { StartNcaprocessDialogComponentComponent } from '../start-ncaprocess-dialog-component/start-ncaprocess-dialog-component.component';
import { SubmissionService } from '../http/intake/submission.service';
import { IntakeConstant } from '../intake/constants/intake.constant';
import { CommonService } from '../http/intake/common.service';
import { RoleService } from '../http/intake/role.service';
import { RoleEnum } from '../intake/constants/Role.enum';
import {
  ClientAcceptanceStatusAction,
  MarsClientExport,
} from '../common/models/client-acceptance-status-action.model';
import { ClientMilestoneStatus } from '../http/client/client-status-webapi.service';
import { ConsultingNCAConstants } from '../client/constants/consulting-nca-constant.';
import { DocumentService } from '../http/document/document.service';
import { ExportPdfPopupComponent } from './export-pdf-popup/export-pdf-popup.component';

@Component({
  selector: 'app-eclipse-header',
  templateUrl: './eclipse-header.component.html',
  styleUrl: './eclipse-header.component.less',
})
export class EclipseHeaderComponent implements OnInit {
  public displayUserName: string | null = null;
  public userEmail: string | null = null;
  public imageUrl: any = '';
  public enumClientMilestoneStatus = ClientMilestoneStatus;
  isProficPicAvailable: boolean = false;
  isDropdownOpen: boolean = false;
  userRoles: string = '';
  isAdminCompleteVisible: boolean = false;
  isDiscontinueVisible: boolean = false;
  isResetStatusVisible: boolean = false;
  isExportToPdfVisible: boolean = false;
  isClientMilestoneExportVisible: boolean = false;
  isMarsClientExportVisible: boolean = false;
  preveiousIsMarsClientExportVisible: boolean = false;
  clientNumber: string = '';
  clientMilestoneProcessType: string | null= null;
  selectedClientMilestoneId: number = 0;
  headerOptions: HeaderOptions = {
    name: 'Eclipse',
    width: WidthState.full,
    theme: Themes.green,
    isResponsive: false,
    isInverse: false,
    customClass: '',
  };

  responsivePriority: HeaderElmnts[] = [
    HeaderElmnts.logo,
    HeaderElmnts.projectName,
    HeaderElmnts.profile,
    HeaderElmnts.nav,
    HeaderElmnts.icons,
    HeaderElmnts.search,
  ];
  headerButtonOptions: ButtonOptions = {
    theme: Themes.dark,
  };
  chatButtonOptions: ButtonOptions = {
    theme: Themes.dark,
    icon: 'dds-icon_chat__s__stroke',
  };
  profileOptions: ProfileOptions = {
    username: '',
    photoUrl: '',
  };

  searchOptions: SearchOptions = {
    placeholder: 'Search clients, opportunities or submissions by name or ID',
    ariaLabel: '',
    size: Size.md,
    isInverse: false,
    customClass: '',
    isTrimValue: false,
    readonly: false,
    maxLength: 100,
  };

  defaultDropdownStrickerPostion: PositionState =
    PositionState.bottomLeft ?? undefined;
  dropdownOptions: DropdownOptions = {
    label: '',
    ariaLabel: '',
    theme: ExtThemes.dark,
    kind: ButtonKind.primaryLoud,
    size: Size.md,
    width: WidthState.fixed,
    icon: 'dds-icon_plus',
    isColorBg: false,
    isInverse: false,
    customClass: '',
    disabled: false,
    isResponsive: false,
    stickerWidth: 300,
    stickerIsOutsideClick: false,
    stickerShift: 0,
    stickerMaxHeight: '',
    stickerIsDisabled: false,
    stickerPosition: PositionState.bottomRight,
    stickerIndent: 0,
    stickerCustomClass: '',
    stickerIsDynamic: true,
  };

  dropdownItems: DropdownItemOptions[] = [
    {
      heading: 'Contract/Proposal/Eng. Related Review',
      value: 'Contract/Proposal/Eng. Related Review',
    },
    {
      heading: 'External Communication',
      value: 'External Communication',
    },
    {
      heading: 'MARS NCA Request',
      value: 'MARS NCA Request',
    },
  ];

  defualtBaseOptions: DropdownItemOptions []= [
    {
      heading: 'Start NCA Process',
      value: 'StartNcaProcess',
    },
    {
      heading: 'Watchlist',
      value: 'Watchlist',
    },
    {
      heading: 'Third party agreements repository',
      value: 'Thirdpartyrepository'
    }
  ];

  baseOptions: DropdownItemOptions[] = [...this.defualtBaseOptions];
  
  #unsubscriber$: Subject<void> = new Subject<void>();
  // Getter to dynamically include 'Admin Complete' based on the condition
  get additionalOptions(): DropdownItemOptions[] {
   
    if(!this.isClientMilestoneExportVisible && !this.isMarsClientExportVisible) {
      this.baseOptions = [...this.defualtBaseOptions];
    }
    if (this.isExportToPdfVisible) {
      this.baseOptions=[...this.baseOptions, {
        heading: IntakeConstant.EXPORT_TO_PDF_HEADING,
        value: IntakeConstant.EXPORT_TO_PDF_VALUE,
      }]
    }
    if (this.isAdminCompleteVisible) {
      return [
        ...this.baseOptions,
        {
          heading: IntakeConstant.ADMINISTRATEVELY_CLOSE_MENU_ITEM,
          value: IntakeConstant.ADMINISTRATEVELY_CLOSE_MENU_ITEM,
        },
      ];
    }
    if (this.isDiscontinueVisible) {
      return [
        ...this.baseOptions,
        {
          heading: IntakeConstant.DISCONTINUE_SUBMISSION_MENU_ITEM,
          value: IntakeConstant.DISCONTINUE_SUBMISSION_MENU_ITEM,
        },
      ];
    }
    if (this.isResetStatusVisible) {
      return [
        ...this.baseOptions,
        {
          heading: IntakeConstant.RESET_SUBMISSION_STATUS,
          value: IntakeConstant.RESET_SUBMISSION_STATUS,
        },
      ];
    }

    //if (
    //  this.isMarsClientExportVisible != this.preveiousIsMarsClientExportVisible
    //) {
    //  if (this.isMarsClientExportVisible) {
    //    this.preveiousIsMarsClientExportVisible =
    //      this.isMarsClientExportVisible;
    //    this.baseOptions = [
    //      ...this.baseOptions,
    //      {
    //        heading: ConsultingNCAConstants.EXPORT_MARS_NCA_FORM_PDF_HEADING,
    //        value: ConsultingNCAConstants.EXPORT_MARS_NCA_FORM_PDF,
    //      },
    //    ];
    //  } else if (
    //    this.baseOptions.find(
    //      (obj) =>
    //        obj.heading ===
    //        ConsultingNCAConstants.EXPORT_MARS_NCA_FORM_PDF_HEADING
    //    )
    //  ) {
    //    this.baseOptions = this.baseOptions.filter(
    //      (obj) =>
    //        obj.heading !==
    //        ConsultingNCAConstants.EXPORT_MARS_NCA_FORM_PDF_HEADING
    //    );
    //  }

    //  return this.baseOptions;
    //}
   
    return this.baseOptions;
  }

  constructor(
    private readonly userService: UserService,
    private readonly msGraphService: MsGraphService,
    private readonly router: Router,
    private readonly modalService: ModalService,
    private readonly submissionService: SubmissionService,
    private readonly roleService: RoleService,
    private commonService: CommonService,
    private documentService: DocumentService
  ) {
    //TODO: need to check the loggin user roles as of now its hardcoded
    this.roleService.setCurrentUserRoles(RoleEnum.SYSTEM_ADMIN);
  }

  ngOnInit(): void {
    let isValidProilePic = false;
    let userProfilePic = localStorage.getItem('userProfilePic');
    let userPreferredName = localStorage.getItem('userPreferredName');
    this.displayUserName = userPreferredName;
    this.profileOptions.username = this.displayUserName ?? 'User';


    this.submissionService.currentlyOnSubmissionPage$
    .pipe(takeUntil(this.#unsubscriber$))
    .subscribe({
      next: (status) => {
        this.isExportToPdfVisible = status;
        this.additionalOptions;
      },
      error: (err) => {
        console.error('Error fetching submission page status:', err);
      },
    });

    if (!userPreferredName) {
      this.userService.userEmail$.subscribe((useremail) => {
        this.userEmail = localStorage.getItem('userProfileId');
        this.getUserPreferredName();
      });
    }

    if (userProfilePic) {
      this.isValidBase64Image(userProfilePic).subscribe((isValid) => {
        if (isValid) {
          isValidProilePic = true;
          this.isProficPicAvailable = true;
          this.imageUrl = userProfilePic;
          this.profileOptions.photoUrl = this.imageUrl;
        }
      });
    }
    if (!isValidProilePic) {
      this.userService.userEmail$.subscribe((useremail) => {
        this.userEmail = localStorage.getItem('userProfileId');
        this.getUserProfilePic();
      });
    }
    this.handleChangeRoute();

    this.submissionService.adminEnabler$.subscribe((status: boolean) => {
      this.isAdminCompleteVisible = status;
    });

    combineLatest([
      this.submissionService.opportunitySelectedDocStatus$,
      this.commonService.discontinueAllowedSubmissionStatus$,
      this.roleService.currentUserRole$,
      this.roleService.submissionDiscontinueAllowedRoles$,
    ])
      .pipe(takeUntil(this.#unsubscriber$))
      .subscribe(
        ([
          selectedOpertunityDocStatus,
          allowedStatus,
          currentUseRole,
          allowedRoles,
        ]) => {
          this.isDiscontinueVisible =
            selectedOpertunityDocStatus != null &&
            allowedStatus.includes(selectedOpertunityDocStatus) &&
            currentUseRole != null &&
            allowedRoles.includes(currentUseRole);
        }
      );
    this.commonService.resetEnabler$.subscribe((status: boolean) => {
      this.isResetStatusVisible = status;
    });
    this.commonService.clientMilestoneActionObservable$.subscribe((action: ClientAcceptanceStatusAction | null)=>{
      if(action){
      this.isClientMilestoneExportVisible = action.showExportButton;
      this.clientNumber = action.clientNumber;
      this.clientMilestoneProcessType  = String(action.clientMilestoneProcessType);
      this.selectedClientMilestoneId = action.selectedClientMilestoneId;
      this.baseOptions = [
        ...this.defualtBaseOptions,
        {
          heading:
            ConsultingNCAConstants.COMPLETED_NCA_FORM_PDF_HEADING.replace(
              '#clientMilestoneProcessType',
              this.clientMilestoneProcessType
            ),
          value: ConsultingNCAConstants.COMPLETED_NCA_FORM_PDF_VALUE.replace(
            '#clientMilestoneProcessType',
            this.clientMilestoneProcessType
          ),
        },
      ];
      } else {
        this.baseOptions = [...this.defualtBaseOptions];
      }
    });
    this.commonService.MarsClientActionObservable$.subscribe((action: MarsClientExport| null) => {
      if (action) {
        this.isMarsClientExportVisible = action.showExportButton;
        this.clientNumber = action.clientNumber;
        this.baseOptions = [
          ...this.defualtBaseOptions,
          {
            heading: ConsultingNCAConstants.EXPORT_MARS_NCA_FORM_PDF_HEADING,
            value: ConsultingNCAConstants.EXPORT_MARS_NCA_FORM_PDF            
          },
        ];
      } else {
        this.baseOptions = [...this.defualtBaseOptions];
      }
    });
  }

  itemSelected(value: string): void {
    if (value === 'Contract/Proposal/Eng. Related Review') {
      this.router.navigate(['/intake/submission']);
    } else if (value === 'MARS NCA Request') {
      this.router.navigate(['/initiate/mars-nca']);
    }
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  navigateTo(path: string): void {
    this.router.navigate([path]);
    this.toggleDropdown();
  }

  handleChangeRoute() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        let entity: string = event.url;
        let entities = entity.split('/');
        let entityLastElement = entities[entities.length - 1];
        let entityId, entityTypeId; //TODO: implement logic to get the entity and entityId

        if (entities.includes('client')) {
          entityTypeId = EntityType.Client;
        } else if (entities.includes('submission')) {
          entityTypeId = EntityType.Submission;
        } else {
          entityTypeId = EntityType.None;
        }

        this.getUserRoles(entityId, entityTypeId);
      });
  }

  getUserRoles(entityId: any, entityTypeId: any) {
    if (this.userEmail) {
      this.userService
        .getUserRoles(entityId, entityTypeId)
        .subscribe((data) => {
          if (data) {
            this.userRoles = data?.employeeRoles
              ?.map((u: any) => u.roleName)
              .join('\n');
          }
        });
    }
  }

  getUserPreferredName() {
    if (this.userEmail)
      this.msGraphService
        .searchUserByEmail(this.userEmail)
        .subscribe((data) => {
          if (data && data.value && data.value.length > 0) {
            const user = data.value[0];
            let firstName = user.givenName;
            const displayName = user.displayName;

            if (!firstName || firstName.trim() === '') {
              firstName = displayName;
            }

            if (firstName) {
              localStorage.setItem('userPreferredName', firstName);
              this.displayUserName = firstName;
              this.profileOptions.username = this.displayUserName ?? 'User';
            } else {
              console.error(
                'First name and display name are both missing or invalid.'
              );
            }
          } else {
            console.error('No user data found or data structure is invalid.');
          }
        });
  }

  openModal() {
    let modalRef = this.modalService.open(ExportPdfPopupComponent, { isFooter: false, size: 'md', isInverse: false });
  }


  additionaloptionSelected(value: string): void {
    if (value === 'StartNcaProcess') {
      this.startNcaProcessManually();
    }

    if (value == 'Watchlist') {
      this.router.navigate(['/watchlist']);
    }

    if (value == 'Thirdpartyrepository') {
      this.router.navigate(['/thirdpartyrepository']);
    }

    if(value=='ExportToPdf'){
      this.openModal();
    }

    if (value === IntakeConstant.ADMINISTRATEVELY_CLOSE_MENU_ITEM) {
      this.submissionService.openAdminCompleteModal(true);
    }
    if (value === IntakeConstant.DISCONTINUE_SUBMISSION_MENU_ITEM) {
      this.submissionService.openDiscontinueSubmissionModal(true);
    }
    if (value === IntakeConstant.RESET_SUBMISSION_STATUS) {
      this.commonService.openResetStatusModal(true);
    }
    if (
      this.clientMilestoneProcessType &&
      value ===
        ConsultingNCAConstants.COMPLETED_NCA_FORM_PDF_VALUE.replace(
          '#clientMilestoneProcessType',
          this.clientMilestoneProcessType
        )
    ) {
      this.exportClientDataPdf(false);
    }
    if (value === ConsultingNCAConstants.EXPORT_MARS_NCA_FORM_PDF) {
      this.exportClientDataPdf(true);
    }
  }
  public async startNcaProcessManually() {
    this.modalService.open(StartNcaprocessDialogComponentComponent);
  }

  getUserProfilePic() {
    if (this.userEmail)
      this.msGraphService.GetProfileImage(this.userEmail).subscribe((data) => {
        try {
          if (data != null) {
            var imgUrl = data;

            this.isValidBase64Image(imgUrl).subscribe((isValid) => {
              if (isValid) {
                this.imageUrl = imgUrl;
                this.profileOptions.photoUrl = this.imageUrl;
                this.isProficPicAvailable = true;
                localStorage.setItem('userProfilePic', data);
              }
            });
          }
        } catch (error: any) {
          console.error('Error fetching profile image', error);
        }
      });
  }

  isValidBase64Image(imageUrl: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const img = new Image();
      img.src = imageUrl;

      img.onload = () => {
        observer.next(true);
        observer.complete();
      };

      img.onerror = () => {
        observer.next(false);
        observer.complete();
      };
    });
  }

  exportClientDataPdf(isMars : boolean){
    this.documentService.exportClientDataPDF(this.clientNumber, this.clientMilestoneProcessType === 'NCA', this.clientMilestoneProcessType === 'CC', isMars, this.selectedClientMilestoneId);
  }

  ngOnDestroy(): void {
    this.#unsubscriber$.next();
    this.#unsubscriber$.complete();
  }
}
