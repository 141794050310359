import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environment/environment';
import { Observable, of } from 'rxjs';
import {
  DashboardDataCollection,
  DashboardGridLevelFilter,
} from '../../common/models/start-page/start-page.model';
import { RecentlyViewedSubmissionList } from '../../common/models/start-page/reviewer-dashboard.model';
import {
  EngagementDashboardData,
  EngagementDashboardTilesCount,
} from '../../common/models/start-page/engagement-dashboard.model';
import { UrlConstant } from '../../intake/constants/url.constant';

@Injectable({
  providedIn: 'root',
})
export class EngagementDashboardWebapiService {
  constructor(private readonly httpService: HttpClient) {}

  apiUrl: string = environment.apiUrl;
  // apiUrl: string = "https://dapi.eclipse.deloitte.com/api/";
  // apiUrl: string = 'https://qapi.eclipse.deloitte.com/api/';//qa

  getEngagementDashBoardGridData(
    tileCode: string,
    pageNumber: number,
    pageSize: number,
    sortParameterCode: number,
    isAscending: boolean,
    filterData: DashboardGridLevelFilter[]
  ): Observable<DashboardDataCollection<EngagementDashboardData>> {
    const params = {
      tileCode: tileCode,
      pageNumber: pageNumber,
      pageSize: pageSize,
      sortParameterCode: sortParameterCode,
      isAscending: isAscending,
    };
    return this.httpService.post<
      DashboardDataCollection<EngagementDashboardData>
    >(this.apiUrl + UrlConstant.POST_ENGAGEMENT_GRID_DATA, filterData, {
      params,
    });
  }

  getEngagementDashboardTilesCount(): Observable<EngagementDashboardTilesCount> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.httpService.get<EngagementDashboardTilesCount>(
      this.apiUrl + UrlConstant.GET_ENGAGEMENT_TILE_COUNT,
      { headers }
    );
  }

  getRecentlyViewedSubmissions(
    pageNumber: number,
    pageSize: number
  ): Observable<RecentlyViewedSubmissionList> {
    return this.httpService.get<RecentlyViewedSubmissionList>(
      this.apiUrl +
        UrlConstant.GET_RECENTLY_VIEWED_SUBMISSIONS +
        '?pageNumber=' +
        pageNumber +
        '&pageSize=' +
        pageSize
    );
  }
}
