import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  OTHER_RADIO_GROUP_VALUE,
  RADIO_GROUP_OPTIONS_FOR_OTHER_SCOPE,
} from './close-out-other-helper';
import {
  ArchiveDetailsUpdatingModel,
  WbsArchiveReceivedModel,
  WbsResponseDataModel,
} from '../../../../common/models/wbs-picker.model';
import { OtherOptionValues } from '../../../constants/intake_document_close_out_other_option_values.enum';
import { SwiftDetails } from '../../../../common/models/intake-submission-details-common-section.model';
import { IntakeConstant } from '../../../constants/intake.constant';

@Component({
  selector: 'app-close-out-other',
  templateUrl: './close-out-other.component.html',
  styleUrl: './close-out-other.component.less',
})
export class CloseOutOtherComponent {
  @Input() other = IntakeConstant.EMPTY_SPACE;
  @Input() wbsChargeCode: null | string = IntakeConstant.EMPTY_SPACE;
  @Input() wbsDescription: null | string = IntakeConstant.EMPTY_SPACE;
  @Input() dataSubmissionId: number | null = null;
  @Input() dataOpportunityId: string = IntakeConstant.EMPTY_SPACE;
  @Input() archiveDetails: WbsArchiveReceivedModel | null = null;
  @Input() swiftDetails: SwiftDetails | null = null;
  @Output() wbsChargeCodeChange: EventEmitter<WbsResponseDataModel> =
    new EventEmitter<WbsResponseDataModel>();
  @Output() otherChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() wbsArchiveValueChange: EventEmitter<ArchiveDetailsUpdatingModel> =
    new EventEmitter<ArchiveDetailsUpdatingModel>();
  other_radioGroupOptions: any = RADIO_GROUP_OPTIONS_FOR_OTHER_SCOPE;
  other_radio_values: any = OTHER_RADIO_GROUP_VALUE;
  wbs_chargecode_state: string = OtherOptionValues.NO_RESUBMIT_FOR_REVIEW;

  otherStateChanged(event: string): void {
    this.other = event;
    this.otherChange.emit(event);
  }

  updateWbsValues(wbsChargeCode: WbsResponseDataModel): void {
    this.wbsChargeCodeChange.emit({
      wbsChargeCode: wbsChargeCode.wbsChargeCode,
      wbsDescription: wbsChargeCode.wbsDescription,
      wbsPending: wbsChargeCode.wbsPending,
    });
  }

  updateArchivesValues(event: ArchiveDetailsUpdatingModel): void {
    this.wbsArchiveValueChange.emit(event);
  }
}
