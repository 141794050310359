<div class="dds-modal-overlay" (click)="close()"></div>
<div
  ddsResize
  [ngClass]="{
    'dds-modal_lg': size === 'lg',
    'dds-modal_inverse': isInverse
  }"
  class="dds-modal"
>
  <div class="dds-modal__header">
    <span class="dds-modal__title">Recently viewed submissions</span>
    <dds-button
      size="sm"
      icon="dds-icon_close"
      kind="silent"
      theme="dark"
      ariaLabel="Close modal"
      class="dds-modal__close"
      [isInverse]="isInverse"
      (clicked)="close()"
    ></dds-button>
  </div>

  <div
    class="dds-modal__body"
    (scroll)="fetchMoreSubmissions($event)"
  >
    <!-- Table logic -->
    <div class="div-headers">
      <table class="dds-data-table dds-data-table_selection">
        <thead class="dds-data-table__header">
          <tr>
            <th
              *ngFor="let column of sampleColumns"
              class="dds-data-table__header-cell"
              [ngStyle]="{ minWidth: column.minWidth }"
            >
              <div class="dds-data-table__content">
                <span>{{ column.header || column.name }}</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="dds-data-table__body">
          <tr
            *ngFor="let row of submssionDetailList"
            class="dds-data-table__row"
            (click)="goToSubmission(row.opportunityNumber,row.submissionId)"
          >
            <td class="dds-data-table__cell">
              <span class="sub-info font-size-12 font-bold-600 item-type">{{
                row.submissionTypeCode
              }}</span>
            </td>
            <td class="dds-data-table__cell">
              <span
                class="padding-status"
                [style.color]="
                  transformStatus(row.submissionStatus)
                    | statusTranform : 'color'
                "
                [style.backgroundColor]="
                  transformStatus(row.submissionStatus)
                    | statusTranform : 'backgroundColor'
                "
                >{{ row.submissionStatus }}</span
              >
            </td>
            <td class="dds-data-table__cell">
              <div class="width100 long-title">
                <span
                  [ddsTooltip]="row.submissionTitle"
                  class="margin-Style long-title"
                  >{{ row.submissionTitle }}</span
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
