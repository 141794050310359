import { Component, Input } from '@angular/core';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ShowAllModel } from '../../../../common/models/show-all-popup-model';

@Component({
  selector: 'app-show-all-popup',
  templateUrl: './show-all-popup.component.html',
  styleUrl: './show-all-popup.component.less',
})
export class ShowAllPopupComponent {
  @Input() public documentInitiatedDetails: ShowAllModel = {
    opportunityNumber: '',
    showAllSplits: false,
  };
  opportunityNumber: string = '';
  constructor(private readonly modalService: ModalService) {}
  ngOnInit(): void {
    this.opportunityNumber = this.documentInitiatedDetails?.opportunityNumber
      ? this.documentInitiatedDetails?.opportunityNumber
      : '';
  }

  closeShowAll(): void {
    this.modalService.close();
  }
}
