export enum DocumentStatus {
  Draft = 'Draft',
  RMReviewed = 'RM reviewed',
  SelfReviewed = 'Self reviewed',
  FinalArchiveReadyVersion = 'Final - Archive ready version',
  SupportingDocument = 'Supporting documentation - no review required',
}

export const DOCUMENT_STATUS_VALUE_MAPPER = new Map<DocumentStatus, number>([
  [DocumentStatus.Draft, 1],
  [DocumentStatus.RMReviewed, 2],
  [DocumentStatus.FinalArchiveReadyVersion, 3],
  [DocumentStatus.SelfReviewed, 4],
  [DocumentStatus.SupportingDocument, 5],
]);

export enum DocumentArchivalStatus {
  ARCHIVED = 'Archived',
  MOVED = 'Archived',
  PENDING = 'Pending'
}