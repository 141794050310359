<div class="nca-container relative text-align-left">
  <progress-indicator></progress-indicator>
  <!-- TODO when functunality defined -->
  <div class="row">
    <span class="d-flex flex-row"
      ><span
        class="dds-icon margin-right-xs font-size-18 dds-icon_home__l__stroke"
        (click)="backToHome()"
      ></span>
      <span class="font-size-14 font-bold-400 padding-bottom-s"
        >My Dashboard</span
      ></span
    >
    <div class="col-8 d-flex flex-row">
      <div class="opportunity-heading">
        {{ opportunityDetailTitle }}
      </div>
    </div>
    <div class="col-4 text-align-right">
      <span class="padding-right-s">Show in Jupiter</span>
      <span class="padding-right-s">
        <img src="\assets\images\dds-icon [16px].svg" />
      </span>
      <span class="padding-right-s">
        <img src="\assets\images\divider.png" />
      </span>
      <span class="padding-right-s">
        <span
          class="dds-icon dds-icon_pause_bordered__s__stroke cursor-clickable rotate-icon padding-bottom-xxs inline-block"
          (click)="navigateToSummary()"
        ></span>
      </span>
      <span
        class="padding-right-s cursor-clickable"
        (click)="navigateToSummary()"
        >Opportunity summary</span
      >
    </div>
  </div>
</div>

<div style="display: flex">
  <div class="row padding-top-l width26pt">
    <div class="col-3 width-left-menu-panel">
      <div class="sidebar">
        <div
          class="nav-item"
          [class.active]="activeIndex === -1"
          (click)="setActive(-1)"
        >
          <span
            class="status-indicator"
            [ngClass]="
              activeIndex === -1
                ? 'status-active-indicator'
                : 'status-inactive-indicator'
            "
          ></span>
          <div class="opportunity-detail-heading">
            <p>Opportunity details</p>
            <span *ngIf="isOverAllRequired()"
              ><img class="right-a" src="\assets\images\draftIcon.svg"
            /></span>
          </div>
        </div>

        <p class="doc-sub">Document submission(s):</p>
        <div class="nav-items">
          <div
            *ngFor="let item of submissitionDetails; let i = index"
            class="nav-item"
            [class.active]="i === activeIndex"
            (click)="setActive(i)"
          >
            <span
              class="status-indicator"
              [ngClass]="
                i === activeIndex
                  ? 'status-active-indicator'
                  : 'status-inactive-indicator'
              "
            ></span>
            <div class="nav-item-content" id="item.submissionId">
              <div class="icon-info-display">
                <img src="\assets\images\file-icon.svg" alt="file img" />
                <div>
                  <h4
                    [ddsTooltip]="item.submissionTitle"
                    [tooltipInvokeType]="options.tooltipInvokeType ?? ''"
                    [tooltipType]="options.tooltipType ?? ''"
                    [tooltipSize]="options.tooltipSize ?? ''"
                    [tooltipPosition]="options.tooltipPosition ?? ''"
                    [tooltipIndent]="options.tooltipIndent ?? 0"
                    [tooltipHasBeak]="options.tooltipHasBeak ?? false"
                    [tooltipIsDisabled]="options.tooltipIsDisabled ?? false"
                    [tooltipIsOutsideClick]="
                      options.tooltipIsOutsideClick ?? false
                    "
                    [tooltipIsDynamic]="options.tooltipIsDynamic ?? false"
                    [tooltipTheme]="options.tooltipTheme ?? ''"
                    [tooltipShowDelay]="options.tooltipShowDelay ?? 0"
                    [tooltipHideDelay]="options.tooltipHideDelay ?? 0"
                    ddsFocus
                  >
                    {{ item.submissionTitle }}
                  </h4>
                  <div class="sub-info">
                    <span class="item-type">{{ item.submissionTypeCode }}</span>
                    <span
                      class="padding-xxs"
                      [style.color]="
                        transformStatus(item.submissionStatusName)
                          | statusStyle : 'color'
                      "
                      [style.backgroundColor]="
                        transformStatus(item.submissionStatusName)
                          | statusStyle : 'backgroundColor'
                      "
                      >{{ item.submissionStatusName }}</span
                    >
                  </div>
                </div>
              </div>
              <span
                class="draft-status"
                *ngIf="
                  item.submissionId &&
                  !requiredCheckForIdList.includes(item.submissionId)
                "
              >
                <img src="\assets\images\draftIcon.svg"
              /></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tabsection"></div>
  <div class="padding-left-m padding-top-l">
    <div *ngIf="activeIndex === -1" class="row">
      <app-oportunity-detail-right-panel
        [opportunityNumber]="opportunityId"
        [tabTitle]="opportunityDetailTitle"
        [submissionDetails]="submissitionDetails"
        (lepValueChange)="onLepValueChange($event)"
      ></app-oportunity-detail-right-panel>
    </div>
    <div *ngIf="activeIndex > -1" class="row">
      <app-submission-common-section
        [dataOpportunityId]="opportunityId"
        [dataSubmissionId]="selectedSubmissionId"
        [dataSubmissionTypeCode]="dataSubmissionTypeCode"
        [dataLepValue]="lepValue"
        [opportunityDocsCount]="submissitionDetails.length"
        (valueChange)="checkRequired($event)"
        (updateTitle)="updateDocumentTitle($event)"
        (updateLeftMenu)="getNavDetails(opportunityId)"
      />
    </div>
  </div>
</div>
