import { Component, Input, OnInit } from '@angular/core';
import { OpportunityDetailsNavModel } from '../../../../common/models/opportunity-details.model';
import { Router } from '@angular/router';
import { SubmissionService } from '../../../../http/intake/submission.service';
import { Subject, takeUntil } from 'rxjs';
import { IntakeDocumentSubmissionTypeCode } from '../../../constants/intake_submission_type_code.enum';
import { OptOutPopupComponent } from '../../../intake-common-popups/opt-out-popup/opt-out-popup.component';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { InitiateNavigation } from '../../opportunity-summary-settings';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import {
  IntakeConstant,
  VALID_DOCUMENT_TYPES,
} from '../../../constants/intake.constant';
import { IntakeOppertunitySubmissionStatus } from '../../../constants/intake_oppertunity_submission_status.enum';
import { StatusEnum } from '../../../constants/status-enum';
import { IntakeDocumentTableModel } from '../../../../common/models/intake-document-table.model';
import { OppertunitySubmissionStatusCode } from '../../../../common/models/oppertunity-submission-statusCode.model';
import { CommonService } from '../../../../http/intake/common.service';
import { WinningProposalOptionValues } from '../../../constants/intake_document_close_out_winning_proposal_option_values.enum';
import { CoElSowOptionValues } from '../../../constants/intake_document_close_out_co_elsow_option_values.enum';
import { DeliverableOptionValues } from '../../../constants/intake_document_close_out_deliverable_option_values.enum';
import { OtherOptionValues } from '../../../constants/intake_document_close_out_other_option_values.enum';

@Component({
  selector: 'app-document-submission',
  templateUrl: './document-submission.component.html',
  styleUrl: './document-submission.component.less',
})
export class DocumentSubmissionComponent implements OnInit {
  @Input() item!: OpportunityDetailsNavModel;
  @Input() opportunityNumber: string = '';
  unsubscriber$: Subject<void> = new Subject<void>();
  submissionData!: any;
  docMetaData!: IntakeDocumentTableModel[];
  submissionStatus: string | undefined = undefined;
  coChangeScope: string | undefined = undefined;
  coDescription: string | undefined = undefined;
  delDescription: string | undefined = undefined;
  internalQRMTeamCommunication: string | undefined = undefined;
  notesAndFeedback: string | null | undefined = null;
  otherDescription: string | undefined = undefined;
  proposalDescription: string | undefined = undefined;
  relatedToRFP: string | undefined = undefined;
  submissionSubTypeId: string | undefined | null = undefined;
  lepReviewedBy: string | null = null;
  submissionTypeCode: string | undefined = undefined;
  isValidDocUploaded: boolean = false;
  isElSowForCoPresent: boolean = false;
  selfReviewEligibilityStatus: string | undefined = '';
  smallButton: ButtonOptions = InitiateNavigation;
  lepAcknowledgedBy: string | null = '';
  lepAcknowledgementDate: string | null = '';
  showLepConfirmationMessage: boolean = false;
  oppertunityStatusCodes!: OppertunitySubmissionStatusCode[];
  updatedVersionForReview!: string | null | undefined;
  winningProposal: string | null | undefined = '';
  contractSignedExecuted: string | null = '';
  wbsChargeCode: string | null = '';
  otherUpdatedVersionForReview: string | null | undefined = '';
  isValidArchiveDocUploaded: boolean = false;
  isValidReturnToReworkDocUploaded: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly intakeService: SubmissionService,
    private readonly modalService: ModalService,
    private readonly commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.commonService
      .getOppertunityStatusCode()
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: OppertunitySubmissionStatusCode[]) => {
          this.oppertunityStatusCodes = response;
        },
        error: (err) => {
          console.error('Error fetching submission status', err);
        },
      });
    this.getSubmissionDetailsInfo();
  }

  getSubmissionDetailsInfo(): void {
    this.intakeService
      .getSubmissionBySubmissionId(
        this.opportunityNumber,
        String(this.item.submissionId)
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: any) => {
          this.submissionData = data;
          if (this.submissionData) {
            this.updateVariables();
          }
          this.getDocumentMetaData();
          if (
            this.submissionTypeCode === IntakeConstant.CHANGE_ORDER_LABEL_CODE
          ) {
            this.getChangeOrderStatus();
          }
        },
        error: (err) => {
          console.error('Error fetching submission', err);
        },
      });
  }

  #updateStatus(): void {
    const validTypes =
      VALID_DOCUMENT_TYPES[
        this.submissionTypeCode as IntakeDocumentSubmissionTypeCode
      ];
    if (this.submissionTypeCode === IntakeConstant.CHANGE_ORDER_LABEL_CODE) {
      const status = this.docMetaData.some(
        (item: IntakeDocumentTableModel) =>
          validTypes.includes(item.documentType) &&
          item.documentStatus === IntakeConstant.FINAL_ARCHIVE_READY_VERSION
      );
      this.isValidDocUploaded = status;
    } else {
      const status = this.docMetaData.some((item: IntakeDocumentTableModel) =>
        validTypes.includes(item.documentType)
      );
      this.isValidDocUploaded = status;
    }
  }

  updateSubmitButtonStatus(): boolean {
    if (
      (this.isElSowForCoPresent && this.coChangeScope && this.coDescription) ||
      (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.CO &&
        this.coChangeScope != null &&
        this.coDescription &&
        this.isValidDocUploaded &&
        !this.showLepConfirmationMessage) ||
      (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.DEL &&
        this.delDescription &&
        this.isValidDocUploaded &&
        !this.showLepConfirmationMessage) ||
      (this.isValidDocUploaded &&
        this.otherDescription &&
        this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.OTH &&
        !this.showLepConfirmationMessage) ||
      (this.isValidDocUploaded &&
        this.relatedToRFP != null &&
        this.proposalDescription &&
        this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.PRO &&
        !this.showLepConfirmationMessage) ||
      (this.isValidDocUploaded &&
        this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.EL_SOW &&
        !this.showLepConfirmationMessage)
    ) {
      return false;
    }
    return true;
  }

  getDocumentMetaData(): void {
    this.intakeService
      .getDocumentTableData(this.item.submissionId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (data: any) => {
          this.docMetaData = data;
          if (this.docMetaData.length > 0) {
            this.isValidArchiveDocUploaded = this.docMetaData.some(
              (item: IntakeDocumentTableModel) =>
                item.documentStatus ===
                IntakeConstant.FINAL_ARCHIVE_READY_VERSION
            );
            this.isValidReturnToReworkDocUploaded = this.docMetaData.some(
              (item: IntakeDocumentTableModel) =>
                item.documentStatus === IntakeOppertunitySubmissionStatus.DRAFT
            );
            this.#updateStatus();
          }
        },
        error: (err) => {
          console.error('Error fetching submission', err);
        },
      });
  }

  onShowMore(): void {
    this.router.navigate(
      ['/submission/opportunity-details', this.opportunityNumber],
      {
        state: {
          submissionID: this.item.submissionId,
          navigatedFromSummary: true,
        },
      }
    );
  }

  isSubmitBtnVisible(): boolean {
    return this.submissionStatus === IntakeOppertunitySubmissionStatus.DRAFT;
  }

  isResubmitBtnVisibleForSummary(): boolean {
    return (
      this.submissionStatus ===
      IntakeOppertunitySubmissionStatus.RETURN_FOR_REWORK
    );
  }

  isSubmittedResubmittedSectionVisible(): boolean {
    return (
      this.submissionStatus === IntakeOppertunitySubmissionStatus.SUBMITTED ||
      this.submissionStatus === IntakeOppertunitySubmissionStatus.RESUBMITTED
    );
  }

  isPendingCloseoutVisible(): boolean {
    return (
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.RM_REVIEWED_PENDING_CLOSEOUT ||
      this.submissionStatus ===
        IntakeOppertunitySubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT
    );
  }

  getChangeOrderStatus(): void {
    const submissionStatusId = Number(
      this.getSubmissionStatusCode(IntakeOppertunitySubmissionStatus.SUBMITTED)
    );
    this.intakeService
      .getChangeOrderStatus(this.opportunityNumber, submissionStatusId)
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: any) => {
          this.isElSowForCoPresent = response;
        },
        error: (err) => {
          console.error('Error fetching submission', err);
        },
      });
  }

  submitCommonSectionDataForSummary(): void {
    let submissionStatusId = this.getSubmissionStatusCode(
      IntakeOppertunitySubmissionStatus.SUBMITTED
    );

    if(this.selfReviewEligibilityStatus === IntakeConstant.YES){
      submissionStatusId = this.getSubmissionStatusCode(
      IntakeOppertunitySubmissionStatus.SELF_REVIEWED_PENDING_CLOSEOUT
    );
  }

    this.intakeService
      .submitCommonSectionData(
        this.item.submissionId,
        Number(submissionStatusId)
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: any) => {
          if (response) {
            this.getSubmissionDetailsInfo();
            this.item.submissionStatusName = response.submissionStatusName;
            if(this.selfReviewEligibilityStatus === IntakeConstant.YES){
              this.item.isFormCompleted=false;
            }
          }
        },
        error: (error) => {},
      });
  }

  onclickResubmitForReviewBtnForSummary(): void {
    const submissionStatusId = this.getSubmissionStatusCode(
      IntakeOppertunitySubmissionStatus.RESUBMITTED
    );
    this.intakeService
      .submitCommonSectionData(
        this.item.submissionId,
        Number(submissionStatusId)
      )
      .pipe(takeUntil(this.unsubscriber$))
      .subscribe({
        next: (response: any) => {
          this.getSubmissionDetailsInfo();
          this.item.submissionStatusName = response.submissionStatusName;
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  transformStatus(status: string): StatusEnum {
    return (
      Object.values(StatusEnum).find((enumValue) => enumValue === status) ||
      StatusEnum.Draft
    );
  }

  openDocumentEditModel(modalBtn?: any): void {
    this.modalService.open(OptOutPopupComponent);
  }

  updateVariables(): void {
    this.submissionTypeCode = this.submissionData.submissionTypeCode;
    this.submissionStatus = this.submissionData.submissionStatusName;
    this.coChangeScope = this.submissionData.coChangeScope;
    this.coDescription = this.submissionData.coDescription;
    this.delDescription = this.submissionData.delDescription;
    this.internalQRMTeamCommunication =
      this.submissionData.internalQRMTeamCommunication;
    this.notesAndFeedback = this.submissionData.notesAndFeedback;
    this.otherDescription = this.submissionData.otherDescription;
    this.proposalDescription = this.submissionData.proposalDescription;
    this.relatedToRFP = this.submissionData.relatedToRFP;
    this.submissionSubTypeId = this.submissionData.submissionSubTypeId;
    this.lepReviewedBy = this.submissionData.lepReviewedBy;
    this.lepAcknowledgedBy = this.submissionData.lepAcknowledgedBy;
    this.lepAcknowledgementDate = this.submissionData.lepAcknowledgementDate;
    this.updatedVersionForReview = this.submissionData.updatedVersionForReview;
    this.selfReviewEligibilityStatus =
      this.submissionData.selfReviewEligibilityStatus;
    this.winningProposal = this.submissionData.winningProposal;
    this.contractSignedExecuted = this.submissionData.contractSignedExecuted;
    this.wbsChargeCode = this.submissionData.wbsChargeCode;
    this.otherUpdatedVersionForReview =
      this.submissionData.otherUpdatedVersionForReview;
    if (
      this.lepAcknowledgedBy === null &&
      this.lepAcknowledgementDate === null &&
      this.lepReviewedBy === null
    ) {
      this.showLepConfirmationMessage = true;
    } else {
      this.showLepConfirmationMessage = false;
    }
  }

  onclickArchiveAndCompleteBtnForSummary(): void {
    const submissionStatusId = this.getSubmissionStatusCode(
      IntakeOppertunitySubmissionStatus.COMPLETED
    );
    if (submissionStatusId) {
      this.intakeService
        .submitCommonSectionData(this.item.submissionId, submissionStatusId)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: any) => {
            this.item.submissionStatusName = response.submissionStatusName;
            this.getSubmissionDetailsInfo();
          },
          error: (error) => {
            console.log(error);
          },
        });
    }
  }

  handleReturnforReworkForSummary(): void {
    const submissionStatusId = this.getSubmissionStatusCode(
      IntakeOppertunitySubmissionStatus.RETURN_FOR_REWORK
    );
    if (submissionStatusId) {
      this.intakeService
        .submitCommonSectionData(this.item.submissionId, submissionStatusId)
        .pipe(takeUntil(this.unsubscriber$))
        .subscribe({
          next: (response: any) => {
            this.getSubmissionDetailsInfo();
            this.item.submissionStatusName = response.submissionStatusName;
          },
          error: () => {
            console.error('An error occurred during submission: ');
          },
        });
    }
  }

  getSubmissionStatusCode(statusName: string): number | undefined {
    return this.oppertunityStatusCodes?.find(
      (status: OppertunitySubmissionStatusCode) =>
        status.statusName === statusName
    )?.id;
  }

  showArchiveButtonForSummary(): boolean {
    if (
      this.isValidArchiveDocUploaded &&
      ((this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.PRO &&
        this.winningProposal?.toLowerCase() ===
          WinningProposalOptionValues.YES.toLowerCase()) ||
        (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.CO &&
          this.contractSignedExecuted?.toLowerCase() ===
            CoElSowOptionValues.YES.toLowerCase()) ||
        (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.DEL &&
          this.updatedVersionForReview?.toLowerCase() ===
            DeliverableOptionValues.NO.toLowerCase()) ||
        (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.OTH &&
          this.otherUpdatedVersionForReview?.toLowerCase() ===
            OtherOptionValues.YES.toLowerCase()) ||
        (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.EL_SOW &&
          this.contractSignedExecuted?.toLowerCase() ===
            CoElSowOptionValues.YES.toLowerCase()))
    ) {
      return true;
    }
    return false;
  }

  showReturnToReworkButtonForSummary(): boolean {
    if (
      this.isValidReturnToReworkDocUploaded &&
      ((this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.PRO &&
        this.winningProposal?.toLowerCase() ===
          WinningProposalOptionValues.NO_RESUBMIT_FOR_REVIEW.toLowerCase()) ||
        (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.CO &&
          this.contractSignedExecuted?.toLowerCase() ===
            CoElSowOptionValues.NO_RESUBMIT_FOR_REVIEW.toLowerCase()) ||
        (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.DEL &&
          this.delDescription &&
          this.updatedVersionForReview?.toLowerCase() ===
            DeliverableOptionValues.YES_RESUBMIT_FOR_REVIEW.toLowerCase()) ||
        (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.OTH &&
          this.otherDescription &&
          this.otherUpdatedVersionForReview?.toLowerCase() ===
            OtherOptionValues.NO_RESUBMIT_FOR_REVIEW.toLowerCase()) ||
        (this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.EL_SOW &&
          this.contractSignedExecuted?.toLowerCase() ===
            CoElSowOptionValues.NO_RESUBMIT_FOR_REVIEW.toLowerCase()))
    ) {
      return true;
    }
    return false;
  }

  /*
  showReturnToReworkButtonForSummary(): boolean {
    if (
      this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.PRO &&
      this.isValidReturnToReworkDocUploaded &&
      this.winningProposal ===
        WinningProposalOptionValues.NO_RESUBMIT_FOR_REVIEW
    ) {
      return true;
    } else if (
      this.isValidReturnToReworkDocUploaded &&
      this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.CO &&
      this.contractSignedExecuted ===
        CoElSowOptionValues.NO_RESUBMIT_FOR_REVIEW 
      
    ) {
      return true;
    } else if (
      this.isValidReturnToReworkDocUploaded &&
      this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.DEL &&
      this.delDescription &&
      this.updatedVersionForReview ===
        DeliverableOptionValues.YES_RESUBMIT_FOR_REVIEW
    ) {
      return true;
    } else if (
      this.isValidReturnToReworkDocUploaded &&
      this.otherDescription &&
      this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.OTH &&
      this.otherUpdatedVersionForReview ===
        OtherOptionValues.NO_RESUBMIT_FOR_REVIEW
    ) {
      return true;
    } else if (
      this.isValidReturnToReworkDocUploaded &&
      this.submissionTypeCode === IntakeDocumentSubmissionTypeCode.EL_SOW &&
       this.contractSignedExecuted === CoElSowOptionValues.NO_RESUBMIT_FOR_REVIEW 
    ) {
      return true;
    }
    return false;
  }
*/
  checkForSelfReviewEligibilityStatus(): boolean {
    return this.selfReviewEligibilityStatus === IntakeConstant.YES;
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }
}
