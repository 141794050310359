export enum EngagementTileCode {
  Initiated = 'initiated',
  Submitted = 'submitted',
  Reviewed = 'reviewed',
  Completed = 'completed',
}

export enum RiskManagerTileCode {
  Inprocess = 'InProcess',
  Completed = 'Completed',
  Discontinued = 'Discontinued'
}
