import { ButtonKind, Column } from '@usitsdasdesign/dds-ng/shared';
import { EmployeeView } from '../common-models';

export enum DashboardTileCode {
  NCASpecialist = 'NCASpecialist',
  Reviewer = 'Reviewer',
  EngagementTeam = 'EngagementTeam',
  RiskManager = 'RiskManager',
}

export enum DashboardRoles {
  NCASpecialist = 'NCA specialist',
  Reviewer = 'Reviewer',
  EngagementTeam = 'Engagement team',
  RiskManager = 'Risk manager oversight',
}

export interface DashboardGridLevelFilter {
  filtertypecode: number;
  filtercriteria: string;
}

export interface GridColumn extends Column {
  filterable?: boolean;
  searchValue?: any;
}

export enum DashboardGridSortParameter {
  clientName = 1,
  parentClientName = 2,
  descDesignation = 3,
  actionByName = 4,
  industry = 5,
  recommendedClientGroupNumber = 6,
  opportunityExpectedCloseDate = 7,
  ncaTriggeredDate = 8,
  continuanceExpiredDate = 9, //TODO: Need to check the key
  openOpportunities = 10,
  priorFyClosedWBS = 11,
  netServiceRevenue = 12,
  submittedDate = 13,
  rushRequest = 14,
  requestedReviewDate = 15,
  approverName = 16,
  daysPendingForCCCompletion = 17,
  submissionTitle = 18,
  lep = 20,
  continuanceStatus = 21,
  openWBS = 22,
  clientType = 23,
  recommendingPPMD = 24,
  industryLeader = 25,
  marsActionSort = 26,
  completedSubmissionDate = 40,
  reviewer = 41,
  primaryRiskManager = 27,
  statusDate = 28,
  riskManager = 29,
  opportunityName = 30,
  jupiterStage = 31,
  offering = 32,
  business = 33,
  jupiterId = 101,
}

export interface DashboardDataCollection<T extends DashboardData> {
  dataList: T[];
  totalDataCount: number;
}

export interface DashboardData {
  clientName?: string;
  clientIconCode?: string;
  clientAcceptanceStatus: string;
  clientIconHover?: string;
  parentClientName: string;
  parentClientIconCode?: string;
  parentClientAcceptanceStatus?: string;
  parentClientIconHover?: string;
  descDesignation: string;
  industry?: string;
  industrySector?: string;
  opportunityList?: OpportunityData[];
  unfilteredDataCount: number;
  watchlistId?: number;
  watchlistStatusId?: number;
  watchlistStatus: string;
  statusComment: string;
  clientWatchlistCreatedBy: string;
  clientWatchlistCreateddate: string;
  prevWatchlistStatusId?: number;
  prevWatchlistStatus: string;
  prevStatusComment: string;
  prevModifiedBy: string;
  prevModifiedDate: string;
}

export interface OpportunityData {
  opportunityName: string;
  opportunityServiceID: number;
  opportunityNumber: string;
  opportunityExpectedCloseDate?: string;
  opportunityStatusDescription: string;
  opportunityModifiedDate: string;
  offeringSolutionText: string;
  marketOfferingSolutionText: string;
  marketOfferingSubSolutionText: string;
  pursuitLeaderOutlookDisplayName: string;
  pursuitLeaderEmployeeID: string;
  pursuitLeaderEmailAddress: string;
  pursuitLeaderEmployeeAlias: string;
  pursuitLeaderEmployee: EmployeeView;
  pursuitLeaderInfoIconText: string;
  opportunityEstimatedValueInfoIconText: string;
  isMilestoneTrigger: boolean;
  [key: string]: any;
}

export type GridUpdateEvent = {
  sortParamaterCode: number;
  isAscending: boolean;
  dashboardfilters: any[];
};

export type ButtonOptionsType = {
  label: string;
  action: string;
  buttonType?: ButtonKind;
  condition: boolean | string;
  tooltip?: string | null;
};
